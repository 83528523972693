import { fetchCurrentUser } from "@/helpers/auth";
import { getFromLF, savetoLF } from "./storage/localForage";

export default {
  async fetchCurrentUser({ commit }) {
    let currentUser = await fetchCurrentUser();
    return commit("setCurrentUser", currentUser);
  },
  async fetchCountry({ commit }) {
    let country = await getFromLF("country");
    return commit("setCountry", country);
  },
  async saveCountry({ commit }, country) {
    await savetoLF("country", country);
    return commit("setCountry", country);
  },
};
