import { render, staticRenderFns } from "./BriefCampaigns.vue?vue&type=template&id=2f72125b&scoped=true&"
import script from "./BriefCampaigns.vue?vue&type=script&lang=js&"
export * from "./BriefCampaigns.vue?vue&type=script&lang=js&"
import style0 from "./BriefCampaigns.vue?vue&type=style&index=0&id=2f72125b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f72125b",
  null
  
)

export default component.exports