<template>
  <v-card
    width="100%"
    min-height="650"
    id="bus"
    class="py-5 px-2 mx-auto white--text"
    :color="$vuetify.theme.themes.dark.background"
  >
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-toolbar
      :color="$vuetify.theme.themes.dark.background"
      flat
      class="white--text mb-2"
    >
      <v-toolbar-title>All Brands</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-data-table
        :items="getAllBrands"
        :headers="headers"
        :search="search"
        class="elevation-1 pa-3"
        :loading="!getAllBrands.length"
        :style="{
          'background-color': $vuetify.theme.themes.dark.background,
          color: $vuetify.theme.themes.dark.text,
        }"
        loading-text="Fetching All Brands... Please wait"
        dark
      >
        <template slot="top">
          <v-toolbar color="grey darken-3" flat>
            <v-btn class="white--text" @click="fetchBrands">
              Refresh
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-responsive max-width="60%">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-responsive>

            <v-spacer></v-spacer>

            <p class="white--text">Total Brands: {{ getAllBrands.length }}</p>
          </v-toolbar>
        </template>

        <template v-slot:[`item.images`]="{ item }">
          <div class="p-2">
            <div v-viewer class="images">
              <img
                class="image"
                v-for="img in item.BrandImages"
                :key="img.id"
                :src="img.path"
                loading="lazy"
                :lazy-src="img.path"
                alt="billboard image"
              />
            </div>
          </div>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <span class="blue--text text--lighten-1">
            {{ item.name }}
          </span>
        </template>

        <template v-slot:[`item.brandCategory`]="{ item }">
          {{ getBrandCategory(item) }}
        </template>

        <template v-slot:[`item.briefs`]="{ item }">
          <span class="primary--text text--lighten-3">
            {{ getNumberOfBriefs(item) }}
          </span>
        </template>

        <template v-slot:[`item.plans`]="{ item }">
          <span class="primary--text text--lighten-3">
            {{ getNumberOfPlans(item) }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("brands");

export default {
  data() {
    return {
      loading: false,

      search: "",
      headers: [
        { text: "Image", value: "images" },
        { text: "Name", value: "name" },
        { text: "Brand Category", value: "brandCategory" },
        { text: "Number of Briefs", value: "briefs" },
        { text: "Number of Media Plans", value: "plans" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getAllBrands"]),
  },
  methods: {
    ...mapActions(["fetchAllBrands"]),

    async fetchBrands() {
      this.loading = true;
      this.fetchAllBrands();
      this.loading = false;
    },

    getBrandCategory(item) {
      if (item.BrandCategory) {
        return item.BrandCategory.name;
      } else {
        return "Not Yet Established";
      }
    },
    getNumberOfBriefs(item) {
      return (
        item.BrandOwners.flatMap((owner) => {
          return owner.Briefs;
        }).length || 0
      );
    },
    getNumberOfPlans(item) {
      return (
        item.BrandOwners.flatMap((owner) => {
          return owner.Briefs.flatMap((brief) => brief.Plans);
        }).length || 0
      );
    },
  },

  async created() {
    await this.fetchBrands();
  },
};
</script>

<style scoped>
.v-card {
  font-family: Manrope, Arial, sans-serif;
  box-shadow: 10px 10px 8px -6px rgba(225, 218, 218, 0.919) !important;
  -webkit-box-shadow: 10px 10px 8px -6px rgba(248, 243, 243, 0.931) !important;
  -moz-box-shadow: 10px 10px 8px -6px rgba(247, 238, 238, 0.939) !important;
  border-right: 1px solid white !important;
  border-top: 0.4px solid rgb(113, 111, 111) !important;
  border-left: 0.4px solid rgb(113, 111, 111) !important;
  border-bottom: 1px solid white !important;
}

.image {
  height: 60px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}

.image:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}
</style>
