<template>
  <v-card
    :color="$vuetify.theme.themes.dark.background"
    id="bus"
    class="pa-3 white--text"
    elevation="0"
  >
    <v-card-title class="pa-1">Brief Details</v-card-title>
    <v-card-text>
      <v-list
        dark
        :color="$vuetify.theme.themes.dark.background"
        id="bus"
        class="white--text"
      >
        <v-list-item>
          <v-list-item-title>
            Brand Name:
            <span class="v-list-item-span secondary--text">
              {{ brand_name }}
            </span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title>
            Brand Category:
            <span class="v-list-item-span secondary--text">
              {{ brand_category_name }}
            </span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title>
            Preferred Planner:
            <span class="v-list-item-span">
              {{ targetPlanners }}
            </span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title>
            Budget Allocation:
            <span class="v-list-item-span secondary--text">
              GH₵ {{ parseBudget() }}
            </span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title>
            Region of Focus:
            <span class="v-list-item-span">{{
              currentBrief.region && currentBrief.region.join(",")
            }}</span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          ><v-list-item-title
            >Campaign Type:
            <span class="v-list-item-span">{{
              currentBrief.campaignType
            }}</span>
          </v-list-item-title></v-list-item
        >
        <v-list-item
          ><v-list-item-title
            >SEC:
            <span class="v-list-item-span"
              >{{ currentBrief.sec && currentBrief.sec.join(",") }}
            </span>
          </v-list-item-title></v-list-item
        >
        <v-list-item
          ><v-list-item-title
            >Target:
            <span class="v-list-item-span">{{
              currentBrief.gender && currentBrief.gender.join(",")
            }}</span>
            <span class="v-list-item-span">{{
              currentBrief.ageGroup && currentBrief.ageGroup.join(",")
            }}</span>
          </v-list-item-title></v-list-item
        >
        <v-list-item>
          <v-list-item-title
            >Duration:

            <span class="v-list-item-span" v-if="currentBrief.end_date"
              >: {{ start_date }} to {{ end_date }}</span
            >
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>More Brief Notes: </v-list-item-title>
            <v-list-item-subtitle>
              {{ currentBrief.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import * as dayjs from "dayjs";
import { fetchBrandCategories } from "../../helpers/brands";

import { fetchAbontenPlanners } from "../../helpers/planners";

export default {
  data() {
    return {
      brandCategories: [],
      abontenPlanners: [],
    };
  },
  props: {
    currentBrief: {
      type: Object,
      default: () => ({
        id: null,
        BrandOwner: {
          Marketer: {
            client: null,
          },
          Planner: {
            client: null,
          },
          Brand: {
            name: null,
            BrandCategory: {
              name: null,
              id: null,
            },
          },
        },
        budget: 0,
        region: [],
        campaignType: null,
        sec: [],
        ageGroup: [],
        gender: [],
        start_date: null,
        end_date: null,
        description: null,
        TargetBriefs: [{ plannerId: null }],
      }),
    },
  },
  computed: {
    brand_name() {
      return this.currentBrief?.BrandOwner.Brand?.name || " ";
    },
    brand_category_name() {
      if (!this.currentBrief?.BrandOwner.Brand?.BrandCategory) return " ";
      const brand = this.brandCategories.find(
        (cat) => cat.id == this.currentBrief?.BrandOwner.Brand?.BrandCategory.id
      );
      return brand?.name;
    },
    targetPlanners() {
      if (this.currentBrief?.TargetBriefs?.length) {
        let planners = [];
        this.currentBrief.TargetBriefs.forEach((target) => {
          this.abontenPlanners.forEach((planner) => {
            if (planner.plannerId == target.plannerId) {
              planners.push(
                `${planner?.first_name} ${planner?.last_name} (${planner?.organization})`
              );
            }
          });
        });
        return planners.join(",");
      } else {
        return "";
      }
    },
    start_date() {
      return new Date(this.currentBrief.start_date).toDateString();
    },
    end_date() {
      return new Date(this.currentBrief.end_date).toDateString();
    },
  },
  methods: {
    parseBudget() {
      if (!this.currentBrief.budget) return 0;
      return new Intl.NumberFormat().format(this.currentBrief.budget);
    },

    duration() {
      let end = dayjs(this.currentBrief.end_date);
      let start = dayjs(this.currentBrief.start_date);

      let diff = end.diff(start, "M");

      if (!diff) return 0;
      return diff;
    },
  },

  async mounted() {
    this.brandCategories = await fetchBrandCategories();
    this.abontenPlanners = await fetchAbontenPlanners();
  },
};
</script>

<style scoped>
#bus {
  font-family: Manrope, Arial, sans-serif;
}

.v-list {
  display: flex;
  flex-direction: column;
}

.v-list-item-span {
  color: var(--v-textGray-base) !important;
}
</style>
