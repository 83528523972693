<template>
  <v-card dark elevation="0" class="px-2 mx-auto">
    <v-card-text>
      <v-container fluid>
        <v-row justify="center">
          <v-col v-for="(img, idx) in currentBillboard.Images" :key="idx">
            <div v-viewer>
              <v-row align="center" justify="center">
                <img
                  alt="Billboard Image"
                  class="previewImage"
                  :src="img.path"
                  :lazy-src="img.path"
                  loading="lazy"
                />
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6">
            <div class="d-flex justify-space-center align-center">
              <p>Code:</p>
              <p class="billboard-prop-value">
                {{ currentBillboard.bb_id }}
              </p>
            </div>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <div class="d-flex justify-space-center align-center">
              <p>Price:</p>
              <p class="billboard-prop-value">
                UGX {{ parseNumber(currentBillboard.rate_card) }}
              </p>
            </div>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <div class="d-flex justify-space-center align-center">
              <p>Print Price:</p>
              <p class="billboard-prop-value">
                UGX {{ parseNumber(currentBillboard.print_charge) }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <div class="d-flex justify-space-center align-center">
              <p>Location:</p>
              <p class="billboard-prop-value">
                {{ currentBillboard.address }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <div class="d-flex justify-space-center align-center">
              <p>Longitude:</p>
              <p class="billboard-prop-value">
                {{ currentBillboard.longitude }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <div class="d-flex justify-space-center align-center">
              <p>Latitude:</p>
              <p class="billboard-prop-value">
                {{ currentBillboard.latitude }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <div class="d-flex justify-space-center align-center">
              <p>Lighting:</p>
              <p class="billboard-prop-value">
                {{ currentBillboard.lighting }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <div class="d-flex justify-space-center align-center">
              <p>Medium:</p>
              <p class="billboard-prop-value">
                {{ currentBillboard.medium }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <div class="d-flex justify-space-center align-center">
              <p>Orientation:</p>
              <p class="billboard-prop-value">
                {{ currentBillboard.orientation }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <div class="d-flex justify-space-center align-center">
              <p>Side of Road from CBD:</p>
              <p class="billboard-prop-value">
                {{ currentBillboard.side_of_road }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <div class="d-flex justify-space-center align-center">
              <p>Site Run Up:</p>
              <p class="billboard-prop-value">
                {{ currentBillboard.site_run_up }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <div class="d-flex justify-space-center align-center">
              <p>Faces:</p>
              <p class="billboard-prop-value">
                {{ currentBillboard.faces }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <div class="d-flex justify-space-center align-center">
              <p>Clutter:</p>
              <p class="billboard-prop-value">
                {{ currentBillboard.clutter }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <div class="d-flex justify-space-center align-center">
              <p>Height:</p>
              <p class="billboard-prop-value">
                {{ currentBillboard.height }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <div class="d-flex justify-space-center align-center">
              <p>Length:</p>
              <p class="billboard-prop-value">{{ currentBillboard.length }}m</p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <div class="d-flex justify-space-center align-center">
              <p>Width:</p>
              <p class="billboard-prop-value">{{ currentBillboard.width }}m</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    currentBillboard: { type: Object, required: true },
  },
  methods: {
    parseNumber(amount) {
      return new Intl.NumberFormat().format(amount);
    },
  },
};
</script>

<style scoped>
.previewImage {
  height: 150px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}

.previewImage:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}

.billboard-prop-value {
  font-family: "Manrope", sans-serif;
}
</style>
