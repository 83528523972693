<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-btn color="secondary lighten-1" elevation="0" @click="createPPt()">
      PPT
      <v-icon color="white">mdi-file-powerpoint </v-icon>
    </v-btn>
  </div>
</template>

<script>
import PptxGenJS from "pptxgenjs";

import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { Buffer } from "buffer";
import { handleHttpError } from "@/helpers/utils";

export default {
  data() {
    return {
      loading: false,
      isInvisible: false,

      snapshotImageData: null,
      pptx: null,
    };
  },

  props: {
    campaign: {
      required: true,
    },
  },

  components: {},

  computed: {
    ...mapGetters({
      getPopulation: "billboards/getPopulation",
    }),
  },

  methods: {
    ...mapActions({
      fetchPop: "billboards/fetchPop",
    }),

    parseDate(dateStr) {
      return new Date(dateStr).toDateString();
    },
    async toDataURL(url) {
      try {
        const responseBlob = await axios.get(url, { responseType: "blob" });
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(responseBlob);
        });
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async createPPt() {
      try {
        this.loading = true;
        this.pptx = new PptxGenJS();

        this.pptx.defineLayout({ name: "HD", width: 20, height: 11.25 });

        // Set presentation to use new layout
        this.pptx.layout = "HD";

        let slide = this.pptx.addSlide();

        let svgContent = await this.fetchSvgSlideContent("slide_1");

        let modifiedSvgText = svgContent.replace(
          "{campaign_name}",
          `${this.campaign.name}`
        );
        svgContent = this.encodeBase64(modifiedSvgText);

        slide
          .addImage({
            data: svgContent,
            x: 0,
            y: 0,
            w: "100%",
            h: "100%",
          })
          .addImage({
            path: "/images/Abonten Logos-01.png",
            x: "87%",
            y: "75%",
            w: "20%",
            h: "35%",
          });

        slide = this.pptx.addSlide();
        svgContent = await this.fetchSvgSlideContent("slide_2");

        modifiedSvgText = svgContent
          .replace("total_monitorings", `${this.campaign.monitoringCount}`)
          .replace(
            "total_bbs_posted",
            `${this.campaign.candidatesWithMonitoringCount}`
          )
          .replace(
            "bbs_not_posted",
            `${this.campaign.candidatesWithoutMonitoringCount}`
          )
          .replace("campaign_progress", `${this.campaign.campaignProgress}`);

        svgContent = this.encodeBase64(modifiedSvgText);

        slide
          .addImage({
            data: svgContent,
            x: 0,
            y: 0,
            w: "100%",
            h: "100%",
          })
          .addImage({
            path: "/images/Abonten Logos-01.png",
            x: "87%",
            y: "75%",
            w: "20%",
            h: "35%",
          });

        for (const candidate of this.campaign.Candidates) {
          slide = this.pptx.addSlide();
          svgContent = await this.fetchSvgSlideContent("slide_4");

          let dynamicImageUrl = candidate.Billboard.Images[0]?.path;

          let imageBase64 = await this.getImageBase64(dynamicImageUrl);

          modifiedSvgText = svgContent
            .replace("Billboard_Medium", `${candidate.Billboard.medium}`)
            .replace(
              "Billboard_Dimensions",
              `${candidate.Billboard.length} * ${candidate.Billboard.width} Metres`
            )
            .replace("Billboard_Address", `${candidate.Billboard.address}`)
            .replace("Billboard_Ligthing", `${candidate.Billboard.lighting}`);

          svgContent = this.encodeBase64(modifiedSvgText);

          slide
            .addImage({
              data: svgContent,
              x: 0,
              y: 0,
              w: "100%",
              h: "100%",
            })
            .addImage({
              path: imageBase64,
              y: 2.294,
              x: 11.47,
              sizing: {
                type: "contain",
                w: 8.18, // Width (inches)
                h: "80%", // Height (inches)
              },
            })
            .addImage({
              path: "/images/Abonten Logos-01.png",
              x: "87%",
              y: "75%",
              w: "20%",
              h: "35%",
            });

          if (candidate.Monitorings.length) {
            for (const monit of candidate.Monitorings) {
              let monitoring_slide = this.pptx.addSlide();
              svgContent = await this.fetchSvgSlideContent("slide_4.1");
              dynamicImageUrl = monit.Image.path;
              let imageBase64 = await this.getImageBase64(dynamicImageUrl);

              modifiedSvgText = svgContent.replace(
                "Monitoring_Date",
                `${this.parseDate(monit.date)}`
              );
              svgContent = this.encodeBase64(modifiedSvgText);

              monitoring_slide
                .addImage({
                  data: svgContent,
                  x: 0,
                  y: 0,
                  w: "100%",
                  h: "100%",
                })
                .addImage({
                  path: imageBase64,
                  y: 0.294,
                  x: 4.1,
                  sizing: {
                    type: "contain",
                    w: 11, // Width (inches)
                    h: "80%", // Height (inches)
                  },
                })
                .addImage({
                  path: "/images/Abonten Logos-01.png",
                  x: "87%",
                  y: "75%",
                  w: "20%",
                  h: "35%",
                });
            }
          }
        }

        slide = this.pptx.addSlide();
        svgContent = await this.fetchSvgSlideContent("slide_5");
        svgContent = this.encodeBase64(svgContent);
        slide
          .addImage({
            data: svgContent,
            x: 0,
            y: 0,
            w: "100%",
            h: "100%",
          })
          .addImage({
            path: "/images/Abonten Logos-01.png",
            x: "87%",
            y: "75%",
            w: "20%",
            h: "35%",
          });

        this.pptx.author = "abonten.com";
        this.pptx.company = "Abonten";
        this.pptx.subject = "Campaign Progress Report";
        this.pptx.title = `${this.campaign.name} progress report`;

        await this.pptx.writeFile({
          fileName: `${this.campaign.name} monitoring report.pptx`,
        });

        // remove loader
        this.loading = false;
        this.pptx = null;
      } catch (err) {
        this.loading = false;
        handleHttpError(`Error creating PPT. ${err}`);
      }
    },

    async fetchSvgSlideContent(slide) {
      let res = await fetch(`/templates_svg/monitoring/${slide}.svg`);
      let svgContent = await res.text();
      return svgContent;
    },

    encodeBase64(data) {
      try {
        const svgTextBuffer = Buffer.from(data);
        const base64Data = `data:image/svg+xml;base64,${svgTextBuffer.toString(
          "base64"
        )}`;
        return base64Data;
      } catch (err) {
        console.error(err);
      }
    },

    async getImageBase64(imageUrl) {
      if (!imageUrl) return;
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    },
  },
};
</script>
<style>
.v-btn {
  background: rgba(143, 146, 161, 0.05);
  border-radius: 32px;
  margin: 0 10px;
}
</style>
