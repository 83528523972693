module.exports.filterScores = {
  lighting: {
    backlit: { score: 3, icon: "bbBacklit1" },
    frontlit: { score: 2, icon: "bbFrontlit1" },
    unlit: { score: 1, icon: "bbNolit" },
    led: { score: 3, icon: "bbBacklit" },
  },
  height: {
    "eye level": { score: 3, icon: "bbDgreen" },
    moderate: { score: 2, icon: "bbAmber" },
    high: { score: 1, icon: "bbRed" },
  },
  clutter: {
    solus: { score: 3, icon: "bbBlue" },
    average: { score: 2, icon: "bbNolit" },
    cluttered: { score: 1, icon: "billboardGreyed1" },
  },
  site_run_up: function (length) {
    length = parseInt(length);
    if (length >= 0 && length <= 50) {
      return { score: 1, icon: "bbNolit" };
    }
    if (length >= 51 && length <= 100) {
      return { score: 2, icon: "bbRed" };
    }
    if (length >= 101 && length <= 150) {
      return { score: 3, icon: "bbAmber" };
    }
    if (length >= 151 && length <= 200) {
      return { score: 4, icon: "bbDgreen" };
    }
    if (length > 200) {
      return { score: 5, icon: "bbBlue" };
    }
  },
  orientation: {
    landscape: { score: 3, icon: "bbDgreen" },
    portrait: { score: 2, icon: "bbAmber" },
    square: { score: 1, icon: "bbRed" },
  },
};

module.exports.totalBbScore = 17;
