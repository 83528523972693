<template>
  <v-card dark max-width="300">
    <v-navigation-drawer id="legend" permanent expand-on-hover right>
      <h3>Map Legend</h3>
      <div class="essentialLayers">
        <slot name="campaignBillboards"></slot>
        <slot name="allBillboards"></slot>
      </div>
      <div class="mapLayers">
        <h5 class="accordion" ref="mapLayers">Map Layers</h5>
        <div class="accordion-content">
          <div>
            Traffic Layer
            <input
              id="trafficChecked"
              type="checkbox"
              v-model="trafficChecked"
              @change="checkTraffic()"
            />
          </div>
        </div>
      </div>
      <div class="poiLayers">
        <h5 class="accordion" ref="poiLayers">POIs</h5>
        <div class="accordion-content">
          <div v-for="poi in poiLayers" :key="poi">
            <img :src="`/images/${poi}.png`" :alt="poi" />
            {{ poi }}
            <input
              :id="`${poi}Checked`"
              class="poi"
              type="checkbox"
              @change="handleChange($event, poi)"
              :value="poi"
              v-model="checkedPoi"
            />
          </div>
        </div>
      </div>
    </v-navigation-drawer>

    <v-dialog width="550" v-model="showPoisDialog">
      <v-card
        :color="$vuetify.theme.themes.dark.background"
        width="100%"
        dark
        elevation="0"
      >
        <v-toolbar dark :color="$vuetify.theme.themes.dark.background">
          <v-toolbar-title class="text-h6"
            >Choose points of interest</v-toolbar-title
          >
          <v-spacer></v-spacer>

          <v-btn icon @click="showPoisDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card
            :color="$vuetify.theme.themes.dark.background"
            dark
            width="100%"
            elevation="0"
            class="pa-2 mt-4 white--text"
          >
            <v-row align="center" class="my-4">
              <v-col cols="12" md="4" class="pa-0"> POI Type: </v-col>
              <v-col cols="12" md="8" class="pa-0">{{
                capitalize(poiQuery.poiType)
              }}</v-col>
            </v-row>
            <v-row align="center" class="my-4">
              <v-col cols="12" md="4" class="pa-0"> Area Name: </v-col>
              <v-col cols="12" md="8" class="pa-0">
                <gmap-autocomplete
                  placeholder="Enter Area Name"
                  class="autoComplete v-text-field v-text-field-outlined pa-0 ma-0"
                  @place_changed="setPoiPlace"
                  id="address"
                  :options="autoCompleteBounds"
                >
                </gmap-autocomplete>
              </v-col>
            </v-row>
            <v-row align="center" class="mb-4 mt-8">
              <v-col cols="12" md="4" class="pa-0"> Radius Distance: </v-col>
              <v-col cols="12" md="8" class="pa-0">
                <v-slider
                  class="ma-0 pa-0"
                  v-model="poiQuery.radius"
                  min="500"
                  step="100"
                  max="5000"
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="poiQuery.radius"
                      class="ma-0 pa-0"
                      hide-details
                      single-line
                      suffix="m"
                      type="number"
                      dark
                      style="width: 100px"
                    ></v-text-field>
                  </template>
                </v-slider>
              </v-col>
            </v-row>
            <v-row class="py-2 my-4">
              <v-col cols="12" md="4" class="pa-0">
                <p class="ma-0 pa-0">Poi Price Level:</p>
              </v-col>
              <v-col cols="12" md="8" class="pa-0">
                <v-select
                  v-model="poiQuery.price_level"
                  :items="pricingLevels"
                  label="Select Pricing Levels"
                  item-value="number"
                  item-text="title"
                  dark
                  chips
                  class="pa-0 ma-0"
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-row class="mb-5" justify="end">
            <v-btn @click="clearPOIQuery">Clear All</v-btn>
            <v-btn color="secondary" @click="checkPoi">Submit</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      mainLayer: ["billboards"],
      mapLayers: ["mapLayers", "ghanaDistricts"],
      poiLayers: [
        "bank",
        "hospital",
        "police",
        "school",
        "university",
        "bar",
        "kiosk",
        "pharmacy",
        "restaurant",
        "supermarket",
        "atm",
        "market",
        "church",
        "mosque",
      ],

      bbChecked: true,
      cmpBBChecked: true,
      trafficChecked: false,
      ghChecked: false,
      checkedPoi: [],

      showPoisDialog: false,
      pricingLevels: [
        { number: 0, label: "0", title: "Free" },
        { number: 1, label: "$", title: "Inexpensive" },
        { number: 2, label: "$$", title: "Moderate" },
        { number: 3, label: "$$$", title: "Expensive" },
        { number: 3, label: "$$$$", title: "Very Expensive" },
      ],

      poiQuery: {
        radius: 0,
        price_level: null,
        location: {
          latitude: 0,
          longitude: 0,
        },
        poiType: null,
      },

      autoCompleteBounds: {
        bounds: {
          north: 11.234926,
          south: 4.737842,
          east: 1.201117,
          west: -3.265621,
        },
        strictBounds: true,
      },
    };
  },
  emits: ["enable-poi"],
  methods: {
    ...mapMutations({
      setPoi: "pois/setPoi",
    }),

    checkPoi() {
      this.$emit("enable-poi", {
        checkedPois: this.checkedPoi,
        poiQuery: this.poiQuery,
      });
    },
    handleChange(event, poi) {
      this.poiQuery.poiType = poi;
      const isChecked = event.target.checked;
      if (isChecked) {
        this.showPoisDialog = true;
      } else {
        // empty its state
        this.setPoi({ poi: [] });
        this.$emit("enable-poi", {
          checkedPois: this.checkedPoi,
          poiQuery: this.poiQuery,
        });
      }
    },

    checkTraffic() {
      this.$emit("enable-traffic", this.trafficChecked);
    },

    capitalize(string) {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    },
    setPoiPlace(place) {
      this.poiQuery.location.latitude = place.geometry.location.lat();
      this.poiQuery.location.longitude = place.geometry.location.lng();
    },
    clearPOIQuery() {
      this.poiQuery = {
        radius: 0,
        price_level: null,
        location: {
          latitude: 0,
          longitude: 0,
        },
        poiType: null,
      };
    },
  },
};
</script>

<style scoped>
#legend {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  background: #fff176;
  color: #050303;
  padding: 5px;
  font-weight: bold;
  width: 30px;
  max-height: 80%;
  text-align: center;
  max-width: 270px;

  overflow-y: scroll;
}

.essentialLayers > div,
.aqLayers > div,
.accordion-content > div,
.eablpoiLayer > div {
  display: flex;
  justify-content: space-between;
  padding: 2px;
  align-items: center;
  font-size: 11px;
  text-transform: capitalize;
}
h5.accordion {
  text-align: center;
  width: 100%;
  background-color: whitesmoke;
  border: none;
  outline: none;
  margin: 5px 0 0 0;
  font-size: 13px;
  color: #745141;
  cursor: pointer;
  transition: background-color 0.2s linear;
}

.accordion-content {
  border-left: 1px solid whitesmoke;
  border-right: 1px solid whitesmoke;
  padding: 0 20px;
  /* max-height: 0; */
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}
.essentialLayers img,
.poiLayers img,
.mapLayers img,
.aqLayers img,
.eablpoiLayer img {
  height: 20px;
  padding: 0;
}
#legend > div:last-child {
  margin-bottom: 20px;
}
#legend h3,
#infoTab h3 {
  margin-top: 0;
  margin-bottom: 5px;
  text-transform: uppercase;
  text-align: center;
}
#legend img {
  vertical-align: middle;
}
#icons8 {
  height: 10px;
  padding: 1px;
  position: absolute;
  bottom: 0;
  right: 0;
}
#address {
  width: 96%;
  padding: 0 5px 0 10px;
  min-height: 3.2em;
  border-bottom: 1px solid grey;
}
.autoComplete {
  color: white;
}
</style>
