import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      campaigns: [
        {
          id: null,
          duration: null,
          start_date: null,
          end_date: null,
          comment: null,
          consent: false,
          name: null,
          Candidates: [
            {
              Billboard: {
                id: null,
                latitude: null,
                longitude: null,
                images: [{ path: null }],
                candidate: { status: false },
              },
              Comments: [
                {
                  comment: null,
                },
              ],
            },
          ],
          PlanComments: [],
          Planner: {
            User: {
              organization: null,
              first_name: null,
              last_name: null,
              planner_name: null,
            },
          },
          Brief: {
            name: null,
            budget: 0,
            BrandOwner: {
              Brand: {
                name: null,
              },
            },
          },
        },
      ],
      currentCampaign: {
        id: null,
        duration: null,
        start_date: null,
        end_date: null,
        comment: null,
        consent: false,
        name: null,
        Candidates: [
          {
            Billboard: {
              id: null,
              latitude: null,
              longitude: null,
              images: [{ path: null }],
              candidate: { status: false },
            },
            Comments: [
              {
                comment: null,
              },
            ],
          },
        ],
        PlanComments: [],
        Planner: {
          User: {
            organization: null,
            first_name: null,
            last_name: null,
            planner_name: null,
          },
        },
        Brief: {
          name: null,
          budget: 0,
          BrandOwner: {
            Brand: {
              name: null,
            },
          },
        },
      },
    };
  },
  mutations,
  getters,
  actions,
};
