<template>
  <v-card
    width="100%"
    min-height="650"
    id="bus"
    class="py-5 px-2 mx-auto white--text"
    :color="$vuetify.theme.themes.dark.background"
  >
    <v-toolbar
      :color="$vuetify.theme.themes.dark.background"
      flat
      class="white--text mb-2"
    >
      <v-toolbar-title>Current Briefs</v-toolbar-title>
    </v-toolbar>
    <v-progress-linear
      color="deep-purple accent-4"
      :active="loading"
      :indeterminate="loading"
    ></v-progress-linear>

    <v-data-table
      :items="getBriefs"
      :headers="headers"
      :search="search"
      sort-by="companyName"
      class="pa-3"
      :style="{
        'background-color': $vuetify.theme.themes.dark.background,
        color: $vuetify.theme.themes.dark.text,
      }"
      dark
      loading-text="Fetching Briefs... Please wait."
      no-data-text="There are no briefs currently"
    >
      <template slot="top">
        <v-toolbar color="grey darken-3" flat>
          <v-btn class="white--text" @click="fetchMyBriefs">
            Refresh
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-responsive max-width="40%" class="mx-auto mb-2">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-responsive>
        </v-toolbar>
      </template>

      <template v-slot:[`item.brand`]="{ item }">
        {{ item.BrandOwner.Brand.name || "Not Available" }}
      </template>

      <template v-slot:[`item.duration`]="{ item }">
        {{ duration(item.start_date, item.end_date) }} Days
      </template>

      <template v-slot:[`item.budget`]="{ item }">
        <span id="formatBudget">
          {{ formatBudget(item.budget) }}
        </span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="actions">
          <v-btn
            class="createCampaign"
            elevation="0"
            v-if="!viewOnCampaignsDialog"
            small
            @click.stop="
              selectBriefForCampaignList(item.id),
                (showCampaignListDialog = true)
            "
          >
            View Proposed Campaigns
          </v-btn>

          <v-btn
            icon
            v-if="!viewOnCampaignsDialog"
            @click.stop="
              (currentBriefId = item.id), (showBriefDetailsDialog = true)
            "
          >
            <v-icon color="secondary" title="More Information">
              mdi-eye
            </v-icon>
          </v-btn>

          <v-btn
            icon
            v-if="!viewOnCampaignsDialog"
            @click.stop="(currentBriefId = item.id), (showEditBriefForm = true)"
          >
            <v-icon title="Edit" color="green">mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            v-if="!viewOnCampaignsDialog"
            icon
            @click.stop="deleteItem(item)"
          >
            <v-icon title="Delete" color="red">mdi-delete</v-icon>
          </v-btn>

          <v-btn
            class="createCampaign"
            small
            elevation="0"
            v-if="viewOnCampaignsDialog"
            @click="selectBrief(item)"
          >
            Select for Campaign
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="showEditBriefForm" width="80%">
      <v-toolbar
        :color="$vuetify.theme.themes.dark.background"
        class="white--text mb-2"
      >
        <v-spacer></v-spacer>

        <v-btn icon @click="showEditBriefForm = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card elevation="0">
        <create-brief
          :currentBrief="currentBrief"
          operation="update"
        ></create-brief>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card
        :color="$vuetify.theme.themes.dark.background"
        class="white--text"
      >
        <v-progress-linear
          color="deep-purple accent-4"
          :active="deleteLoader"
          :indeterminate="deleteLoader"
        ></v-progress-linear>

        <v-card-title class="text-h5"
          >Are you sure you want to delete this Brief?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="blue darken-1" @click="closeDelete"
            >Cancel</v-btn
          >
          <v-btn outlined color="blue darken-1" @click="deleteBbConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showCampaignListDialog" width="90%">
      <v-toolbar
        :color="$vuetify.theme.themes.dark.background"
        flat
        class="white--text mb-2"
      >
        <v-spacer></v-spacer>

        <v-btn co icon @click="showCampaignListDialog = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <brief-campaigns></brief-campaigns>
    </v-dialog>

    <v-dialog v-model="showBriefDetailsDialog" width="60%">
      <v-card width="100%" elevation="0">
        <v-toolbar
          :color="$vuetify.theme.themes.dark.background"
          flat
          class="white--text mb-2"
        >
          <v-spacer></v-spacer>

          <v-btn icon @click="showBriefDetailsDialog = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div>
          <brief-details :currentBrief="currentBrief"></brief-details>
        </div>
      </v-card>
    </v-dialog>

    <alert></alert>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import { reportSuccess } from "@/helpers/utils.js";
import CreateBrief from "./CreateBrief.vue";
import BriefDetails from "@/components/briefs/BriefDetails.vue";
import BriefCampaigns from "@/components/briefs/BriefCampaigns.vue";

import * as dayjs from "dayjs";

export default {
  data() {
    return {
      search: "",
      loading: false,
      deleteLoader: false,

      headers: [
        { text: "Brand", value: "brand" },
        { text: "Duration", value: "duration" },
        { text: "Budget(GH₵)", value: "budget" },
        { text: "Campaign Type", value: "campaignType" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      showEditBriefForm: false,
      showBriefDetailsDialog: false,
      deleteDialog: false,

      currentBriefId: null,
      currentBrief: {
        id: null,
        BrandOwner: {
          Marketer: {
            client: null,
          },
          Planner: {
            client: null,
          },
          Brand: {
            name: null,
            BrandCategory: {
              id: null,
            },
          },
        },
        budget: 0,
        region: [],
        campaignType: null,
        sec: [],
        ageGroup: [],
        gender: [],
        start_date: null,
        end_date: null,
        description: null,
        TargetBriefs: [],
      },

      showCampaignListDialog: false,
    };
  },

  props: { viewOnCampaignsDialog: Boolean },

  components: { CreateBrief, BriefDetails, BriefCampaigns },

  computed: {
    ...mapGetters({
      getBriefs: "briefs/getBriefs",
      getBriefsCart: "briefs/getBriefsCart",
      getCurrentUser: "getCurrentUser",
    }),
  },

  emits: ["select-brief"],

  methods: {
    ...mapActions({
      addBriefToCart: "briefs/addBriefToCart",
      fetchBriefs: "briefs/fetchBriefs",
      fetchBrief: "briefs/fetchBrief",
      fetchAllBriefs: "briefs/fetchAllBriefs",
      deleteBrief: "briefs/deleteBrief",
    }),

    async fetchMyBriefs() {
      this.loading = true;
      await this.fetchBriefs(this.getCurrentUser.userId);
      this.loading = false;
    },

    ...mapMutations({ setCurrentBrief: "briefs/setCurrentBrief" }),

    formatBudget(budget) {
      return new Intl.NumberFormat().format(budget);
    },

    duration(start_date, end_date) {
      let end = dayjs(end_date);
      let start = dayjs(start_date);

      let diff = end.diff(start, "d");

      if (!diff) return 0;
      return diff;
    },

    async selectBrief(brief) {
      await this.addBriefToCart(brief);

      reportSuccess(
        "Brief selected successfully. Its details are on the panel on the left."
      );

      this.$emit("select-brief");
    },

    closeDialog() {
      this.showEditBriefForm = false;
    },

    deleteItem(brief) {
      this.currentBrief = brief;
      this.deleteDialog = true;
      this.deleteLoader = true;
    },

    closeDelete() {
      this.deleteDialog = false;
    },

    async deleteBbConfirm() {
      await this.deleteBrief(this.currentBrief.id);
      this.closeDelete();
      await this.fetchMyBriefs();
      this.deleteLoader = false;
    },

    selectBriefForCampaignList(brief_id) {
      this.setCurrentBrief(brief_id);
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.closeDelete();
    },
    viewOnCampaignsDialog: {
      immediate: true,
      async handler(val) {
        if (!val) return;
        this.loading = true;
        await this.fetchAllBriefs();
        this.loading = false;
      },
    },
    currentBriefId: {
      async handler(val) {
        this.loading = true;
        this.currentBrief = await this.fetchBrief(val);
        this.loading = false;
      },
    },
  },

  async mounted() {
    if (!this.viewOnCampaignsDialog) {
      await this.fetchMyBriefs();
    }
  },
};
</script>

<style scoped>
.v-card {
  font-family: Manrope, Arial, sans-serif;
  box-shadow: 10px 10px 8px -6px rgba(225, 218, 218, 0.919) !important;
  -webkit-box-shadow: 10px 10px 8px -6px rgba(248, 243, 243, 0.931) !important;
  -moz-box-shadow: 10px 10px 8px -6px rgba(247, 238, 238, 0.939) !important;
  border-right: 1px solid white !important;
  border-top: 0.4px solid rgb(113, 111, 111) !important;
  border-left: 0.4px solid rgb(113, 111, 111) !important;
  border-bottom: 1px solid white !important;
}
.v-toolbar {
  font-size: 24px;
  margin: 0 !important;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.createCampaign {
  background: rgba(0, 165, 255, 0.1);
  border-radius: 4px;
  padding: 15px 5px;

  color: #00a5ff;
  height: 24px;
}
#formatBudget {
  background: rgba(32, 201, 172, 0.1);
  height: 48px !important;
  color: #20c9ac;
  padding: 5px 14px;
}
.v-btn {
  margin: 15px 0;
  /* height: 32px !important; */
  /* width: 90%; */
  text-transform: none;
}
</style>
