<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-card elevation="2" width="400">
        <v-card-title class="primary--text">
          <v-row class="pa-2" justify="center" align="center">
            <v-avatar>
              <v-img
                :src="'/images/abonten-logo.png'"
                contain
                alt="logo"
                height="54"
              ></v-img>
            </v-avatar>
            abonten
          </v-row>
        </v-card-title>
        <v-progress-linear
          color="secondary"
          :active="loading"
          :indeterminate="loading"
        ></v-progress-linear>

        <div v-if="success">
          <v-card-text>
            <v-row justify="center">
              <p class="success--text">Email has been verified.</p>
            </v-row>
            <v-row justify="center">
              <p>You will be redirected to login</p>
            </v-row>
            <v-row justify="center">
              <p class="p-2">
                Click on
                <v-btn text href="/login" link color="secondary">login</v-btn>
                If not redirected.
              </p>
            </v-row>
          </v-card-text>
        </div>

        <div v-else>
          <v-card-text>
            <v-row justify="center">
              <p class="error--text">Error verifying account.</p>
            </v-row>
          </v-card-text>
        </div>
      </v-card>
    </v-row>
    <alert></alert>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("user");

export default {
  data() {
    return {
      success: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions(["verifyAccount"]),
  },
  async mounted() {
    this.loading = true;
    this.success = await this.verifyAccount({
      token: this.$route.params.token,
    });

    this.loading = false;
    if (this.success) this.$router.replace("/login");
  },
};
</script>

<style></style>
