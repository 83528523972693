<template>
  <div>
    <v-row class="my-4" justify="end">
      <v-btn
        color="accent"
        :rounded="false"
        elevation="2"
        outlined
        raised
        tile
        @click="resetFilter"
        >RESET</v-btn
      >
    </v-row>
    <v-expansion-panels dark flat multiple v-model="panel">
      <v-overlay :value="loading">
        <v-progress-circular
          color="secondary"
          size="70"
          width="7"
          indeterminate
        ></v-progress-circular>
      </v-overlay>

      <v-expansion-panel v-if="!viewOnCampaigns">
        <v-expansion-panel-header> Brand </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-autocomplete
            v-model="searchQuery.brand_name"
            auto-select-first
            chips
            clearable
            deletable-chips
            multiple
            small-chips
            cache-items
            solo-inverted
            hide-no-data
            label="e.g KFC"
            :items="getBrands"
            @change="fetchSearch"
          ></v-autocomplete>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="!viewOnCampaigns">
        <v-expansion-panel-header> Supplier </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-autocomplete
            v-model="searchQuery.supplier"
            auto-select-first
            chips
            clearable
            deletable-chips
            multiple
            small-chips
            cache-items
            solo-inverted
            hide-no-data
            :items="getSuppliers"
            @change="fetchSearch"
          ></v-autocomplete>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Lighting </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox
            v-model="searchQuery.lighting"
            v-for="el in filters.lighting"
            :key="el.item"
            :label="el.title"
            color="secondary"
            :value="el.item"
            @change="fetchSearch"
          ></v-checkbox>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Site Run Up </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-slider
            v-model="searchQuery.site_run_up"
            step="10"
            min="0"
            max="500"
            @change="fetchSearch"
          >
            <template v-slot:append>
              <v-text-field
                v-model="searchQuery.site_run_up"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                suffix="m"
                style="width: 70px"
              ></v-text-field>
            </template>
          </v-slider>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Price </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model.trim="searchQuery.rate_card.min"
                min="0"
                type="number"
                filled
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model.trim="searchQuery.rate_card.max"
                min="0"
                max="1000000"
                type="number"
                suffix="GH₵"
                filled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn width="100" @click="fetchSearch">Go</v-btn>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Height </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox
            v-model="searchQuery.height"
            v-for="el in filters.height"
            :key="el.item"
            :label="el.title"
            color="secondary"
            :value="el.item"
            @change="fetchSearch"
          ></v-checkbox>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Length </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-slider
            v-model="searchQuery.length"
            min="0"
            max="12"
            @change="fetchSearch"
          >
            <template v-slot:append>
              <v-text-field
                v-model="searchQuery.length"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                suffix="m"
                style="width: 60px"
              ></v-text-field>
            </template>
          </v-slider>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Width </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-slider
            v-model="searchQuery.width"
            min="0"
            max="20"
            @change="fetchSearch"
          >
            <template v-slot:append>
              <v-text-field
                v-model="searchQuery.width"
                class="mt-0 pt-0"
                hide-details
                single-line
                suffix="m"
                type="number"
                style="width: 60px"
              ></v-text-field>
            </template>
          </v-slider>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Orientation </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox
            v-model="searchQuery.orientation"
            v-for="el in filters.orientation"
            :key="el.item"
            :label="el.title"
            color="secondary"
            :value="el.item"
            @change="fetchSearch"
          ></v-checkbox>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Clutter </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox
            v-model="searchQuery.clutter"
            v-for="el in filters.clutter"
            :key="el.item"
            :label="el.title"
            color="secondary"
            :value="el.item"
            @change="fetchSearch"
          ></v-checkbox>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Medium </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox
            v-model="searchQuery.medium"
            v-for="el in filters.medium"
            :key="el.item"
            :label="el.title"
            color="secondary"
            :value="el.item"
            @change="fetchSearch"
          ></v-checkbox>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Side of Road </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox
            v-model="searchQuery.side_of_road"
            v-for="el in filters.side_of_road"
            :key="el"
            :label="el"
            color="secondary"
            :value="el"
            @change="fetchSearch"
          ></v-checkbox>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Faces </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox
            v-model="searchQuery.faces"
            v-for="el in 3"
            :key="el"
            :label="el.toString()"
            color="secondary"
            :value="el"
            @change="fetchSearch"
          ></v-checkbox>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Area Name </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="my2">
            <gmap-autocomplete
              placeholder="Enter Address"
              class="autoComplete v-text-field v-text-field-outlined"
              @place_changed="setPlace"
              id="address"
              :options="autoCompleteBounds"
            >
            </gmap-autocomplete>
          </v-row>

          <v-row class="my2">
            <v-slider
              v-model="searchQuery.withinAddress.radius"
              min="200"
              step="200"
              max="3000"
              @change="fetchSearch"
            >
              <template v-slot:append>
                <v-text-field
                  v-model="searchQuery.withinAddress.radius"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  suffix="m"
                  type="number"
                  style="width: 80px"
                ></v-text-field>
              </template>
            </v-slider>
          </v-row>

          <v-row class="my2" justify="center">
            <v-btn width="100" @click="fetchSearch">Go</v-btn>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          Points of Interest
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-0">
            <v-select
              v-model="searchQuery.nearPois.poiType"
              :items="pois"
              label="Select POIs"
              color="secondary"
              item-value="number"
              item-text="title"
            ></v-select>
          </v-row>
          <v-row class="mt-0">
            <gmap-autocomplete
              placeholder="POIs location"
              class="autoComplete v-text-field v-text-field-outlined"
              @place_changed="setPoiPlace"
              id="address"
              :options="autoCompleteBounds"
            >
            </gmap-autocomplete>
          </v-row>
          <v-row class="mt-8">
            <v-select
              v-model="searchQuery.nearPois.price_level"
              :items="pricingLevels"
              label="Select Pricing Levels"
              item-value="number"
              item-text="title"
              chips
              class="pa-0 ma-0"
            ></v-select>
          </v-row>

          <v-row class="mt-4">
            <v-slider
              v-model="searchQuery.nearPois.radius"
              min="200"
              step="100"
              max="1000"
            >
              <template v-slot:append>
                <v-text-field
                  v-model="searchQuery.nearPois.radius"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  suffix="m"
                  type="number"
                  style="width: 76px"
                ></v-text-field>
              </template>
            </v-slider>
          </v-row>

          <v-row justify="center">
            <v-btn width="100" @click="fetchSearch">Go</v-btn>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,

      searchQuery: {
        brand_name: [],
        supplier: [],
        lighting: [],
        site_run_up: 0,
        rate_card: { min: 0, max: 0 },
        height: [],
        length: 0,
        width: 0,
        orientation: [],
        clutter: [],
        medium: [],
        side_of_road: [],
        faces: [],
        withinAddress: { lat: 0, lng: 0, radius: 1000 },
        nearPois: {
          poiType: null,
          radius: 200,
          location: { latitude: 0, longitude: 0 },
          price_level: null,
        },
      },
      panel: [0],

      pricingLevels: [
        { number: 0, label: "0", title: "Free" },
        { number: 1, label: "$", title: "Inexpensive" },
        { number: 2, label: "$$", title: "Moderate" },
        { number: 3, label: "$$$", title: "Expensive" },
        { number: 3, label: "$$$$", title: "Very Expensive" },
      ],

      filters: {
        lighting: [
          { item: "backlit", title: "Backlit" },
          { item: "frontlit", title: "Frontlit" },
          { item: "unlit", title: "Unlit" },
          { item: "led", title: "LED" },
        ],
        height: [
          { item: "eye level", title: "Eye Level" },
          { item: "moderate", title: "Moderate" },
          { item: "high", title: "High" },
        ],
        orientation: [
          { item: "landscape", title: "Landscape" },
          { item: "portrait", title: "Portrait" },
          { item: "square", title: "Square" },
        ],
        clutter: [
          { item: "solus", title: "Solus" },
          { item: "average", title: "Average" },
          { item: "cluttered", title: "Cluttered" },
        ],
        medium: [
          { item: "Wall Wrap", title: "Wall Wrap" },
          { item: "RoadArch/Gantry", title: "RoadArch/Gantry" },
          { item: "Rooftops", title: "Rooftops" },
          { item: "LED", title: "LED" },
          { item: "Unipole", title: "Unipole" },
          { item: "Medium", title: "Medium" },
          { item: "Bus Stop", title: "Bus Stop" },
          { item: "Bus Stop Shelter", title: "Bus Stop Shelter" },
        ],

        side_of_road: ["Right", "Left", "Middle"],
      },

      pois: [
        "atm",
        "bank",
        "bar",
        "hospital",
        "kiosk",
        "market",
        "pharmacy",
        "police",
        "restaurant",
        "school",
        "supermarket",
        "university",
      ],

      autoCompleteBounds: {
        bounds: {
          north: 11.234926,
          south: 4.737842,
          east: 1.201117,
          west: -3.265621,
        },
        strictBounds: true,
      },
    };
  },

  props: { viewOnCampaigns: Boolean },

  computed: {
    ...mapGetters({
      getBrands: "billboards/getBrands",
      getSuppliers: "billboards/getSuppliers",
    }),
  },

  methods: {
    ...mapActions({
      searchBillboards: "billboards/searchBillboards",
      fetchBrands: "billboards/fetchBrands",
      fetchSuppliers: "billboards/fetchSuppliers",
    }),

    resetFilter() {
      this.searchQuery = {
        brand_name: [],
        supplier: [],
        lighting: [],
        site_run_up: 0,
        rate_card: { min: 0, max: 0 },
        height: [],
        length: 0,
        width: 0,
        orientation: [],
        clutter: [],
        medium: [],
        side_of_road: [],
        faces: [],
        withinAddress: { lat: 0, lng: 0, radius: 1000 },
        nearPois: {
          poiType: null,
          radius: 200,
          location: { latitude: 0, longitude: 0 },
          price_level: null,
        },
      };
    },

    async fetchSearch() {
      this.loading = true;
      await this.searchBillboards(this.searchQuery);
      this.loading = false;
    },

    async setPlace(place) {
      if (!place) return;
      this.searchQuery.withinAddress.lat = place.geometry.location.lat();
      this.searchQuery.withinAddress.lng = place.geometry.location.lng();
    },

    async setPoiPlace(place) {
      if (!place) return;
      this.searchQuery.nearPois.location.latitude =
        place.geometry.location.lat();
      this.searchQuery.nearPois.location.longitude =
        place.geometry.location.lng();
    },
  },

  async mounted() {
    this.loading = true;
    await this.fetchBrands();
    await this.fetchSuppliers();
    this.loading = false;
  },
};
</script>

<style scoped>
.v-expansion-panels {
  border-top: 2px solid #f68625;
  border-bottom: 2px solid #f68625;
}
.v-expansion-panel-header {
  padding: 10px !important;
}
.v-expansion-panel {
  margin-top: 10px;
}
.v-expansion-panel-content {
  padding: 1em 0 0 !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-input--selection-controls {
  padding: 0 !important;
  margin: 0 !important;
}
.v-input__slot {
  padding: 0 !important;
  margin: 0 !important;
}
.theme--light.v-messages {
  display: none;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 2em;
  padding: 0;
}

#address {
  width: 96%;
  padding: 0 5px 0 10px;
  min-height: 3.2em;
  border-bottom: 1px solid grey;
}
.autoComplete {
  color: white;
}
</style>
