import { handleHttpError, baseUrl, getToken } from "./utils";
const axios = require("axios").default;

async function fetchFilteredInspections(payload) {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/inspections?${payload}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (err) {
    const error =
      `Error fetching Inspections: ${err.response.data.error}` ||
      "An error occurred fetching Inspections. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

export { fetchFilteredInspections };
