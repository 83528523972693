<template>
  <v-card
    width="100%"
    min-height="650"
    id="bus"
    elevation="0"
    class="px-2 mx-auto white--text"
    :color="$vuetify.theme.themes.dark.background"
  >
    <v-toolbar elevation="0" color="grey darken-3">
      <v-row justify="center">
        <span class="text-h5 white--text">PROPOSED CAMPAIGNS</span>
      </v-row>
    </v-toolbar>

    <v-progress-linear
      color="deep-purple accent-4"
      :active="loading"
      :indeterminate="loading"
    ></v-progress-linear>

    <v-data-table
      :items="getCampaigns"
      :headers="headers"
      :search="search"
      sort-by="plan_name"
      class="elevation-1 pa-3"
      no-data-text="You have no running campaigns yet."
      loading-text="Fetching Campaigns... Please wait."
      :style="{
        'background-color': $vuetify.theme.themes.dark.background,
        color: $vuetify.theme.themes.dark.text,
      }"
      dark
    >
      <template slot="top">
        <v-toolbar color="grey darken-3" flat>
          <v-btn class="white--text" @click="fetchCampaigns">
            Refresh
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-responsive max-width="40%" class="mx-auto mb-2">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-responsive>
        </v-toolbar>
      </template>

      <template v-slot:[`item.brand`]="{ item }">
        <span class="ha-3 pa-3 rounded-sm secondary--text">
          {{ item.Brief?.BrandOwner?.Brand.name }}
        </span>
      </template>

      <template v-slot:[`item.planner`]="{ item }">
        {{ item.Planner.User.plannerName }}
      </template>

      <template v-slot:[`item.duration`]="{ item }">
        {{ duration(item.Brief?.start_date, item.Brief?.end_date) }} Days
      </template>

      <template v-slot:[`item.cost`]="{ item }">
        {{ totalCost(item.Candidates) }}
      </template>

      <template v-slot:[`item.sites`]="{ item }">
        {{ item.Candidates.length }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="redirectToCampaign(item.token)">
          <v-icon color="secondary" title="More Information"> mdi-eye </v-icon>
        </v-btn>
        <v-btn icon @click="copyToClipBoard(item.token)">
          <v-icon color="secondary" title="Copy Link">mdi-content-copy</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-snackbar v-model="showToast" color="success" timeout="2000" bottom>{{
      toastMessage
    }}</v-snackbar>

    <alert></alert>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import * as dayjs from "dayjs";

export default {
  data() {
    return {
      search: "",
      loading: false,
      showToast: false,
      toastMessage: "Link copied to clipboard.",

      headers: [
        { text: "Brand", value: "brand" },
        { text: "Campaign Name", value: "name", align: "center" },
        { text: "Created By", value: "planner" },
        { text: "Duration", value: "duration", sortable: "false" },
        { text: "Total Negotiated Cost(GH₵)", value: "cost", align: "center" },
        { text: "Sites", value: "sites" },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],

      currentCampaign: null,

      showCampaignDialog: false,
    };
  },

  computed: {
    ...mapGetters({
      getCampaigns: "campaigns/getCampaigns",
      getCurrentUser: "getCurrentUser",
    }),
  },

  methods: {
    ...mapMutations({ setCurrentCampaign: "campaigns/setCurrentCampaign" }),

    ...mapActions({
      fetchUserCampaigns: "campaigns/fetchUserCampaigns",
      fetchCampaign: "campaigns/fetchCampaign",
    }),

    duration(start_date, end_date) {
      let end = dayjs(end_date);
      let start = dayjs(start_date);

      let diff = end.diff(start, "d");

      if (!diff) return 0;
      return diff;
    },

    formatBudget(budget) {
      return new Intl.NumberFormat().format(budget);
    },
    totalCost(candidates) {
      if (!candidates.length) return;
      return candidates.reduce((acc, curr) => {
        return acc + curr.negotiated_net_rate;
      }, 0);
    },

    async redirectToCampaign(campaignToken) {
      this.$router.push({ path: `/proposed-campaign/${campaignToken}` });
    },

    copyToClipBoard(campaignToken) {
      let link = `https://app.abonten.com/proposed-campaign/${campaignToken}`;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.showToast = true;
        })
        .catch(() => {
          console.error("Unable to copy link.");
        });
    },

    async fetchCampaigns() {
      this.loading = true;
      await this.fetchUserCampaigns(this.getCurrentUser.userId);

      this.loading = false;
    },
  },

  async mounted() {
    await this.fetchCampaigns();
  },
};
</script>
