<template>
  <v-card
    width="100%"
    min-height="650"
    id="bus"
    elevation="0"
    class="px-2 mx-auto white--text"
    :color="$vuetify.theme.themes.dark.background"
  >
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-card-text>
      <div class="d-flex justify-end align-center">
        <p class="ma-0 pr-2 white--text">Actions:</p>
        <v-btn
          depressed
          class="heartbeat"
          @click="approvalDialog = !approvalDialog"
        >
          <span>Approve/Disapprove</span>
        </v-btn>
      </div>
      <campaign-item></campaign-item>
    </v-card-text>

    <v-dialog v-model="approvalDialog" max-width="65%">
      <v-toolbar
        :color="$vuetify.theme.themes.dark.background"
        class="white--text mb-2"
      >
        <v-spacer>
          <v-toolbar-title
            >Approve Campaign Billboards</v-toolbar-title
          ></v-spacer
        >

        <v-btn icon @click="approvalDialog = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card
        class="px-2 mx-auto white--text"
        :color="$vuetify.theme.themes.dark.background"
      >
        <v-card-text>
          <v-row class="d-flex flex-column">
            <v-form v-model="valid" ref="planForm">
              <div>
                <v-text-field
                  dark
                  label="Email"
                  placeholder="Enter Marketer Email"
                  :rules="rules.emailRules"
                  v-model.trim="marketerEmail"
                  required
                  >Email</v-text-field
                >
              </div>

              <div>
                <v-radio-group row dark v-model="currentCampaign.approval">
                  <span class="white--text pr-4"
                    >Approve whole Campaign ?
                  </span>
                  <v-radio
                    color="green"
                    label="Approve"
                    :value="true"
                  ></v-radio>
                  <v-radio
                    color="red"
                    label="Disapprove"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </div>

              <div>
                <v-textarea
                  dark
                  filled
                  placeholder="Enter any comment on the campaign."
                  label="Campaign comment"
                  v-model.trim="planComment"
                >
                </v-textarea>
              </div>
            </v-form>
          </v-row>
          <div class="ma-2">
            <h3 class="white--text candidates-heading">Billboard Candidates</h3>
            <v-row
              class="py-3 candidates"
              v-for="(candidate, idx) in currentCampaign.Candidates"
              :key="candidate.id"
              justify="space-around"
            >
              <v-col cols="12" md="3">
                <div class="p-2 images">
                  <span class="white--text">
                    {{ idx + 1 }}
                  </span>
                  <div v-viewer>
                    <img
                      class="image"
                      v-for="img in candidate.Billboard.Images"
                      :key="img.id"
                      :src="img.path"
                      alt="Billboard Image"
                      :lazy-src="img.path"
                      loading="lazy"
                    />
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="3">
                <p class="white--text">
                  Size: {{ candidate.Billboard.length }} *
                  {{ candidate.Billboard.width }} m
                </p>
                <p class="white--text">
                  Address: {{ candidate.Billboard.address }}
                </p>
                <p class="white--text">
                  Faces: {{ candidate.Billboard.faces }}
                </p>
                <p class="white--text">
                  Lighting: {{ candidate.Billboard.lighting }}
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <div>
                  <v-radio-group
                    row
                    dark
                    v-model="candidate.approval"
                    :rules="rules.candidateRadioButtons"
                  >
                    <span class="white--text pr-4">Approve Billboard ? </span>
                    <v-radio
                      color="green"
                      label="Approve"
                      value="approved"
                    ></v-radio>
                    <v-radio
                      color="red"
                      label="Disapprove"
                      value="disapproved"
                    ></v-radio>
                  </v-radio-group>
                </div>

                <v-textarea
                  dark
                  filled
                  placeholder="Enter any comment on the billboard."
                  label="Billboard Comment"
                  v-model.trim="candidate.newComment"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </div>
          <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            color="secondary"
            @click="toTop"
          >
            <v-icon>mdi-arrow-up-drop-circle</v-icon>
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="approvalDialog = !approvalDialog">Close</v-btn>
          <v-btn @click="submit()" color="success">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <alert></alert>
  </v-card>
</template>

<script>
import CampaignItem from "@/components/campaign/CampaignItem.vue";

import { mapActions, mapMutations } from "vuex";
import { updateCampaignComment } from "../../helpers/campaigns";
import { handleHttpError, simpleNotification } from "@/helpers/utils";

export default {
  data() {
    return {
      token: null,
      loading: false,
      valid: false,
      fab: false,

      rules: {
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        candidateRadioButtons: [(v) => !!v || "Please choose an Action."],
      },
      currentCampaign: {
        id: null,
        budget: null,
        duration: null,
        start_date: null,
        end_date: null,
        name: null,
        Candidates: [
          {
            id: null,
            negotiated_net_rate: null,
            status: null,
            availability_confirmation: null,
            Comments: [
              {
                comment: null,
              },
            ],
            Billboard: {
              id: null,
              latitude: null,
              longitude: null,
              Images: [{ path: null }],
              MediaOwner: {
                User: {
                  organization: null,
                  first_name: null,
                  last_name: null,
                },
              },
            },
          },
        ],
        PlanComments: [
          {
            comment: null,
          },
        ],
        Planner: {
          User: {
            organization: null,
            first_name: null,
            last_name: null,
          },
        },
        Brief: {
          name: null,
          budget: null,
          BrandOwner: {
            Brand: {
              name: null,
            },
          },
        },
      },

      approvalDialog: false,

      marketerEmail: null,
      planComment: null,

      candidates: [],
    };
  },

  components: { CampaignItem },

  methods: {
    ...mapMutations({ setCurrentCampaign: "campaigns/setCurrentCampaign" }),

    ...mapActions({
      fetchCampaignByToken: "campaigns/fetchCampaignByToken",
    }),

    async submit() {
      if (this.$refs.planForm.validate()) {
        if (!this.valid) return;
        this.loading = true;

        let payload = {
          marketerEmail: this.marketerEmail,
          planComment: this.planComment,
          planId: this.currentCampaign.id,
          approval: this.currentCampaign.approval,
          token: this.token,
          candidates: this.currentCampaign.Candidates,
        };
        await updateCampaignComment(payload);

        this.loading = false;

        setTimeout(() => this.$router.push({ path: "/briefs" }), 5000);
      } else {
        this.getInvalidTextFields();
      }
    },
    getInvalidTextFields() {
      const formControls = this.$refs.planForm.$children;
      const invalidElements = [];

      formControls.forEach((control) => {
        if (!control.$refs.input || !control.validate(true)) {
          invalidElements.push(control);
        }
      });
      if (invalidElements.length > 0) {
        invalidElements[0].$refs.input.focus();
      }
    },

    toTop() {
      this.$vuetify.goTo(0);
    },

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
  },
  watch: {
    approvalDialog: {
      handler(val) {
        if (val) {
          //
          simpleNotification(
            "Please make sure you fill the entire form. Approve/Disapprove individual billboards and finally hit the submit button."
          );
        } else {
          //closed the dialog
          handleHttpError("Approval Not submitted.");
        }
      },
    },
  },

  async created() {
    this.token = this.$route.params.campaignToken;

    this.currentCampaign = await this.fetchCampaignByToken(this.token);
    this.setCurrentCampaign(this.currentCampaign);

    this.loading = false;
  },
};
</script>
<style scoped>
.v-card {
  font-family: Manrope, Arial, sans-serif;
}
.heartbeat {
  -webkit-animation: heartbeat 1.8s ease-in-out infinite both;
  animation: heartbeat 1.8s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.images {
  overflow: hidden;
  /* max-width: 5em; */
}
.image {
  height: 120px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}
.image:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}
.candidates-heading {
  text-align: center;
  text-decoration: underline;
}
.candidates {
  border-bottom: 1px solid rgb(226, 246, 172);
}
</style>
