<template>
  <v-card-text class="features">
    <div>
      Male Population:
      <b>
        {{ parseNumber(population.maleTotal) }}
      </b>
    </div>

    <div>
      Female Population:
      <b>
        {{ parseNumber(population.femaleTotal) }}
      </b>
    </div>
    <div>
      Total Population:
      <b>
        {{ parseNumber(population.totalPop) }}
      </b>
    </div>
  </v-card-text>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    population: {
      type: Object,
      required: true,
    },
  },
  methods: {
    parseNumber(num) {
      if (!num) return 0;
      return new Intl.NumberFormat().format(num);
    },
  },
};
</script>

<style scoped>
.features {
  font-family: Manrope, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}
.features > div {
  display: flex;
  justify-content: space-between;
  color: black;
  font-size: 13px;
}
</style>
