<template>
  <section
    class="auth-main-container source-sans-pro"
    style="background-color: black"
  >
    <div class="fixed-background">
      <v-img
        class="bg-img"
        :src="backgrounds[country ? country.value : 'default']"
        cover
      ></v-img>
    </div>

    <div class="auth-container pt-8">
      <div class="logo-row">
        <v-img
          src="/images/redesign/logo-white.png"
          width="140"
          height="140"
          contain
          style="margin-bottom: -0px"
        ></v-img>
        <a href="/home">
          <v-icon color="white">mdi-chevron-left-circle-outline</v-icon>
        </a>
      </div>
      <v-col :lg="cardWidth ?? 4">
        <v-card class="auth-card" v-if="!noCard">
          <slot></slot>
        </v-card>
        <slot v-if="noCard"></slot>
      </v-col>
      <v-row>
        <div class="other-actions mt-4">
          <slot name="bottom"></slot>
        </div>
      </v-row>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      backgrounds: {
        default: "/images/redesign/home-hero.png",
        gh: "/images/redesign/gh-hero.png",
        ng: "/images/redesign/ng-hero.png",
        cm: "/images/redesign/cm-hero.png",
        ci: "/images/redesign/ci-hero.png",
      },
    };
  },
  props: {
    cardWidth: Number,
    noCard: Boolean,
  },
  computed: {
    ...mapState(["country"]),
  },
  mounted() {
    this.$store.dispatch("fetchCountry");
  },
};
</script>

<style scoped>
.fixed-background {
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
}

.bg-img {
  filter: brightness(0.1);
  height: 100%;
}

.auth-main-container {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 60px 0;
  overflow: auto;
}
.auth-container {
  z-index: 10;
  border-radius: 48px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  padding-bottom: 60px;
  /* backdrop-filter: opacity(.1); */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.auth-container::-webkit-scrollbar {
  display: none;
}

.auth-card {
  /* border: .1px solid #FDFAFA; */
  background: linear-gradient(
    253.09deg,
    rgba(217, 217, 217, 0.25) -7.53%,
    rgba(217, 217, 217, 0) 97.04%
  );
  /* background: linear-gradient(246.16deg, rgba(253, 250, 250, 0.25) 4.55%, rgba(255, 251, 251, 0.25) 39.51%); */
  backdrop-filter: blur(25px);
  border-radius: 33px;
  color: #fff;
  padding: 64px 60px;
}

@media screen and (max-width: 576px) {
  .auth-card {
    padding: 64px 12px;
  }

  .auth-container {
    width: 100%;
  }
}

.auth-card h4 {
  font-size: 36px;
  font-weight: 400;
  color: #fff;
}

.other-actions {
  z-index: 99;
  width: 100%;
}

.other-actions a {
  color: #fff !important;
  text-decoration: none;
}

.logo-row {
  display: flex;
  position: relative;
  width: 100%;
}

.logo-row a {
  text-decoration: none !important;
  position: absolute;
  top: 0;
  right: 40px;
}
</style>
