<template>
  <v-container fluid fill-height>
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-row align="center" class="ma-3" justify="center">
      <v-btn
        v-for="btn in buttons"
        elevation="0"
        :key="btn.name"
        @click="getContent(btn.cmp)"
        :class="{ active: btn.cmp === currentComponent }"
      >
        {{ btn.name }}
      </v-btn>
    </v-row>

    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="secondary"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up-drop-circle</v-icon>
    </v-btn>

    <v-card elevation="1" width="100%" class="ma-0 pa-4" color="white">
      <v-card-text>
        <keep-alive>
          <div v-html="fileContent" id="documentation-content"></div>
        </keep-alive>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { marked } from "marked";

export default {
  data() {
    return {
      fileContent: null,

      currentComponent: null,

      loading: false,
      fab: false,
    };
  },

  props: {
    buttons: {
      type: Array,
      required: true,
    },
  },

  methods: {
    async getContent(cmp) {
      this.loading = true;
      this.currentComponent = cmp;

      let doc = await fetch(cmp);
      doc = await doc.text();

      this.fileContent = marked.parse(doc);

      this.loading = false;
    },

    toTop() {
      this.$vuetify.goTo(0);
    },

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
  },

  async created() {
    await this.getContent(this.buttons[0].cmp);
  },
};
</script>

<style>
.container {
  width: 100vw;
}
#documentation-content {
  color: black;
  font-family: cursive;
  font-size: 1em;
}

.v-btn {
  background: rgba(143, 146, 161, 0.05);
  margin: 0 10px;
  text-transform: none;
}
.active {
  background: #f68625 !important;
}

h1 {
  text-align: center;
  text-decoration: underline;
  margin: 15px;
}
h2 {
  margin: 10px;
}
img {
  max-width: 90%;
  padding: 10px;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
