<template>
  <v-card
    min-width="100%"
    min-height="650"
    id="bus"
    elevation="0"
    class="py-5 px-2 mx-auto white--text"
    :color="$vuetify.theme.themes.dark.background"
  >
    <v-toolbar color="grey darken-3" flat class="mb-2 white--text">
      <v-toolbar-title>Current Billboards</v-toolbar-title>
    </v-toolbar>

    <v-data-table
      :items="getBillboards"
      :headers="headers"
      :search="search"
      sort-by="name"
      class="pa-2 dataTable"
      loading-text="Fetching Billboards... Please wait"
      no-data-text="User does not have Billboards"
      :style="{
        'background-color': $vuetify.theme.themes.dark.background,
        color: $vuetify.theme.themes.dark.text,
      }"
      dark
    >
      <template slot="top">
        <v-toolbar color="grey darken-3" flat>
          <v-btn class="white--text" @click="fetchBBS">
            Refresh
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-responsive max-width="90%">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              full-width
            ></v-text-field>
          </v-responsive>

          <v-dialog v-model="deleteDialog" max-width="500px">
            <v-card>
              <v-progress-linear
                color="deep-purple accent-4"
                :active="loading"
                :indeterminate="loading"
              ></v-progress-linear>

              <v-card-title class="text-h5"
                >Are you sure you want to delete this Billboard?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteBbConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.number`]="{ _, index }">
        {{ _ }} {{ index + 1 }}
      </template>

      <template v-slot:[`item.images`]="{ item }">
        <div class="p-2">
          <div v-viewer class="images">
            <img
              class="image"
              v-for="img in item.Images"
              :key="img.id"
              :src="img.path"
              :lazy-src="img.path"
              loading="lazy"
              alt="billboard image"
            />
          </div>
        </div>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          icon
          @click="
            (showMoreBillboardDetailsForm = true), (currentBillboard = item)
          "
        >
          <v-icon color="secondary" title="More Information"> mdi-eye </v-icon>
        </v-btn>

        <v-icon
          class="actions--icon"
          color="green"
          @click.stop="(currentBillboard = item), (showBbForm = true)"
        >
          mdi-pencil</v-icon
        >

        <v-icon
          class="actions--icon"
          color="error"
          title="Delete"
          @click="deleteItem(item.id)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="showBbForm" width="80%">
      <v-toolbar :color="$vuetify.theme.themes.dark.background" flat>
        <v-spacer></v-spacer>
        <v-btn icon @click="showBbForm = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card
        class="white--text"
        :color="$vuetify.theme.themes.dark.background"
        elevation="0"
      >
        <billboard-form
          operation="edit"
          @close-form="showBbForm = false"
          :currentBillboard="currentBillboard"
        ></billboard-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showMoreBillboardDetailsForm" width="80%">
      <v-toolbar dark flat>
        <span class="text-h5"> Billboards Data</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="showMoreBillboardDetailsForm = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <billboard-view :currentBillboard="currentBillboard"></billboard-view>
    </v-dialog>
    <alert></alert>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BillboardForm from "@/components/media_owner/OwnerBbform.vue";

import BillboardView from "@/components/billboard/BillboardFeature.vue";
export default {
  data() {
    return {
      search: "",
      loading: false,
      deleteDialog: false,
      dialog: false,

      billboard_id: null,
      showBbForm: false,
      currentBillboard: {},
      showMoreBillboardDetailsForm: false,

      headers: [
        { text: "#", align: "start", sortable: false, value: "number" },

        {
          text: "Image",
          sortable: false,
          value: "images",
        },
        { text: "Price (GH₵)", value: "rate_card" },
        { text: "Located At", value: "address" },
        { text: "Lighting", value: "lighting" },
        { text: "Length (m)", value: "length" },
        { text: "Width (m)", value: "width" },
        { text: "Site Run Up(m)", value: "site_run_up" },
        { text: "Orientation", value: "orientation" },
        { text: "Height", value: "height" },
        { text: "Clutter", value: "clutter" },
        { text: "Medium", value: "medium" },
        { text: "Added On", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  components: { BillboardForm, BillboardView },

  computed: {
    ...mapGetters({
      getBillboards: "billboards/getBillboards",
      getCurrentUser: "getCurrentUser",
    }),
  },

  methods: {
    ...mapActions({
      fetchMyBbs: "billboards/fetchMyBbs",
      deleteBB: "billboards/deleteBB",
    }),

    async fetchBBS() {
      await this.fetchMyBbs(this.getCurrentUser.userId);
    },

    close() {
      this.dialog = false;
    },

    closeDelete() {
      this.deleteDialog = false;
    },

    deleteItem(id) {
      this.billboard_id = id;
      this.deleteDialog = true;
      this.loading = true;
    },

    async deleteBbConfirm() {
      this.deleteBB(this.billboard_id);
      this.closeDelete();
      this.loading = false;
      await this.fetchBBS();
    },

    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date.toDateString();
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.closeDelete();
    },
  },
};
</script>

<style scoped>
.v-card {
  font-family: Manrope, Arial, sans-serif;
}

.dataTable {
  box-shadow: 10px 10px 8px -6px rgba(225, 218, 218, 0.919) !important;
  -webkit-box-shadow: 10px 10px 8px -6px rgba(248, 243, 243, 0.931) !important;
  -moz-box-shadow: 10px 10px 8px -6px rgba(247, 238, 238, 0.939) !important;
  border-right: 1px solid white !important;
  border-top: 0.4px solid rgb(113, 111, 111) !important;
  border-left: 0.4px solid rgb(113, 111, 111) !important;
  border-bottom: 1px solid white !important;
}

.v-data-table__expanded__content {
  background-color: antiquewhite;
}

.image {
  height: 50px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}

.image:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}

.actions--icon {
  padding: 0 5px;
}

.actions--icon:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}

.v-btn {
  margin: 15px 0;
  text-transform: none;
}
</style>
