<template>
  <v-container fluid class="pa-5">
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-row align="center" justify="center">
      <v-toolbar height="34" flat color="expansionPanels" class="my-1">
        <v-spacer />
        <v-toolbar-title class="black--text"
          >Choose Pricing Model</v-toolbar-title
        >
        <v-spacer />
      </v-toolbar>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="auto" v-for="tier in getTiers" :key="tier.id" class="ma-4">
        <v-card elevation="6" color="white" width="320" height="500">
          <v-card-title
            class="pa-4 white--text secondary lighten-2 text-uppercase text-h2"
          >
            <v-row justify="center">
              {{ tier.name }}
            </v-row>
          </v-card-title>

          <v-card-subtitle class="mt-4">
            <v-row justify="center">
              <p class="ma-4">
                <span class="text-h3 pa-0 secondary--text">
                  ${{ tier.cost }}</span
                >
                <span class="text-h6 pa-0"> / mo </span>
              </p>
            </v-row>
          </v-card-subtitle>

          <v-card-text>
            <v-divider></v-divider>
            <p
              class="pa-2 ma-0 services"
              v-for="service in tier.services"
              :key="service.id"
            >
              <v-icon color="green">mdi-check-all</v-icon>
              {{ parseServices(service.name) }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center" align="end">
              <v-btn
                class="white--text"
                color="success darken-2"
                @click="checkOutPricing(tier)"
                >Subscribe</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <alert></alert>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      tierPricing: null,
      checked: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getTiers: "tiers/getTiers",
    }),
  },
  methods: {
    ...mapActions({
      fetchTiers: "tiers/fetchTiers",
      createSubscription: "subscriptions/createSubscription",
    }),

    parseServices(serv) {
      let services = serv.split("_");
      return this.capitalizeWords(services).join(" ");
    },

    capitalizeWords(arr) {
      return arr.map((element) => {
        return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
      });
    },

    async checkOutPricing(tier) {
      this.loading = true;
      const res = await this.createSubscription(tier);
      if (res.paystackData) {
        window.open(res.paystackData.authorization_url, "_blank");
        this.loading = false;
      } else {
        //
        this.$router.replace("/");
      }
    },
  },

  async mounted() {
    await this.fetchTiers();
  },
};
</script>
<style scoped>
.container {
  font-family: DM Sans, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
}
.services {
  border-bottom: 1px solid #dfd6d6;
}
.v-card__actions {
  height: 200px;
}
.pricing-models {
  font-family: "Manrope", Arial, sans-serif;
  color: #594343 !important;
}
</style>
