import { render, staticRenderFns } from "./ChooseSubscription.vue?vue&type=template&id=772387f0&scoped=true&"
import script from "./ChooseSubscription.vue?vue&type=script&lang=js&"
export * from "./ChooseSubscription.vue?vue&type=script&lang=js&"
import style0 from "./ChooseSubscription.vue?vue&type=style&index=0&id=772387f0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "772387f0",
  null
  
)

export default component.exports