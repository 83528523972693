import { handleHttpError, baseUrl, getToken } from "./utils";

const axios = require("axios").default;

async function fetchAllRegions() {
  try {
    const token = await getToken();

    const response = await axios.get(`${baseUrl}/regions`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    let regions = response.data.data[0].regions;
    return regions;
  } catch (err) {
    const error =
      `Error fetching Regions: ${err}` ||
      "An error occurred fetching Regions. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

export { fetchAllRegions };
