<template>
  <v-container fluid>
    <v-row align="center" justify="center" class="ma-2">
      <v-col id="driver" cols="12" lg="2" xl="2" sm="2" md="2">
        <v-row>
          <v-card
            width="100%"
            elevation="0"
            :color="$vuetify.theme.themes.dark.background"
            class="white--text"
          >
            <v-btn
              color="expansionPanels"
              elevation="0"
              v-for="btn in buttons"
              :key="btn.name"
              @click="setComponent(btn.cmp)"
              :class="{ active: btn.cmp === currentComponent }"
            >
              <v-icon color="black">{{ btn.icon }}</v-icon>
              {{ btn.name }}
            </v-btn>
          </v-card>
        </v-row>
      </v-col>

      <v-col cols="12" sm="10" lg="10" xl="10" md="10">
        <v-row>
          <keep-alive>
            <component
              :is="currentComponent"
              :buttons="helpButtons"
            ></component>
          </keep-alive>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BillboardForm from "@/components/media_owner/OwnerBbform.vue";
import ViewOwnerbbs from "@/components/media_owner/ViewOwnerbbs.vue";
import DocumentationHelper from "../../components/DocumentationHelper.vue";

export default {
  data() {
    return {
      currentComponent: "ViewOwnerbbs",

      buttons: [
        {
          name: "View My Billboards",
          icon: "mdi-monitor-dashboard",
          cmp: "ViewOwnerbbs",
        },
        {
          name: "Add Billboard",
          icon: "mdi-plus-box",
          cmp: "BillboardForm",
        },
        {
          name: "Help/Support",
          icon: "mdi-desktop-mac",
          cmp: "DocumentationHelper",
        },
      ],
      helpButtons: [
        {
          name: "Create Billboard Help",
          cmp: "/abonten_manual/billboards/add/add_billboard.md",
        },
        {
          name: "View Billboards Help",
          cmp: "/abonten_manual/billboards/view/view_billboard.md",
        },
      ],
    };
  },
  components: {
    BillboardForm,
    ViewOwnerbbs,
    DocumentationHelper,
  },
  methods: {
    setComponent(cmp) {
      this.currentComponent = cmp;
    },
  },
};
</script>

<style scoped>
.v-container {
  font-family: Manrope, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  background: #424242;
}

#driver {
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.v-btn {
  margin: 10px;
  height: 48px !important;
  width: 90%;
  text-transform: none;
}
.active {
  background: #f68625 !important;
}
.v-icon {
  margin: 0 5px;
}
</style>
