<template>
  <v-card
    min-width="100%"
    min-height="650"
    id="bus"
    elevation="0"
    class="px-2 mx-auto white--text"
    :color="$vuetify.theme.themes.dark.background"
  >
    <v-toolbar
      elevation="0"
      :color="$vuetify.theme.themes.dark.background"
      flat
      class="white--text"
    >
      <v-row justify="center">
        <span class="text-h5">BILLBOARD FORM</span>
      </v-row>
    </v-toolbar>

    <v-progress-linear
      color="deep-purple accent-4"
      :active="loading"
      :indeterminate="loading"
    ></v-progress-linear>

    <v-card-text class="pt-0">
      <v-form v-model="valid" ref="form">
        <v-container fluid>
          <v-row justify="center" v-if="billboard.id">
            <v-col v-for="(img, idx) in billboard.Images" :key="idx">
              <div v-viewer v-if="billboard.Images[0].path">
                <v-card :color="$vuetify.theme.themes.dark.background" flat>
                  <v-card-text class="pt-0">
                    <v-row align="center" justify="center">
                      <img
                        alt="Billboard Image"
                        class="previewImage"
                        :src="img.path"
                        :lazy-src="img.path"
                        loading="lazy"
                      />
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-icon
                      color="error"
                      title="Delete"
                      @click="deleteItem(img.path)"
                    >
                      mdi-delete
                    </v-icon>
                  </v-card-actions>
                </v-card>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <file-pond
                name="images"
                ref="pond"
                class-name="my-pond"
                label-idle="Drop Images here Or Click here to Upload..."
                allow-multiple="true"
                max-files="3"
                accepted-file-types="image/jpeg, image/png"
                :files="images64"
                :metadata="null"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                dark
                v-model.trim="billboard.bb_id"
                type="text"
                label="Code"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                dark
                v-model.trim="billboard.rate_card"
                type="number"
                min="0"
                suffix="GH₵"
                label="Price"
                :rules="rules.genRule"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-row class="mt-2" align="center" justify="center">
                <gmap-autocomplete
                  placeholder="Location of Billboard"
                  required
                  id="address"
                  :value="billboard.address"
                  class="v-text-field v-text-field-outlined white--text"
                  @place_changed="setPlace"
                  :options="autoCompleteBounds"
                ></gmap-autocomplete>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                dark
                type="number"
                label="Longitude"
                v-model="billboard.longitude"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                dark
                type="number"
                label="Latitude"
                v-model="billboard.latitude"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                dark
                :rules="rules.selectRule"
                :items="lightingChoices"
                v-model="billboard.lighting"
                label="Lighting"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                dark
                :rules="rules.selectRule"
                :items="mediumChoices"
                v-model="billboard.medium"
                label="Medium"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                dark
                :rules="rules.selectRule"
                :items="orientationChoices"
                v-model="billboard.orientation"
                label="Orientation"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                dark
                :rules="rules.selectRule"
                :items="sideOfRoadChoices"
                v-model="billboard.side_of_road"
                label="Side of Road"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                dark
                v-model.trim="billboard.site_run_up"
                :rules="rules.site_run_upRules"
                required
                type="number"
                label="Site Run Up"
                suffix="Metres"
                placeholder="Maximum distance the Billboard is visible"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                dark
                v-model.trim="billboard.faces"
                :rules="rules.faceRules"
                type="number"
                label="Faces"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6"
              ><v-select
                dark
                :rules="rules.selectRule"
                :items="clutterChoices"
                v-model="billboard.clutter"
                label="Clutter"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-select
                dark
                :rules="rules.selectRule"
                :items="heightChoices"
                v-model="billboard.height"
                label="Height"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                dark
                v-model.trim="billboard.length"
                :rules="rules.genRule"
                label="Length"
                type="number"
                suffix="Metres"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                dark
                v-model.trim="billboard.width"
                :rules="rules.genRule"
                label="Width"
                type="number"
                suffix="Metres"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                dark
                v-model.trim="billboard.brand_name"
                label="Current Brand Name"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
      <v-btn color="blue darken-1" text @click="submitForm"> Save </v-btn>
    </v-card-actions>

    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card
        class="white--text"
        :color="$vuetify.theme.themes.dark.background"
      >
        <v-progress-linear
          color="deep-purple accent-4"
          :active="loading"
          :indeterminate="loading"
        ></v-progress-linear>

        <v-card-title class="text-h5"
          >Are you sure you want to delete this Image?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteImageConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <alert></alert>
  </v-card>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";
const { mapActions } = createNamespacedHelpers("billboards");

import { getToken, handleHttpError } from "@/helpers/utils";

import { setOptions } from "vue-filepond";
import FilePond from "../../mixins/filePond";

import { deleteImage as deleteImageFromS3 } from "@/helpers/images";

export default {
  data() {
    return {
      images64: [],
      image_id: null,

      loading: false,
      dialog: false,
      valid: false,
      rules: {
        imageRules: [(v) => !!v || "Image is required"],
        selectRule: [(v) => !!v || "Please Select an Option"],
        genRule: [(v) => !!v || "Please fill this."],
        site_run_upRules: [
          (v) => !!v || "Required",
          (v) => v >= 0 || "Distance should be above 0",
          (v) => v <= 200 || "Max should not be above 200",
        ],
        faceRules: [
          (v) => !!v || "Required",
          (v) => v >= 0 || "Faces should be above 0",
          (v) => v <= 4 || "Max should not be above 4",
        ],
      },
      heightChoices: ["Eye Level", "Moderate", "High"],
      clutterChoices: ["Solus", "Average", "Cluttered", "Solus"],
      lightingChoices: ["Frontlit", "Backlit", "Unlit", "LED"],
      mediumChoices: [
        "Wall Wrap",
        "RoadArch/Gantry",
        "Rooftops",
        "LED",
        "Unipole",
        "Medium",
        "Bus Stop",
        "Bus Stop Shelter",
      ],
      orientationChoices: ["Landscape", "Portrait", "Square"],
      sideOfRoadChoices: ["Left", "Middle", "Right"],

      deleteDialog: false,

      autoCompleteBounds: {
        bounds: {
          north: 11.234926,
          south: 4.737842,
          east: 1.201117,
          west: -3.265621,
        },
        strictBounds: true,
      },

      userToken: null,

      imagesUploadUrl: `${process.env.VUE_APP_URL}/images`,

      billboard: {
        ...this.currentBillboard,
        newImages: [],
      },
    };
  },

  props: {
    currentBillboard: {
      type: Object,
      default: () => ({
        Images: [],
        industry: null,
        site_run_up: null,
        faces: null,
        length: null,
        width: null,
        height: null,
        clutter: null,
        lighting: null,
        medium: null,
        orientation: null,
        side_of_road: null,
        address: null,
        latitude: 0,
        location: null,
        longitude: 0,
        rate_card: null,
        brand_name: null,
        direction: null,
        id: null,
        owner_id: null,
        status: true,
        bb_id: null,
        supplier: null,
        newImages: [],
      }),
    },
    operation: String,
  },

  computed: {
    ...mapGetters({
      getCurrentUser: "getCurrentUser",
    }),
  },

  components: { FilePond },

  watch: {
    deleteDialog: {
      immediate: true,
      handler(val) {
        if (val) this.deleteImageConfirm();
      },
    },
  },

  methods: {
    ...mapActions(["addBillboards", "updateBillboard", "fetchMyBbs"]),

    async fetchBBS() {
      await this.fetchMyBbs(this.getCurrentUser.userId);
    },

    deleteItem(id) {
      this.image_id = id;
      this.deleteDialog = true;
    },

    closeDelete() {
      this.deleteDialog = false;
    },

    async deleteImageConfirm() {
      await this.deleteImage();
      this.closeDelete();
      this.loading = false;
    },

    async deleteImage() {
      // send api to delete from s3
      // remove the image from the images
      const rmIdx = this.billboard.Images.findIndex(
        (img) => img.id === this.image_id
      );
      this.billboard.images.splice(rmIdx, 1);

      await deleteImageFromS3(this.image_id);
    },

    close() {
      this.$emit("close-form");
      this.dialog = false;
    },

    async submitForm() {
      if (!this.billboard.address) {
        handleHttpError(`Error Billboard does not have an Address.`);
      }
      this.$refs.form.validate();

      if (!this.valid) return;

      this.loading = true;

      if (this.operation === "edit") {
        if (!this.billboard.Images.length) {
          handleHttpError(`Error: Billboard does not have an Image.`);
          this.loading = false;
          return;
        }

        await this.updateBillboard(this.billboard);
        await this.fetchBBS();
      } else {
        if (!this.billboard.Images.length) {
          handleHttpError(`Error: Billboard does not have an Image.`);
          this.loading = false;

          return;
        }
        await this.addBillboards(this.billboard);
        await this.fetchBBS();
      }

      this.loading = false;
      this.$emit("close-form");

      // reset
      this.$refs.form.reset();
    },

    async setPlace(place) {
      if (!place) return;
      this.billboard.address = place.formatted_address;
      this.billboard.latitude = place.geometry.location.lat();
      this.billboard.longitude = place.geometry.location.lng();
    },
  },

  async mounted() {
    this.userToken = await getToken();

    setOptions({
      server: {
        url: this.imagesUploadUrl,
        process: {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.userToken}`,
          },
          timeout: 12000,
          onload: (response) => {
            // on success
            // send success message
            const imageLink = JSON.parse(response).path;
            // add the url to list of billboard images
            // before check if it exists in the list already
            const exists = this.billboard.Images.some(
              (img) => img.link === imageLink
            );
            if (this.operation == "edit") {
              if (!exists) {
                this.billboard.newImages.push({
                  link: imageLink,
                });
              }
            } else {
              this.billboard.Images.push({
                link: imageLink,
              });
            }
          },
          ondata: (formData) => {
            formData.append("images", this.images64);
            return formData;
          },
          onerror: (response) => {
            const message = response.error
              ? `Error Uploading Image: ${response.error}`
              : "An error was encountered trying to upload image.";
            return handleHttpError(message);
          },
        },
      },
    });
  },
};
</script>

<style>
#create {
  background-color: #66bb6a;
  color: white;
  padding: 7px;
}

#edit {
  color: #66bb6a;
}
.previewImage {
  height: 150px;
  cursor: pointer;
}
.previewImage:hover {
  transform: scale(1.05);
  transition: all 0.2s;
}
#address {
  width: 96%;
  padding: 0 5px 0 10px;
  min-height: 3.2em;
  border-bottom: 1px solid grey;
}

.filepond--wrapper {
  width: 40%;
  margin: 0 auto;
}
.filepond--credits {
  display: none;
}
.filepond--list {
  margin: 10px !important;
}
.filepond--drop-label {
  background-color: var(--v-secondary-lighten3);
  border-radius: 20px;
  color: black;
}
</style>
