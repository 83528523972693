<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col id="driver" cols="12" lg="2" xl="2" sm="2" md="2">
        <v-row>
          <v-card
            width="100%"
            elevation="0"
            :color="$vuetify.theme.themes.dark.background"
            class="white--text"
          >
            <v-btn
              color="expansionPanels"
              elevation="0"
              v-for="btn in buttons"
              :key="btn.name"
              @click="setComponent(btn.cmp)"
              :class="{ active: btn.cmp === currentComponent }"
            >
              <v-icon color="black">{{ btn.icon }}</v-icon>
              {{ btn.name }}
            </v-btn>
          </v-card>
        </v-row>

        <v-row
          class="mt-10 briefDetails--card"
          v-if="currentComponent === 'CreateCampaign'"
        >
          <v-col>
            <v-card class="p-0" :color="$vuetify.theme.themes.dark.background">
              <v-toolbar
                :color="$vuetify.theme.themes.dark.background"
                flat
                class="white--text mb-2"
              >
                <v-spacer></v-spacer>

                <v-btn icon @click="expandBriefDetails = true">
                  <v-icon color="white">mdi-arrow-expand</v-icon>
                </v-btn>
              </v-toolbar>

              <brief-details
                :currentBrief="{ ...getBriefsCart }"
              ></brief-details>
            </v-card>
            <v-row class="my-4">
              <v-spacer></v-spacer>
              <v-btn width="80" height="30" color="accent" @click="clearCart"
                >Clear
                <v-icon color="white">mdi-delete </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="10" lg="10" xl="10" md="10" class="pa-1">
        <keep-alive>
          <component :is="currentComponent" :buttons="helpButtons"></component>
        </keep-alive>
      </v-col>

      <v-dialog v-model="expandBriefDetails" width="40%">
        <v-card width="100%" elevation="0">
          <v-toolbar
            :color="$vuetify.theme.themes.dark.background"
            flat
            class="white--text mb-2"
            elevation="0"
          >
            <v-spacer></v-spacer>

            <v-btn icon @click="expandBriefDetails = false">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <div>
            <brief-details :currentBrief="{ ...getBriefsCart }"></brief-details>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import CreateCampaign from "@/components/campaign/CreateCampaign.vue";

import CampaignList from "@/components/campaign/CampaignList.vue";
import BriefDetails from "@/components/briefs/BriefDetails.vue";
import DocumentationHelper from "../../components/DocumentationHelper";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("briefs");

export default {
  data() {
    return {
      expandBriefDetails: false,
      currentComponent: "CampaignList",
      buttons: [
        {
          name: "Create Campaign",
          icon: "mdi-compass-outline",
          cmp: "CreateCampaign",
        },
        { name: "View Campaigns", icon: "mdi-home", cmp: "CampaignList" },
        {
          name: "Help/Support",
          icon: "mdi-desktop-mac",
          cmp: "DocumentationHelper",
        },
      ],

      helpButtons: [
        {
          name: "Create Campaign Help",
          cmp: "/abonten_manual/campaigns/create/create_campaign.md",
        },
        {
          name: "View Campaign Help",
          cmp: "/abonten_manual/campaigns/view/view_campaign.md",
        },
      ],
    };
  },
  components: {
    CreateCampaign,
    CampaignList,
    BriefDetails,
    DocumentationHelper,
  },

  computed: { ...mapGetters(["getBriefsCart"]) },

  methods: {
    ...mapActions(["removeBriefFromCart"]),

    setComponent(cmp) {
      this.currentComponent = cmp;
    },
    async clearCart() {
      await this.removeBriefFromCart();
    },
  },
};
</script>

<style scoped>
#driver {
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.v-btn {
  margin: 10px;
  height: 48px !important;
  width: 90%;
  text-transform: none;
}

.active {
  background: #f68625 !important;
}

.briefDetails--card {
  max-width: 100%;
  font-family: Manrope, Arial, sans-serif;
  box-shadow: 10px 10px 8px -6px rgba(225, 218, 218, 0.919) !important;
  -webkit-box-shadow: 10px 10px 8px -6px rgba(248, 243, 243, 0.931) !important;
  -moz-box-shadow: 10px 10px 8px -6px rgba(247, 238, 238, 0.939) !important;
  border-right: 1px solid white !important;
  border-top: 0.4px solid rgb(113, 111, 111) !important;
  border-left: 0.4px solid rgb(113, 111, 111) !important;
  border-bottom: 1px solid white !important;
}
</style>
