<template>
  <v-card
    width="100%"
    min-height="650"
    id="bus"
    elevation="0"
    :color="$vuetify.theme.themes.dark.background"
    class="py-5 px-2 mx-auto white--text"
  >
    <v-toolbar
      :color="$vuetify.theme.themes.dark.background"
      flat
      class="white--text mb-2"
    >
      <v-toolbar-title>Current Campaigns</v-toolbar-title>
    </v-toolbar>

    <v-progress-linear
      color="deep-purple accent-4"
      :active="initLoading"
      :indeterminate="initLoading"
    ></v-progress-linear>

    <v-data-table
      :items="getCampaigns"
      :headers="headers"
      :search="search"
      sort-by="plan_name"
      class="elevation-1 pa-3"
      no-data-text="You have no running campaigns yet."
      loading-text="Fetching Campaigns... Please wait."
      :style="{
        'background-color': $vuetify.theme.themes.dark.background,
        color: $vuetify.theme.themes.dark.text,
      }"
      dark
    >
      <template slot="top">
        <v-toolbar color="grey darken-3" flat>
          <v-btn class="white--text" @click="fetchCampaigns">
            Refresh
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-responsive max-width="40%" class="mx-auto mb-2">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-responsive>
        </v-toolbar>
      </template>

      <template v-slot:[`item.brand`]="{ item }">
        <span class="ha-3 pa-3 rounded-sm secondary--text">
          {{ getBrand(item) }}
        </span>
      </template>

      <template v-slot:[`item.organization`]="{ item }">
        {{ getOrg(item.Planner) }}
      </template>

      <template v-slot:[`item.planner`]="{ item }">
        {{ getPlanner(item.Planner) }}
      </template>

      <template v-slot:[`item.budget`]="{ item }">
        <span id="formatBudget">
          {{ parseNumber(item.Brief?.budget) }}
        </span>
      </template>

      <template v-slot:[`item.duration`]="{ item }">
        {{ item.Brief?.duration || "Not Available" }}
      </template>

      <template v-slot:[`item.sites`]="{ item }">
        {{ item.Candidates.length }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="copyToClipBoard(item.token)">
          <v-icon color="secondary" title="Copy Link">mdi-content-copy</v-icon>
        </v-btn>

        <v-btn
          v-if="!viewOnMonitoring"
          icon
          @click.stop="selectCampaign(item.id), (showCampaignDialog = true)"
        >
          <v-icon color="secondary" title="More Information"> mdi-eye </v-icon>
        </v-btn>

        <v-btn icon v-if="!viewOnMonitoring">
          <v-icon
            title="Edit"
            color="success"
            @click.stop="editPlan(item.id), show($event)"
            >mdi-pencil</v-icon
          >
        </v-btn>

        <v-btn icon v-if="!viewOnMonitoring" @click.stop="deleteItem(item)">
          <v-icon title="Delete" color="error">mdi-delete</v-icon>
        </v-btn>

        <v-btn
          id="selectCurrentCampaign"
          small
          elevation="0"
          v-if="viewOnMonitoring"
          @click="selectCampaign(item.id)"
        >
          Select Campaign
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="showCampaignDialog" width="90%">
      <v-toolbar :color="$vuetify.theme.themes.dark.background">
        <v-spacer></v-spacer>

        <v-btn icon @click="showCampaignDialog = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <campaign-item></campaign-item>
    </v-dialog>

    <v-menu
      close-on-click
      origin="center center"
      transition="scale-transition"
      v-model="showEditMenu"
      offset-y
      :position-x="menuX"
      :position-y="menuY"
      dark
    >
      <v-card>
        <v-card-subtitle>Choose an Option</v-card-subtitle>
        <v-card-text>
          <v-btn
            color="warning lighten-3"
            elevation="0"
            small
            class="ma-2"
            v-if="currentCampaign.approval"
            @click="(showEditCampaignTableDialog = true), (mode = 'table')"
            >Edit Negotiated Rate
          </v-btn>
          <v-btn
            color="success lighten-2"
            elevation="0"
            small
            class="ma-2"
            @click="(showEditCampaignDialog = true), (mode = 'map')"
            >Edit On Map
          </v-btn>
          <!-- <v-btn
            elevation="0"
            color="success lighten-2"
            small
            class="ma-2"
            @click="(showEditCampaignDialog = true), (mode = 'list')"
            >Edit On Table
          </v-btn> -->
        </v-card-text>
      </v-card>
    </v-menu>

    <v-dialog v-model="showEditCampaignDialog" width="98%">
      <v-toolbar :color="$vuetify.theme.themes.dark.background">
        <v-spacer></v-spacer>

        <v-btn icon @click="showEditCampaignDialog = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <edit-campaign>
        <v-toolbar-title slot="title">Edit Campaign</v-toolbar-title>
      </edit-campaign>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="390px">
      <v-card>
        <v-progress-linear
          color="deep-purple accent-4"
          :active="loading"
          :indeterminate="loading"
        ></v-progress-linear>

        <v-card-title class="text-h5"
          >Are you sure you want to delete this Campaign?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteCampaignConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showEditCampaignTableDialog">
      <v-toolbar :color="$vuetify.theme.themes.dark.background">
        <v-toolbar-title class="white--text">Edit Campaign</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon @click="showEditCampaignTableDialog = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <campaignitem-billboards
        :currentCampaign="currentCampaign"
        :viewOnCampaignEdit="true"
      ></campaignitem-billboards>
    </v-dialog>

    <v-snackbar v-model="showToast" color="success" timeout="2000" bottom>{{
      toastMessage
    }}</v-snackbar>
    <alert></alert>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import CampaignItem from "./CampaignItem.vue";
import CampaignitemBillboards from "./CampaignitemBillboards.vue";
import EditCampaign from "./edit/EditCampaign.vue";

export default {
  components: { CampaignItem, EditCampaign, CampaignitemBillboards },

  data() {
    return {
      search: "",
      loading: false,
      initLoading: false,
      toastMessage: "Link copied to clipboard.",
      showToast: false,

      currentCampaign: {
        id: null,
        approval: null,
      },
      currentCampaignID: null,
      showCampaignDialog: false,
      showEditCampaignDialog: false,
      deleteDialog: false,

      headers: [
        { text: "Brand", value: "brand" },
        {
          text: "Company Name",
          align: "start",
          value: "organization",
          sortable: false,
        },
        { text: "Campaign Name", value: "name" },
        { text: "Campaign By", value: "planner" },
        { text: "Duration", value: "duration", sortable: false },
        { text: "Budget(GH₵)", value: "budget", sortable: false },
        { text: "Sites", value: "sites" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      showEditCampaignTableDialog: false,
      showEditMenu: false,
      mode: null,
      menuX: 0,
      menuY: 0,
    };
  },
  computed: {
    ...mapGetters({
      getCampaigns: "campaigns/getCampaigns",
      getCurrentUser: "getCurrentUser",
    }),
  },

  props: { viewOnMonitoring: Boolean },

  emits: ["select-campaign"],

  methods: {
    ...mapMutations({ setCurrentCampaign: "campaigns/setCurrentCampaign" }),

    ...mapActions({
      fetchUserCampaigns: "campaigns/fetchUserCampaigns",
      fetchAllCampaigns: "campaigns/fetchAllCampaigns",
      fetchCampaign: "campaigns/fetchCampaign",
      deleteCampaign: "campaigns/deleteCampaign",
    }),

    getBrand(item) {
      return item?.Brief?.BrandOwner?.Brand?.name || "N/A";
    },

    getOrg(planner) {
      return planner.User.organization;
    },
    getPlanner(planner) {
      return planner.User.plannerName;
    },
    parseNumber(amount) {
      if (amount) {
        return new Intl.NumberFormat().format(amount);
      } else {
        return 0;
      }
    },
    copyToClipBoard(campaignToken) {
      let link = `https://app.abonten.com/proposed-campaign/${campaignToken}`;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.showToast = true;
        })
        .catch(() => {
          console.error("Unable to copy link.");
        });
    },

    async editPlan(campaignId) {
      // set current cmp
      this.currentCampaign = await this.fetchCampaign(campaignId);
      this.setCurrentCampaign(this.currentCampaign);
    },

    async selectCampaign(campaignId) {
      this.currentCampaign = await this.fetchCampaign(campaignId);

      this.setCurrentCampaign(this.currentCampaign);

      this.$emit("select-campaign");
    },
    async fetchCampaigns() {
      this.initLoading = true;
      if (this.viewOnMonitoring) {
        //
        await this.fetchAllCampaigns();
      } else {
        await this.fetchUserCampaigns(this.getCurrentUser.userId);
      }
      this.initLoading = false;
    },

    show(event) {
      this.showEditMenu = false;

      this.menuX = event.clientX;
      this.menuY = event.clientY;

      this.$nextTick(() => (this.showEditMenu = true));
    },

    deleteItem(campaign) {
      this.currentCampaign = campaign;
      this.deleteDialog = true;
      this.loading = true;
    },

    closeDelete() {
      this.deleteDialog = false;
    },

    async deleteCampaignConfirm() {
      await this.deleteCampaign(this.currentCampaign.id);
      this.closeDelete();
      await this.fetchCampaigns();
      this.loading = false;
    },
  },

  async mounted() {
    await this.fetchCampaigns();
  },
};
</script>

<style scoped>
.v-card {
  font-family: Manrope, Arial, sans-serif;
  box-shadow: 10px 10px 8px -6px rgba(225, 218, 218, 0.919) !important;
  -webkit-box-shadow: 10px 10px 8px -6px rgba(248, 243, 243, 0.931) !important;
  -moz-box-shadow: 10px 10px 8px -6px rgba(247, 238, 238, 0.939) !important;
  border-right: 1px solid white !important;
  border-top: 0.4px solid rgb(113, 111, 111) !important;
  border-left: 0.4px solid rgb(113, 111, 111) !important;
  border-bottom: 1px solid white !important;
}
.v-toolbar {
  font-size: 24px;
  margin: 0 !important;
}
.v-data-table-header {
  background: #f9f9fa;
}
#formatBudget {
  background: rgba(32, 201, 172, 0.1);
  height: 48px !important;
  color: #20c9ac;
  padding: 5px 14px;
}
.v-data-table__wrapper tr {
  min-height: 70px !important;
}

#selectCurrentCampaign {
  text-transform: none;
  background: rgba(0, 165, 255, 0.1);
  border-radius: 4px;
  padding: 15px 5px;

  color: #00a5ff;
  height: 24px;
}
.v-btn {
  margin: 15px 0;
  text-transform: none;
}
</style>
