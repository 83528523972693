<template>
  <v-app
    :style="{
      'background-color': $vuetify.theme.themes.dark.background,
      text: $vuetify.theme.themes.dark.text,
    }"
  >
    <v-main>
      <v-fade-transition mode="in-out">
        <router-view></router-view>
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("auth");

export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    ...mapActions(["tryLogin"]),
  },
  async created() {
    await this.tryLogin();
  },
  mounted() {
    this.show = true;
    this.$Tawk.$createChat();
  },
};
</script>

<style scoped>
.source-sans-pro {
  font-family: "Source Sans Pro", sans-serif;
}
</style>
