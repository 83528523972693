<template>
  <div>
    <v-container fluid fill-height class="pa-6">
      <v-toolbar
        :color="$vuetify.theme.themes.dark.background"
        class="white--text b-8"
        flat
      >
        <v-toolbar-title> Brief Form</v-toolbar-title>
      </v-toolbar>

      <v-progress-linear
        color="deep-purple accent-4"
        :active="loading"
        :indeterminate="loading"
      ></v-progress-linear>

      <v-row align="center" justify="center">
        <v-col id="driver" cols="5" sm="5" md="5">
          <v-card
            elevation="0"
            :color="$vuetify.theme.themes.dark.background"
            class="py-5 px-2 mx-auto white--text"
          >
            <v-form ref="form" v-model="valid">
              <v-expansion-panels dark>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    expand-icon="mdi-menu-right"
                    id="v-step-2"
                  >
                    <v-icon color="white">mdi-office-building-outline </v-icon>
                    Brand
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-select
                      v-model.trim="currentBrief.BrandOwner.Brand.name"
                      label="Select a Brand"
                      :items="getUserBrands"
                      required
                      item-value="name"
                      item-text="name"
                      :rules="rules.fieldRules"
                    ></v-select>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="canViewPreferredPlanner">
                  <v-expansion-panel-header
                    expand-icon="mdi-menu-right"
                    id="v-step-2"
                  >
                    <v-icon>mdi-target-account</v-icon>
                    Preferred Planner
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card
                      max-height="200"
                      elevation="0"
                      class="overflow-auto"
                    >
                      <v-radio-group v-model="targetPlanner">
                        <v-radio
                          v-for="planner in abontenPlanners"
                          :key="planner.plannerId"
                          :label="getPlannerName(planner)"
                          :value="planner.plannerId"
                        ></v-radio>
                      </v-radio-group>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header expand-icon="mdi-menu-right">
                    <v-icon color="white">mdi-cash-check</v-icon>
                    Budget Allocation
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-text-field
                      v-model.trim="currentBrief.budget"
                      :rules="rules.fieldRules"
                      min="0"
                      type="number"
                      suffix="GH₵"
                      required
                      filled
                      label="Input Budget"
                    >
                    </v-text-field>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header expand-icon="mdi-menu-right">
                    <v-icon color="white">mdi-map-marker-outline</v-icon>
                    Region of Focus
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-combobox
                      :items="getRegions"
                      v-model="currentBrief.region"
                      name="region"
                      item-color="secondary"
                      :rules="rules.selectRules"
                      multiple
                      chips
                      label="Region"
                    ></v-combobox>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header expand-icon="mdi-menu-right">
                    <v-icon color="white">mdi-compass-outline</v-icon>
                    Campaign Type
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row align="center">
                      <v-select
                        v-model="currentBrief.campaignType"
                        label="Campaign"
                        :items="campaigns"
                        :rules="rules.fieldRules"
                      ></v-select>
                      <v-btn
                        class="ml-2"
                        icon
                        color="blue"
                        @click="showCampaignsDialog = true"
                      >
                        <v-icon title="More information"
                          >mdi-progress-question
                        </v-icon>
                      </v-btn>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header expand-icon="mdi-menu-right">
                    <v-icon color="white">mdi-account-circle-outline</v-icon>
                    Social Economic Class (SEC)
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-combobox
                      :items="secs"
                      v-model="currentBrief.sec"
                      name="sec"
                      item-color="secondary"
                      multiple
                      label="SEC"
                      chips
                      :rules="rules.selectRules"
                    ></v-combobox>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header expand-icon="mdi-menu-right">
                    <v-icon color="white">mdi-desktop-mac</v-icon>
                    Target
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-combobox
                          :items="ages"
                          v-model="currentBrief.ageGroup"
                          name="ages"
                          label="Age Group"
                          item-color="secondary"
                          multiple
                          :rules="rules.selectRules"
                          small-chips
                        ></v-combobox>
                      </v-row>
                      <v-row align="center" justify="center">
                        <v-combobox
                          :items="genders"
                          v-model="currentBrief.gender"
                          name="genders"
                          item-color="secondary"
                          multiple
                          label="Gender"
                          :rules="rules.selectRules"
                          small-chips
                        ></v-combobox>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header expand-icon="mdi-menu-right">
                    <v-icon color="white">mdi-calendar-month-outline</v-icon>
                    Campaign Duration
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-menu
                          v-model="startMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="currentBriefStartDate"
                              label="Start date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :rules="rules.fieldRules"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="currentBriefStartDate"
                            @change="startMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-row>

                      <v-row align="center" justify="center">
                        <v-menu
                          v-model="endMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="currentBriefEndDate"
                              label="End date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              :rules="rules.fieldRules"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="currentBriefEndDate"
                            @change="endMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header expand-icon="mdi-menu-right">
                    <v-icon color="white">mdi-notebook-edit</v-icon>
                    More Info.
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-textarea
                      filled
                      color="secondary"
                      name="input-7-4"
                      label="Any request not captured ?"
                      v-model="currentBrief.description"
                    ></v-textarea>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-form>
          </v-card>
        </v-col>

        <v-divider></v-divider>

        <v-col cols="7" sm="7" md="7">
          <brief-details :currentBrief="currentBrief"></brief-details>
        </v-col>
      </v-row>

      <v-row>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="submitBrief"> Save </v-btn>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>

    <v-dialog max-width="450" v-model="showCampaignsDialog">
      <v-card class="pa-5">
        <v-card-text>
          <p>
            <span class="text-h6">Launch Campaign</span> - This is for new brand
            that want to create awareness or a dormant brand that want to
            revive. The focus for such campaign is to drive awareness and
            therefore the plan is to use frequency inventories to drive this
            campaign.
          </p>
          <p>
            <span class="text-h6">Sustenance Campaign </span> - This is for
            existing brand that have a particular promotion they want to push at
            a particular time of the year. This campaign is normally for an
            already established brands and such campaigns are meant to solve a
            specific problem of the brand or push sales and are therefore very
            focused. Outdoor selection for such campaign are very tactical since
            we have a more focused reason for this campaign.
          </p>
          <p>
            <span class="text-h6">Disruptive Campaign</span> - Disruptive
            campaign are mainly to counter competition at a particular point of
            the year. Focus for such campaign are skewed towards how to outdo
            what competition is doing at the same or about to do.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="showCampaignsDialog = false" color="error"
            >Close</v-btn
          >
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <alert></alert>
  </div>
</template>

<script>
import BriefDetails from "./BriefDetails.vue";

import { mapActions, mapGetters } from "vuex";
import { fetchBrandCategories } from "../../helpers/brands";

import { fetchAbontenPlanners } from "../../helpers/planners";

export default {
  data() {
    return {
      startMenu: false,
      endMenu: false,
      nowDate: new Date().toISOString().slice(0, 10),

      rules: {
        fieldRules: [(v) => !!v || "Field is required. Please Fill."],
        selectRules: [
          (v) =>
            v.length > 0 ||
            "Item is required. Please Select at least one option",
        ],
      },

      valid: false,
      loading: false,
      showCampaignsDialog: false,

      brandCategories: [],
      abontenPlanners: [],
    };
  },

  components: { BriefDetails },

  props: {
    currentBrief: {
      type: Object,
      default: () => ({
        id: null,
        BrandOwner: {
          Marketer: {
            client: null,
          },
          Planner: {
            client: null,
          },
          Brand: {
            name: null,
            BrandCategory: {
              id: null,
            },
          },
        },
        budget: 0,
        region: [],
        campaignType: null,
        sec: [],
        ageGroup: [],
        gender: [],
        start_date: new Date().toISOString().slice(0, 10),
        end_date: new Date().toISOString().slice(0, 10),
        description: null,
        TargetBriefs: [],
      }),
    },
    operation: String,
  },

  computed: {
    ...mapGetters({
      getCurrentUser: "getCurrentUser",
      getRegions: "regions/getRegions",
      getUserDetails: "user/getUserDetails",
      getUserBrands: "brands/getUserBrands",
    }),

    currentBriefStartDate: {
      get() {
        return new Date(this.currentBrief.start_date)
          .toISOString()
          .slice(0, 10);
      },
      set(value) {
        if (!value) new Date().toISOString().slice(0, 10);
        this.currentBrief.start_date = value;
      },
    },

    currentBriefEndDate: {
      get() {
        return new Date(this.currentBrief.end_date).toISOString().slice(0, 10);
      },
      set(value) {
        this.currentBrief.end_date = value;
      },
    },

    targetPlanner: {
      get: function () {
        return this.currentBrief?.TargetBriefs?.[0]?.plannerId;
      },
      set: function (value) {
        this.currentBrief.TargetBriefs = [];
        this.currentBrief.TargetBriefs.push({ plannerId: value });
      },
    },

    campaigns() {
      return ["Tactical Campaign", "Sustenance Campaign", "Launch Campaign"];
    },

    // TODO: get sec from db and map
    secs() {
      return ["A", "B", "C", "D", "E"];
    },

    ages() {
      return [
        "0-4",
        "5-9",
        "10-14",
        "15-19",
        "20-24",
        "25-29",
        "30-34",
        "35-39",
        "40-44",
        "45-49",
        "50-54",
        "55-59",
        "60-64",
        "65-69",
        "70-74",
        "75-79",
        "80+",
      ];
    },

    genders() {
      return ["Male", "Female"];
    },

    canViewPreferredPlanner() {
      return this.getCurrentUser.userType === "marketer";
    },
  },

  methods: {
    ...mapActions({
      addBrief: "briefs/addBrief",
      updateBrief: "briefs/updateBrief",
      fetchBriefs: "briefs/fetchBriefs",
      fetchAllRegions: "regions/fetchAllRegions",
      fetchUserBrands: "brands/fetchUserBrands",
    }),

    async submitBrief() {
      this.$refs.form.validate();

      if (!this.valid) return;

      this.loading = true;

      //
      this.currentBrief.email = this.getCurrentUser.email;

      if (this.operation === "update") {
        await this.updateBrief(this.currentBrief);
      } else {
        await this.addBrief(this.currentBrief);
      }
      this.$refs.form.reset();
      await this.fetchBriefs(this.getCurrentUser.userId);
      this.loading = false;
    },

    getPlannerName(planner) {
      return `${this.capitalizeEachWord(
        planner?.first_name
      )} ${this.capitalizeEachWord(
        planner?.last_name
      )} (${this.capitalizeEachWord(planner?.organization)})`;
    },

    capitalizeEachWord(str) {
      return str.replace(/\b\w/g, function (match) {
        return match.toUpperCase();
      });
    },
  },

  async mounted() {
    await this.fetchAllRegions();
    (this.brandCategories = await fetchBrandCategories()),
      (this.abontenPlanners = await fetchAbontenPlanners());
    await this.fetchUserBrands(this.getCurrentUser.userId);
  },
};
</script>

<style scoped>
.container {
  background: #424242;
  box-shadow: 10px 10px 8px -6px rgba(225, 218, 218, 0.919) !important;
  -webkit-box-shadow: 10px 10px 8px -6px rgba(248, 243, 243, 0.931) !important;
  -moz-box-shadow: 10px 10px 8px -6px rgba(247, 238, 238, 0.939) !important;
  border-right: 1px solid white !important;
  border-top: 0.4px solid rgb(113, 111, 111) !important;
  border-left: 0.4px solid rgb(113, 111, 111) !important;
  border-bottom: 1px solid white !important;
}
#driver {
  font-family: DM Sans, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
}

.v-toolbar {
  font-size: 24px;
  margin: 0 !important;
}

.v-expansion-panel {
  margin-top: 10px;
}
.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none !important;
}
.v-expansion-panel-header > .v-icon {
  margin-right: auto;
}

.v-expansion-panel-content {
  padding: 16px !important;
}
</style>
