import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Tawk from "vue-tawk";

import { abilitiesPlugin } from "@casl/vue";
import abilities_required from "./router/guards/abilities_required.js";

Vue.use(abilitiesPlugin, abilities_required(), {
  useGlobalProperties: true,
});

import * as GmapVue from "gmap-vue";

import JsonExcel from "vue-json-excel";

import Alert from "@/components/notifications/Alert.vue";

Vue.config.productionTip = false;

Vue.component("alert", Alert);

Vue.use(VueViewer);

Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: "places, visualization",
  },
  installComponents: true,
});

Vue.component("downloadExcel", JsonExcel);

Vue.use(Tawk, {
  tawkSrc: process.env.VUE_APP_TAWK_SRC,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
