<template>
  <v-card
    width="100%"
    min-height="650"
    class="py-5 px-2 mx-auto white--text"
    :color="$vuetify.theme.themes.dark.background"
  >
    <v-toolbar
      :color="$vuetify.theme.themes.dark.background"
      flat
      class="white--text mb-2"
    >
      <v-toolbar-title>Owner Campaigns</v-toolbar-title>
    </v-toolbar>
    <v-progress-linear
      color="deep-purple accent-4"
      :active="loading"
      :indeterminate="loading"
    >
    </v-progress-linear>

    <v-data-table
      :items="getCampaigns"
      :headers="campaignHeaders"
      :search="search"
      sort-by="plan_name"
      class="elevation-1 pa-3"
      no-data-text="Your Billboards have no running campaigns yet."
      loading-text="Fetching Campaigns... Please wait."
      :style="{
        'background-color': $vuetify.theme.themes.dark.background,
        color: $vuetify.theme.themes.dark.text,
      }"
      dark
    >
      <template slot="top">
        <v-toolbar :color="$vuetify.theme.themes.dark.background" flat>
          <v-btn @click="fetchCampaigns">
            Refresh
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-responsive max-width="40%" class="mx-auto mb-2">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-responsive>
        </v-toolbar>
      </template>

      <template v-slot:[`item.campaignNumber`]="{ _, index }">
        {{ _ }} {{ index + 1 }}
      </template>

      <template v-slot:[`item.organization`]="{ item }">
        {{ getOrg(item.Planner) }}
      </template>

      <template v-slot:[`item.planner`]="{ item }">
        {{ getPlanner(item.Planner) }}
      </template>

      <template v-slot:[`item.start_date`]="{ item }">
        {{ parseDate(item.Brief?.start_date) }}
      </template>

      <template v-slot:[`item.end_date`]="{ item }">
        {{ parseDate(item.Brief?.end_date) }}
      </template>

      <template v-slot:[`item.duration`]="{ item }">
        {{ item.Brief?.duration }}
      </template>

      <template v-slot:[`item.sites`]="{ item }">
        {{ item.Candidates?.length }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          @click="updatePlan(item.Candidates)"
          color="light-green darken-1"
          depressed
          elevation="2"
          outlined
          text
          >Update Campaign</v-btn
        >
      </template>
    </v-data-table>

    <v-dialog v-model="showCandidatesPanel" width="90%">
      <v-toolbar
        elevation="1"
        :color="$vuetify.theme.themes.dark.background"
        class="white--text"
        flat
      >
        <v-toolbar-title>Billboards in Campaign</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon @click="showCandidatesPanel = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-data-table
        :items="currentCandidates"
        :headers="billboardHeaders"
        :search="bbSearch"
        sort-by="plan_name"
        class="elevation-1 pa-3"
        no-data-text="Your Billboards have no running campaigns yet."
        loading-text="Fetching Campaigns... Please wait."
        :style="{
          'background-color': $vuetify.theme.themes.dark.background,
          color: $vuetify.theme.themes.dark.text,
        }"
        dark
      >
        <template slot="top">
          <v-toolbar
            elevation="1"
            :color="$vuetify.theme.themes.dark.background"
            class="white--text"
            flat
          >
            <v-spacer></v-spacer>

            <v-responsive max-width="40%" class="mx-auto mb-2">
              <v-text-field
                v-model="bbSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-responsive>
          </v-toolbar>
        </template>
        <template v-slot:[`item.bbNumber`]="{ _, index }">
          {{ _ }} {{ index + 1 }}
        </template>

        <template v-slot:[`item.images`]="{ item }">
          <div class="p-2 images">
            <div v-viewer>
              <img
                class="image"
                v-for="img in item.Billboard.Images"
                :key="img.id"
                :src="img.path"
                alt="Billboard Image"
                :lazy-src="img.path"
                loading="lazy"
              />
            </div>
          </div>
        </template>

        <template v-slot:[`item.rate_card`]="{ item }">
          <span id="formatBudget">
            {{ parseNumber(item.Billboard.rate_card) }}
          </span>
        </template>

        <template v-slot:[`item.address`]="{ item }">
          {{ item.Billboard?.address }}
        </template>

        <template v-slot:[`item.length`]="{ item }">
          {{ item.Billboard?.length }}
        </template>

        <template v-slot:[`item.width`]="{ item }">
          {{ item.Billboard?.width }}
        </template>

        <template v-slot:[`item.height`]="{ item }">
          {{ item.Billboard?.height }}
        </template>

        <template v-slot:[`item.faces`]="{ item }">
          {{ item.Billboard?.faces }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            @click="uploadImage(item)"
            color="light-green darken-1"
            elevation="2"
            outlined
            >Upload Image</v-btn
          >
        </template>
      </v-data-table>
    </v-dialog>

    <v-dialog v-model="showUploadImagePanel" width="90%" height="50rem">
      <v-toolbar
        elevation="1"
        :color="$vuetify.theme.themes.dark.background"
        flat
        class="white--text"
      >
        <v-toolbar-title>Upload Billboard Image</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon @click="showUploadImagePanel = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card
        min-height="550"
        :color="$vuetify.theme.themes.dark.background"
        class="white--text"
      >
        <v-row class="pt-8" align="center" justify="center">
          <file-pond
            name="images"
            ref="pond"
            class-name="my-pond"
            label-idle="Drop Images here Or Click here to Upload..."
            allow-multiple="true"
            max-files="3"
            accepted-file-types="image/jpeg, image/png"
            v-bind:files="images64"
        /></v-row>

        <v-card
          elevation="0"
          class="mx-auto my-12 white--text"
          :color="$vuetify.theme.themes.dark.background"
        >
          <v-row>
            <v-col
              v-for="monit in getMonitorings.Monitorings"
              :key="monit.id"
              class="d-flex child-flex"
              cols="4"
            >
              <v-row class="flex-column" align="center">
                <span class="white--text">
                  Monitored On:
                  <span class="cyan--text text--darken-2"
                    >{{ parseDate(monit.date) }}
                  </span>
                </span>
                <v-img
                  width="150px"
                  :src="`${monit.Image.path}`"
                  :lazy-src="`${monit.Image.path}`"
                  class="grey pa-5 lighten-2 ma-5"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <v-icon color="error" title="Delete" @click="deleteItem(monit)"
                  >mdi-delete</v-icon
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteConfirmationDialog" width="500px">
      <v-card
        class="py-5 px-2 mx-auto white--text"
        :color="$vuetify.theme.themes.dark.background"
      >
        <v-progress-linear
          color="deep-purple accent-4"
          :active="loading"
          :indeterminate="loading"
        ></v-progress-linear>

        <v-card-title class="text-h5"
          >Are you sure you want to delete this Monitoring?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteConfirmationDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteMonitoringConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <alert></alert>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { getToken, handleHttpError, reportSuccess } from "@/helpers/utils";

import { setOptions } from "vue-filepond";

import FilePond from "../../mixins/filePond";

export default {
  data() {
    return {
      loading: false,
      imagesLoading: false,
      search: "",
      showCandidatesPanel: false,
      currentCandidates: [],
      currentCandidate: {},
      monitoringImages: [],

      campaignHeaders: [
        { text: "#", align: "start", sortable: false, value: "campaignNumber" },
        {
          text: "Company Name",
          align: "start",
          value: "organization",
          sortable: false,
        },
        { text: "Campaign Name", value: "name" },
        { text: "Campaign By", value: "planner" },
        { text: "Starts On", value: "start_date" },
        { text: "Ends On", value: "end_date" },
        { text: "Duration (Days)", value: "duration", sortable: false },
        { text: "Sites", value: "sites" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      billboardHeaders: [
        { text: "#", align: "start", sortable: false, value: "bbNumber" },
        { text: "Image", sortable: false, value: "images" },
        { text: "Price(GH₵)", value: "rate_card" },
        { text: "Address", value: "address" },
        { text: "Length (Metres)", value: "length" },
        { text: "Width (Metres)", value: "width" },
        { text: "Height", value: "height" },
        { text: "Faces", value: "faces" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      bbSearch: "",
      showUploadImagePanel: false,
      userToken: null,

      imagesUploadUrl: `${process.env.VUE_APP_URL}/images`,
      images64: [],
      newImageLink: null,

      deleteConfirmationDialog: false,
      currentMonitoringId: null,
    };
  },
  components: { FilePond },

  computed: {
    ...mapGetters({
      getCampaigns: "campaigns/getCampaigns",
      getCurrentUser: "getCurrentUser",
      getMonitorings: "monitoring/getMonitorings",
    }),
  },
  methods: {
    ...mapActions({
      fetchUserCampaigns: "campaigns/fetchUserCampaigns",
      monitorByOwner: "monitoring/monitorByOwner",
      fetchMonitoringByCandidate: "monitoring/fetchMonitoringByCandidate",
      deleteMonitoring: "monitoring/deleteMonitoring",
    }),

    async fetchCampaigns() {
      await this.fetchUserCampaigns(this.getCurrentUser.userId);
    },

    parseDate(date) {
      return new Date(date).toDateString();
    },

    getOrg(planner) {
      return planner.User.organization;
    },
    getPlanner(planner) {
      return planner.User.plannerName;
    },

    parseNumber(amount) {
      if (amount) {
        return new Intl.NumberFormat().format(amount);
      } else {
        return 0;
      }
    },

    updatePlan(candidates) {
      this.showCandidatesPanel = true;
      this.currentCandidates = candidates;
    },

    async uploadImage(candidate) {
      this.showUploadImagePanel = true;
      this.currentCandidate = candidate;

      // fetch monitoring images
      await this.fetchMonitoringByCandidate(this.currentCandidate.id);
    },
    async uploadMonitoring() {
      const payload = {
        candidateId: this.currentCandidate.id,
        images: [
          {
            link: this.newImageLink,
          },
        ],
        date: new Date(),
      };

      await this.monitorByOwner(payload);
    },
    deleteItem(monit) {
      this.currentMonitoringId = monit.id;
      this.deleteConfirmationDialog = true;
    },
    async deleteMonitoringConfirm() {
      this.loading = true;
      await this.deleteMonitoring(this.currentMonitoringId);
      this.loading = false;
      this.deleteConfirmationDialog = false;
      await this.fetchMonitoringByCandidate(this.currentCandidate.id);
    },
  },

  async mounted() {
    await this.fetchCampaigns();
    this.userToken = await getToken();

    setOptions({
      server: {
        url: this.imagesUploadUrl,
        process: {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.userToken}`,
          },
          timeout: 7000,
          onload: async (response) => {
            // on success
            // send success message
            this.newImageLink = JSON.parse(response).path;

            // upload monitoring
            await this.uploadMonitoring();
            // show success message
            reportSuccess("Billboard Monitoring Uploaded Successfully");
            // close imageupload panel
            this.showUploadImagePanel = false;

            // TODO: on delete remove monitoring
            // TODO: on success refresh refresh bb images
            await this.fetchMonitoringByCandidate(this.currentCandidate.id);
          },
          ondata: (formData) => {
            formData.append("billboard-images", this.images64);
            return formData;
          },
          onerror: (response) => {
            const message = response.data
              ? `Error Uploading Image: ${response.data.error.message}`
              : "An error was encountered trying to upload image.";
            return handleHttpError(message);
          },
        },
      },
    });
  },
};
</script>

<style scoped>
.v-card {
  font-family: Manrope, Arial, sans-serif;
}

.v-card,
.v-dialog {
  font-family: Manrope, Arial, sans-serif;
}

.images {
  /* overflow-x: scroll; */
  overflow: hidden;
  max-width: 5em;
}

.image {
  height: 50px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
  /* position: absolute; */
}

.image:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}

.filepond--wrapper {
  width: 40%;
  margin: 0 auto;
}

.filepond--credits {
  display: none;
}

.filepond--list {
  margin: 10px !important;
}

.filepond--drop-label {
  background-color: var(--v-secondary-lighten3);
  border-radius: 20px;
  color: black;
}
</style>
