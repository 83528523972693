<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col id="driver" cols="3" sm="3" md="3">
        <v-row>
          <v-card
            width="100%"
            elevation="0"
            :color="$vuetify.theme.themes.dark.background"
            class="white--text"
          >
            <v-btn color="expansionPanels" elevation="0" class="active">
              <v-icon color="black">mdi-cart</v-icon>
              View Cart
            </v-btn>
            <v-btn color="expansionPanels" elevation="0" to="/campaigns">
              <v-icon color="black">mdi-compass</v-icon>
              Create Campaign
            </v-btn>
          </v-card>
        </v-row>
      </v-col>

      <v-col cols="9" sm="9" md="9" class="pa-1 cartContainer">
        <v-toolbar
          height="34"
          flat
          :color="$vuetify.theme.themes.dark.background"
          class="my-1 white--text"
        >
          <v-spacer />
          <v-toolbar-title>Billboards Cart</v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <div class="white--text" v-if="getBriefsCart.budget" id="priceDriver">
          <v-row>
            <div>Brief Budget: {{ getBriefsCart.budget }} GH₵</div>
          </v-row>
          <v-row>
            <div>Duration: {{ getDuration() }} Days</div>
          </v-row>
          <v-row>
            <div>Total Billboards Prices: {{ totalBbsPrice() }} GH₵</div>
          </v-row>
          <v-row>Price Difference: {{ budgetDiff() }} GH₵</v-row>
        </div>

        <v-progress-linear
          color="deep-purple accent-4"
          :active="exportProgress"
          :indeterminate="exportProgress"
        ></v-progress-linear>

        <v-card
          v-if="billboards.length"
          :color="$vuetify.theme.themes.dark.background"
          class="white--text"
        >
          <v-data-table
            :items="billboards"
            :headers="headers"
            sort-by="name"
            class="elevation-1 pa-3 white--text"
            :style="{
              'background-color': $vuetify.theme.themes.dark.background,
              color: $vuetify.theme.themes.dark.text,
            }"
            dark
            :loading="!billboards.length"
            loading-text="Fetching Billboards... Please wait"
            no-data-text="There are no billboards in the cart"
          >
            <template v-slot:[`item.number`]="{ _, index }">
              {{ _ }} {{ index + 1 }}
            </template>

            <template v-slot:[`item.images`]="{ item }">
              <div class="p-2">
                <div v-viewer class="images">
                  <img
                    class="image"
                    v-for="img in item.Images"
                    :key="img.id"
                    :src="img.path"
                    :lazy-src="img.path"
                    loading="lazy"
                    alt="Billboard Image"
                  />
                </div>
              </div>
            </template>

            <template v-slot:[`item.total_rate_card`]="{ item }">
              {{ getTotalRate(item.rate_card) }}
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              {{ formatDate(item.createdAt) }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="error" title="Delete" @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>

            <template slot="no-data"> NO DATA HERE!</template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn width="200" to="/campaigns" class="white--text" outlined>
              Continue Browsing</v-btn
            >
            <v-btn
              color="secondary"
              v-if="getBbCart"
              @click="checkOut"
              width="200"
              >Buy Billboards</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showCheckoutForm" width="300">
      <v-card
        :color="$vuetify.theme.themes.dark.background"
        elevation="0"
        class="py-5 px-2 mx-auto white--text"
      >
        <v-card-subtitle class="white--text">One Last step</v-card-subtitle>
        <v-progress-linear
          color="deep-purple accent-4"
          :active="savePlanLoader"
          :indeterminate="savePlanLoader"
        ></v-progress-linear>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col>
                <v-text-field
                  dark
                  v-model.trim="planDetails.plan_name"
                  label="Campaign Name"
                  :rules="rules.genRule"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <div v-if="!getBriefsCart.id">
              <v-row>
                <v-col>
                  <v-text-field
                    dark
                    v-model.trim="planDetails.brand"
                    label="Brand Name"
                    :rules="rules.genRule"
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-combobox
                    v-model="planDetails.marketerEmails"
                    multiple
                    persistent-hint
                    small-chips
                    label="Add Marketer Email(s)"
                    dark
                    @change="addNewEmail"
                    solo
                    :items="[]"
                    :error-messages="marketerEmailsErrors"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    dark
                    v-model.trim="planDetails.estimate"
                    label="Campaign Budget"
                    :rules="rules.genRule"
                    suffix="GH₵"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-container fill-height fluid>
                  <v-row align="center" justify="center">
                    <v-col>
                      <v-menu
                        v-model="startMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dark
                            v-model="planDetails.start_date"
                            label="Start date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="rules.genRule"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          dark
                          v-model="planDetails.start_date"
                          @change="startMenu = false"
                          :min="nowDate"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row align="center" justify="center">
                    <v-col>
                      <v-menu
                        v-model="endMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dark
                            v-model="planDetails.end_date"
                            label="End date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            :rules="rules.genRule"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          dark
                          v-model="planDetails.end_date"
                          :min="nowDate"
                          @change="endMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn max-width="70" @click="showCheckoutForm = false"
                >Cancel</v-btn
              >
              <v-btn max-width="70" @click="submitPlan" color="secondary"
                >Save</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <alert></alert>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as dayjs from "dayjs";

import { handleHttpError } from "@/helpers/utils.js";
import { reportSuccess } from "@/helpers/utils";

export default {
  data() {
    return {
      loading: false,

      exportProgress: false,

      billboards: [],

      headers: [
        { text: "#", align: "start", sortable: false, value: "number" },
        { text: "Image", sortable: false, value: "images" },
        { text: "Lighting", value: "lighting" },
        { text: "Price(GH₵)", value: "rate_card" },
        { text: "Total Campaign Price(GH₵)", value: "total_rate_card" },
        {
          text: "Length (Metres)",
          value: "length",
        },
        { text: "Width (Metres)", value: "width" },
        { text: "Height", value: "height" },
        { text: "Clutter", value: "clutter" },
        { text: "Faces", value: "faces" },
        { text: "Orientation", value: "orientation" },
        { text: "Site Run Up(m)", value: "site_run_up" },
        { text: "Medium", value: "medium" },
        { text: "Address", value: "address" },

        { text: "Actions", value: "actions", sortable: false },
      ],
      mode: null,

      planDetails: {
        plan_name: null,
        estimate: 0,
        start_date: null,
        end_date: null,
        brand: "",
        marketerEmails: [],
      },

      startMenu: false,
      endMenu: false,
      nowDate: new Date().toISOString().slice(0, 10),

      savePlanLoader: false,
      valid: false,
      showCheckoutForm: false,
      rules: {
        genRule: [(v) => !!v || "Please fill this."],
      },
      marketerEmailsErrors: [],
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "getCurrentUser",
      getBbCart: "billboards/getBbCart",
      getBriefsCart: "briefs/getBriefsCart",
    }),
  },

  methods: {
    ...mapActions({
      fetchBB: "billboards/fetchBB",
      fetchBbCart: "billboards/fetchBbCart",
      removeBbFromCart: "billboards/removeBbFromCart",
      addCampaign: "campaigns/addCampaign",
    }),

    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date.toDateString();
    },

    async getBillboards() {
      if (!this.getBbCart) {
        this.loading = false;
        return handleHttpError("Cart is currently empty");
      } else {
        this.billboards = [];
        for (let bbID of this.getBbCart) {
          let bb = await this.fetchBB(bbID);
          this.billboards.push(bb);
        }
        return this.billboards;
      }
    },

    totalBbsPrice() {
      const total = this.billboards.reduce((acc, bb) => {
        let price;
        if (this.getBriefsCart.start_date && this.getBriefsCart.end_date) {
          price = this.calculateTotalCandidateRate(
            this.getBriefsCart.start_date,
            this.getBriefsCart.end_date,
            +bb.rate_card
          );
        } else {
          price = parseInt(bb.rate_card);
        }
        if (!price) {
          price = 0;
        }
        return acc + price;
      }, 0);
      if (!total) return 0;
      return total;
    },
    getDuration(){
      let end = dayjs(this.getBriefsCart.end_date);
      let start = dayjs(this.getBriefsCart.start_date);

      let diff = end.diff(start, "d");

      if (!diff) return 0;
      return diff;
    },

    getTotalRate(rate_card) {
      if (this.getBriefsCart.start_date && this.getBriefsCart.end_date) {
        return this.calculateTotalCandidateRate(
          this.getBriefsCart.start_date,
          this.getBriefsCart.end_date,
          +rate_card
        );
      } else {
        return rate_card;
      }
    },

    calculateTotalCandidateRate(
      startDateStr,
      endDateStr,
      billboardCostPerMonth
    ) {
      const startDate = new Date(startDateStr);
      const endDate = new Date(endDateStr);

      if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
        throw new Error(
          "Invalid date format. Please provide valid Date objects for startDate and endDate."
        );
      }

      const startYear = startDate.getFullYear();
      const startMonth = startDate.getMonth();
      const endYear = endDate.getFullYear();
      const endMonth = endDate.getMonth();

      const monthsInCampaign =
        (endYear - startYear) * 12 + (endMonth - startMonth) + 1;

      // Calculate the total cost
      const totalCost = billboardCostPerMonth * monthsInCampaign;

      return totalCost;
    },

    budgetDiff() {
      const briefBudg = parseInt(this.getBriefsCart.budget) || 0;
      const totalBBPrice = this.totalBbsPrice();

      const diff = briefBudg - totalBBPrice;

      if (Math.sign(diff) > 0) {
        return `${diff}`;
      } else {
        return `-${diff}`;
      }
    },

    async deleteItem(bb) {
      await this.removeBbFromCart(bb.id);
      await this.getBillboards();
    },

    async checkOut() {
      this.showCheckoutForm = true;
    },

    inMarketerEmails(newEmail) {
      return this.planDetails.marketerEmails.indexOf(newEmail) > -1;
    },

    addNewEmail() {
      this.planDetails.marketerEmails = this.planDetails.marketerEmails.filter(
        (item) => this.isValidEmail(item)
      );
      if (this.planDetails.marketerEmails.length > 0) {
        const newItems = this.planDetails.marketerEmails.filter(
          (item) => !this.planDetails.marketerEmails.includes(item)
        );
        this.planDetails.marketerEmails.push(...newItems);
        this.marketerEmailsErrors = [];
      }
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    async submitPlan() {
      this.$refs.form.validate();

      if (!this.valid) return;

      if (!this.getBriefsCart.id) {
        if (!this.planDetails.marketerEmails.length) {
          this.marketerEmailsErrors.push("Please add a Marketer Email");
          return;
        } else {
          this.marketerEmailsErrors = [];
        }
      }

      this.savePlanLoader = true;
      let res;
      this.planDetails.email = this.getCurrentUser.email;
      this.planDetails.billboards = this.getBbCart;

      if (!this.getBriefsCart.id) {
        const payload = { mode: "map", data: this.planDetails };
        res = await this.addCampaign(payload);
      } else {
        this.planDetails.briefId = this.getBriefsCart.id;
        const payload = { mode: "brief", data: this.planDetails };
        res = await this.addCampaign(payload);
      }

      if (res) {
        this.savePlanLoader = false;
        this.showCheckoutForm = false;

        for (const bbID of this.getBbCart) {
          await this.removeBbFromCart(bbID);
        }
        reportSuccess("Campaign created successfully");

        setTimeout(() => this.$router.replace({ path: "/campaigns" }), 5000);
      }
      this.savePlanLoader = false;
    },

    showLoading() {
      this.exportProgress = true;
    },
    removeLoading() {
      this.exportProgress = false;
    },
  },

  async mounted() {
    await this.fetchBbCart();
    await this.getBillboards();
  },
};
</script>

<style scoped>
#driver {
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.v-btn {
  margin: 10px;
  height: 48px !important;
  width: 90%;
  text-transform: none;
}

.active {
  background: #f68625 !important;
}
.v-data-table__expanded__content {
  background-color: antiquewhite;
}
.image {
  height: 50px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}
.image:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}

.v-card {
  font-family: Manrope, Arial, sans-serif;
}

.v-data-table-header {
  background: #f9f9fa;
}

#priceDriver {
  font-family: Manrope, Arial, sans-serif;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.cartContainer {
  font-family: Manrope, Arial, sans-serif;
  box-shadow: 10px 10px 8px -6px rgba(225, 218, 218, 0.919) !important;
  -webkit-box-shadow: 10px 10px 8px -6px rgba(248, 243, 243, 0.931) !important;
  -moz-box-shadow: 10px 10px 8px -6px rgba(247, 238, 238, 0.939) !important;
  border-right: 1px solid white !important;
  border-top: 0.4px solid rgb(113, 111, 111) !important;
  border-left: 0.4px solid rgb(113, 111, 111) !important;
  border-bottom: 1px solid white !important;
}
</style>
