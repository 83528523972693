import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      monitorings: [],
      currentMonitoring: {},
      planMonitoring: {
        name: null,
        candidatesCount: null,
        monitoringCount: null,
        candidatesWithMonitoringCount: null,
        candidatesWithoutMonitoringCount: null,
        Candidates: [
          {
            Billboard: {
              Images: [
                {
                  path: null,
                },
              ],
            },
            Monitorings: [
              {
                Image: { path: null },
                Monitor: {},
              },
            ],
          },
        ],
      },
    };
  },
  mutations,
  getters,
  actions,
};
