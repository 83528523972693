import { baseUrl, handleHttpError, getToken } from "./utils";
const axios = require("axios").default;

async function fetchAbontenPlanners() {
  try {
    const token = await getToken();

    const response = await axios.get(`${baseUrl}/planners/abonten`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (err) {
    const message = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Marketing Agencies. Please try again or contact the System Admin";
    return handleHttpError(message);
  }
}

export { fetchAbontenPlanners };
