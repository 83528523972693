<template>
  <v-card
    width="100%"
    min-height="650"
    id="bus"
    class="py-5 px-2 mx-auto white--text containerCard"
    :color="$vuetify.theme.themes.dark.background"
  >
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-toolbar
      :color="$vuetify.theme.themes.dark.background"
      class="white--text b-8"
      flat
    >
      <v-toolbar-title>Brand Form</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-row justify="center">
        <div justify="center" v-if="brand.id">
          <v-col v-for="(img, idx) in brand.BrandImages" :key="idx">
            <div v-viewer v-if="brand.BrandImages[0].path">
              <v-card :color="$vuetify.theme.themes.dark.background" flat>
                <v-card-text class="pt-0">
                  <v-row align="center" justify="center">
                    <img
                      alt="Brand Image"
                      class="previewImage"
                      :src="img.path"
                      :lazy-src="img.path"
                    />
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-icon
                    color="error"
                    title="Delete"
                    @click="deleteItem(img.path)"
                  >
                    mdi-delete
                  </v-icon>
                </v-card-actions>
              </v-card>
            </div>
          </v-col>
        </div>

        <v-form ref="brandForm" v-model="valid">
          <v-card width="450" height="280" class="mb-4 pa-8" dark elevation="0">
            <file-pond
              name="images"
              ref="pond"
              class-name="my-pond"
              label-idle="Drop Images here Or Click here to Upload..."
              max-files="1"
              accepted-file-types="image/jpeg, image/png"
              :files="images64"
              :metadata="null"
            />
          </v-card>

          <v-card width="450" class="mb-4" dark elevation="0">
            <v-text-field
              v-model.trim="brand.name"
              :rules="rules.fieldRules"
              required
              filled
              dark
              label="Brand Name"
            >
            </v-text-field>
          </v-card>

          <v-card
            height="200"
            width="450"
            dark
            elevation="0"
            class="overflow-auto"
          >
            <v-card-subtitle
              :color="$vuetify.theme.themes.dark.background"
              class="white--text"
              flat
            >
              <v-toolbar-title>Brand Category</v-toolbar-title>
            </v-card-subtitle>
            <v-radio-group
              v-model="brand.BrandCategory.id"
              :rules="[(v) => !!v || 'Please select an option']"
              :error-messages="radioErrors"
            >
              <v-radio
                dark
                v-for="brandCategory in brandCategories"
                :key="brandCategory.id"
                :label="`${brandCategory.name}`"
                :value="brandCategory.id"
                :rules="rules.fieldRules"
                required
                color="secondary"
                class="ma-0 pa-2"
              ></v-radio>
            </v-radio-group>
          </v-card>
          <v-row class="my-4" justify="end">
            <v-btn color="green darken-3" @click="createBrand">Save</v-btn>
          </v-row>
        </v-form>
      </v-row>

      <v-dialog v-model="deleteDialog" max-width="500px">
        <v-card
          class="white--text"
          :color="$vuetify.theme.themes.dark.background"
        >
          <v-progress-linear
            color="deep-purple accent-4"
            :active="loading"
            :indeterminate="loading"
          ></v-progress-linear>

          <v-card-title class="text-h5"
            >Are you sure you want to delete this Image?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteImageConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { getToken, handleHttpError } from "@/helpers/utils";

import { deleteImage as deleteImageFromS3 } from "@/helpers/images";

import { setOptions } from "vue-filepond";
import FilePond from "../../mixins/filePond";

export default {
  data() {
    return {
      loading: false,
      brandCategories: [],

      radioErrors: [],
      valid: false,
      rules: {
        fieldRules: [(v) => !!v || "Name is required. Please Fill."],
      },

      imagesUploadUrl: `${process.env.VUE_APP_URL}/images`,
      images64: [],

      deleteDialog: false,
      currentBrandImageId: null,
    };
  },

  props: {
    operation: String,

    brand: {
      type: Object,
      default: () => ({
        name: null,
        BrandCategory: {
          id: null,
        },
        BrandImages: [],
        newImages: [],
      }),
    },
  },

  components: { FilePond },

  methods: {
    ...mapActions({
      editBrand: "brands/updateBrand",
      addBrand: "brands/addBrand",
      fetchBrandCategories: "brands/fetchBrandCategories",
    }),

    async createBrand() {
      this.loading = true;
      this.$refs.brandForm.validate();

      if (!this.brand.BrandCategory.id) {
        this.radioErrors = ["Please select an option"];
      } else {
        this.radioErrors = [];
      }

      if (!this.valid) return;
      if (this.operation === "edit") {
        await this.editBrand(this.brand);
      } else {
        await this.addBrand(this.brand);
      }
      this.loading = false;
    },

    deleteItem(id) {
      this.currentBrandImageId = id;
      this.deleteDialog = true;
    },

    async deleteImageConfirm() {
      await this.deleteImage();
      this.closeDelete();
      this.loading = false;
    },

    async deleteImage() {
      // remove the image from the current BrandImages
      const rmIdx = this.brand.BrandImages.findIndex(
        (img) => img.id === this.currentBrandImageId
      );
      this.brand.BrandImages.splice(rmIdx, 1);
      // send api to delete from s3
      await deleteImageFromS3(this.currentBrandImageId);
    },

    closeDelete() {
      this.deleteDialog = false;
    },

    addNewImages() {
      if (!this.brand.newImages) {
        this.brand.newImages = [];
      }
    },
  },
  watch: {
    deleteDialog: {
      immediate: true,
      handler(val) {
        if (val) this.deleteImageConfirm();
      },
    },
  },

  async mounted() {
    this.loading = true;

    this.brandCategories = await this.fetchBrandCategories();

    this.userToken = await getToken();

    setOptions({
      server: {
        url: this.imagesUploadUrl,
        process: {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.userToken}`,
          },
          timeout: 12000,
          onload: (response) => {
            // on success
            // send success message
            const imageLink = JSON.parse(response).path;
            // add the url to list of billboard images
            // before check if it exists in the list already
            const exists = this.brand.BrandImages.some(
              (img) => img.link === imageLink
            );
            if (this.operation == "edit") {
              if (!exists) {
                this.addNewImages();
                this.brand.newImages.push({
                  link: imageLink,
                });
              }
            } else {
              this.brand.BrandImages.push({
                link: imageLink,
              });
            }
          },
          ondata: (formData) => {
            formData.append("images", this.images64);
            return formData;
          },
          onerror: (response) => {
            const message = response.error
              ? `Error Uploading Image: ${response.error}`
              : "An error was encountered trying to upload image.";
            return handleHttpError(message);
          },
        },
      },
    });

    this.loading = false;
  },
};
</script>

<style>
.containerCard {
  font-family: Manrope, Arial, sans-serif;
  box-shadow: 10px 10px 8px -6px rgba(225, 218, 218, 0.919) !important;
  -webkit-box-shadow: 10px 10px 8px -6px rgba(248, 243, 243, 0.931) !important;
  -moz-box-shadow: 10px 10px 8px -6px rgba(247, 238, 238, 0.939) !important;
  border-right: 1px solid white !important;
  border-top: 0.4px solid rgb(113, 111, 111) !important;
  border-left: 0.4px solid rgb(113, 111, 111) !important;
  border-bottom: 1px solid white !important;
}

.filepond--wrapper {
  width: 99%;
  margin: 0 auto;
}
.filepond--credits {
  display: none !important;
}
.filepond--list {
  margin: 10px !important;
}
.filepond--drop-label {
  background-color: var(--v-secondary-lighten3);
  border-radius: 20px;
  color: black;
}
.filepond--image-preview-wrapper {
  width: 100%;
  height: 150px;
}

.filepond--drop-label.filepond--drop-label label {
  font-size: 12px;
}

#edit {
  color: #66bb6a;
}
.previewImage {
  height: 150px;
  cursor: pointer;
}
.previewImage:hover {
  transform: scale(1.05);
  transition: all 0.2s;
}
</style>
