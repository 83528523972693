import {
  fetchBbsByBbox,
  fetchAllBbs,
  fetchPop,
  fetchBBPaginated,
  deleteBB,
  fetchBB,
  addBillboards,
  updateBillboard,
  addBbToCart,
  fetchBbCart,
  removeBbFromCart,
  searchBillboards,
  fetchBrands,
  fetchSuppliers,
  fetchMyBbs,
  fetchBBsByBriefs,
  clearCart,
  fetchRandomBbs,
} from "@/helpers/billboards";

import { fetchCandidates, updateCandidates } from "@/helpers/candidate";

export default {
  async fetchAllBbs({ commit }) {
    const billboards = await fetchAllBbs();
    return commit("setBillboards", billboards);
  },

  async fetchRandomBbs({ commit }) {
    const bbs = await fetchRandomBbs();
    return commit("setBillboards", bbs);
  },

  async fetchMyBbs({ commit }, payload) {
    const bb = await fetchMyBbs(payload);
    return commit("setBillboards", bb);
  },

  async fetchBB(_, id) {
    const billboard = await fetchBB(id);
    return billboard;
  },

  async fetchPop({ commit }, { lat, long }) {
    const populationData = await fetchPop({ lat, long });
    return commit("setPopulation", populationData);
  },

  async fetchBbsByBbox({ commit }, payLoad) {
    const billboards = await fetchBbsByBbox(payLoad);
    return commit("setBillboards", billboards);
  },

  async fetchBBPaginated(_, payload) {
    const billboards = await fetchBBPaginated(payload);
    return billboards;
  },

  async fetchBBsByBriefs(_, payLoad) {
    const billboards = await fetchBBsByBriefs(payLoad);
    return billboards;
  },

  async addBillboards(_, payload) {
    await addBillboards(payload);
  },

  async updateBillboard(_, payload) {
    await updateBillboard(payload);
  },

  async deleteBB(_, id) {
    await deleteBB(id);
  },

  async addBbToCart({ commit }, id) {
    const bbCart = await addBbToCart(id);
    return commit("setBBToCart", bbCart);
  },

  async fetchBbCart({ commit }) {
    const bbCart = await fetchBbCart();
    return commit("setBBToCart", bbCart);
  },

  async removeBbFromCart({ commit }, id) {
    const bbCart = await removeBbFromCart(id);
    return commit("setBBToCart", bbCart);
  },

  async clearCart({ commit }) {
    const bbCart = await clearCart();
    return commit("setBBToCart", bbCart);
  },

  async searchBillboards({ commit }, payLoad) {
    const results = await searchBillboards(payLoad);
    return commit("setSearchResults", results);
  },

  async fetchBrands({ commit }) {
    const results = await fetchBrands();
    return commit("setBrands", results);
  },

  async fetchSuppliers({ commit }) {
    const res = await fetchSuppliers();
    return commit("setSuppliers", res);
  },

  async fetchCandidates({ commit }, payload) {
    const candidates = await fetchCandidates(payload);
    return commit("setCandidates", candidates);
  },

  async updateCandidates(_, payload) {
    const res = await updateCandidates(payload);
    return res;
  },
};
