import { handleHttpError, reportSuccess, baseUrl, getToken } from "./utils";
const axios = require("axios").default;

async function uploadImage(imageFile) {
  try {
    const token = await getToken();

    const response = await axios.post(`${baseUrl}/images`, imageFile, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data.imageUrl;
  } catch (err) {
    const message = err.response.data
      ? `Error Uploading Image: ${err?.response?.data?.error}`
      : "An error was encountered trying to upload image. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

async function deleteImage(imageId) {
  try {
    const token = await getToken();

    const response = await axios.delete(`${baseUrl}/images/${imageId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status == 200) {
      reportSuccess("Image deleted successful");
      return true;
    }
  } catch (err) {
    const message = err.response.data
      ? `Error Deleting Image: ${err?.response?.data?.error}`
      : "An error was encountered trying to delete image. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

export { uploadImage, deleteImage };
