<template>
  <v-form v-model="valid" ref="forgotPasswordForm">
    <AuthLayout>
      <div class="py-8" v-if="!success">
        <h4 class="mb-2">Forgot password?</h4>
        <p class="white--text" style="font-size: 14px">
          Please enter your registered email address. We'll send instructions to
          help reset your password.
        </p>
        <BeautifulInput
          placeholder="Enter Email"
          icon="mdi-email-outline"
          required
          @input="setEmail"
          :rules="emailRules"
        >
        </BeautifulInput>
        <div class="d-flex">
          <v-btn
            rounded
            class="text-none"
            @click="submitForm"
            :disabled="loading"
            :loading="loading"
            >Send reset instructions</v-btn
          >
        </div>
      </div>
      <div class="py-8" v-if="success">
        <span class="text-center d-block" style="font-size: 48px">✅</span>
        <h4 class="mb-2" style="font-size: 28px">Email instructions sent</h4>
        <p class="white--text" style="font-size: 14px">
          Please follow the instructions we sent to your inbox.
        </p>
        <BeautifulInput
          :placeholder="email"
          required
          @input="setEmail"
          :rules="emailRules"
          disabled
        >
        </BeautifulInput>
        <div class="d-flex">
          <v-btn rounded class="text-none">Instructions Sent!</v-btn>
        </div>
      </div>
    </AuthLayout>
  </v-form>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("user");
import AuthLayout from "@/components/misc/AuthLayout.vue";
import BeautifulInput from "@/components/misc/BeautifulInput.vue";

export default {
  components: { AuthLayout, BeautifulInput },
  data() {
    return {
      valid: false,
      loading: false,
      success: false,

      email: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    ...mapActions(["forgotPassword"]),

    async submitForm() {
      this.$refs.forgotPasswordForm.validate();
      if (!this.valid) return;
      this.loading = true;

      const res = await this.forgotPassword({ email: this.email });
      if (res) this.success = true;
      this.loading = true;
    },
    setEmail(v) {
      this.email = String(v).trim();
    },
  },
};
</script>

<style scoped>
h4 {
  font-size: 36px;
  font-weight: 400;
  color: #fff;
}
</style>
