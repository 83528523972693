export default {
  getBillboards(state) {
    return state.billboards;
  },
  getPopulation(state) {
    return state.population;
  },
  getBbCart(state) {
    return state.bbCart;
  },
  getSearchResults(state) {
    return state.search;
  },
  getBrands(state) {
    return state.brands;
  },
  getSuppliers(state) {
    return state.suppliers;
  },
  getCandidates(state) {
    return state.candidates;
  },
};
