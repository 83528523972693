<template>
  <v-card
    width="100%"
    min-height="650"
    id="bus"
    class="py-5 px-2 mx-auto white--text"
    :color="$vuetify.theme.themes.dark.background"
  >
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-toolbar
      :color="$vuetify.theme.themes.dark.background"
      flat
      class="white--text mb-2"
    >
      <v-toolbar-title>All Users</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-data-table
        :items="allUsers"
        :headers="headers"
        :search="search"
        class="pa-3"
        :style="{
          'background-color': $vuetify.theme.themes.dark.background,
          color: $vuetify.theme.themes.dark.text,
        }"
        dark
        loading-text="Fetching Users... Please wait."
        no-data-text="There are no Users currently"
      >
        <template slot="top">
          <v-toolbar color="grey darken-3" flat>
            <v-btn class="white--text" @click="fetchUsers">
              Refresh
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-responsive max-width="40%" class="mx-auto mb-2">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-responsive>
            <v-spacer></v-spacer>

            <p class="white--text">Total Users: {{ allUsers.length }}</p>
          </v-toolbar>
        </template>

        <template v-slot:[`item.full_name`]="{ item }">
          <span class="secondary--text text--lighten-1">
            {{ item.full_name }}
          </span>
        </template>

        <template v-slot:[`item.userType`]="{ item }">
          <span>
            {{ getUserType(item.userType) }}
          </span>
        </template>

        <template v-slot:[`item.organization`]="{ item }">
          <span class="blue--text text--lighten-2">
            {{ item.Organization.name }}
          </span>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          {{ parseDate(item.createdAt) }}
        </template>
      </v-data-table>
    </v-card-text>

    <alert></alert>
  </v-card>
</template>

<script>
import { fetchAllUsers } from "@/helpers/user";

export default {
  data() {
    return {
      loading: false,

      search: "",
      allUsers: [],
      headers: [
        { text: "Name", value: "full_name" },
        { text: "Designation", value: "designation" },
        { text: "User Type", value: "userType" },
        { text: "Organization", value: "organization" },
        { text: "Joined On", value: "createdAt", sortable: false },
      ],
    };
  },
  methods: {
    parseDate(dateStr) {
      return new Date(dateStr).toDateString();
    },

    async fetchUsers() {
      this.loading = true;
      this.allUsers = await fetchAllUsers();
      this.loading = false;
    },
    getUserType(userType) {
      if (userType === "media_planner") {
        return "Media Planner";
      } else if (userType === "marketer") {
        return "Marketer";
      } else if (userType === "media_owner") {
        return "Media Owner";
      } else if (userType === "other") {
        return "Not Specified";
      } else if (userType === "manager") {
        return "Manager";
      }
    },
  },
  async created() {
    await this.fetchUsers();
  },
};
</script>

<style scoped>
.v-card {
  font-family: Manrope, Arial, sans-serif;
  box-shadow: 10px 10px 8px -6px rgba(225, 218, 218, 0.919) !important;
  -webkit-box-shadow: 10px 10px 8px -6px rgba(248, 243, 243, 0.931) !important;
  -moz-box-shadow: 10px 10px 8px -6px rgba(247, 238, 238, 0.939) !important;
  border-right: 1px solid white !important;
  border-top: 0.4px solid rgb(113, 111, 111) !important;
  border-left: 0.4px solid rgb(113, 111, 111) !important;
  border-bottom: 1px solid white !important;
}
</style>
