<template>
  <v-container class="p-0" fluid fill-height>
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-row class="p-0" align="center">
      <v-col>
        <v-dialog v-model="showFilterDialog" width="40%">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              color="secondary lighten-1"
              @click="shakeBtn = !shakeBtn"
              :class="{ shake: shakeBtn }"
              v-bind="attrs"
              v-on="on"
            >
              Filter
            </v-btn>
          </template>

          <v-card
            :color="$vuetify.theme.themes.dark.background"
            class="white--text"
          >
            <v-toolbar
              elevation="1"
              :color="$vuetify.theme.themes.dark.background"
              class="white--text"
            >
              <v-toolbar-title>Select Campaigns</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-btn icon @click="showFilterDialog = false">
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-row align="center" justify="space-between" class="mx-0 mt-5">
                <span class="white--text mr-9"> DATE RANGE: </span>
                <v-col cols="12" lg="4">
                  <v-menu
                    ref="menu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dark
                        v-model="startDate"
                        label="Start date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      dark
                      v-model="startDate"
                      :active-picker.sync="activePicker1"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <span>TO</span>

                <v-col cols="12" lg="4">
                  <v-menu
                    ref="menu"
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dark
                        v-model="endDate"
                        label="End date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      dark
                      v-model="endDate"
                      :active-picker.sync="activePicker2"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row align="center" justify="space-between" class="mx-0">
                <span class="white--text mr-9"> SPECIFIC DATE: </span>
                <v-col cols="12" lg="7">
                  <v-menu
                    ref="menu"
                    v-model="specificDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dark
                        v-model="specificDate"
                        label="Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      dark
                      v-model="specificDate"
                      :active-picker.sync="activePicker3"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row
                v-if="campaignList.length"
                align="center"
                justify="space-between"
                class="mx-0"
              >
                <span class="white--text mr-9"> CAMPAIGN: </span>
                <v-col cols="12" lg="7">
                  <v-select
                    dark
                    v-if="campaignList.length"
                    :items="campaignList"
                    v-model="currentCampaignId"
                    name="Campaigns"
                    label="Campaign Name"
                    item-color="secondary"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-row class="mb-5" justify="end">
                <v-btn @click="clearAll">Clear All</v-btn>
                <v-btn color="secondary" @click="submit">Submit</v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>

      <v-col>
        <v-row v-if="mode == 'byCampaign'" justify="end" align="center">
          <span class="white--text"> Export : </span>
          <monitoring-ppt :campaign="getMonitorings"></monitoring-ppt>
        </v-row>
      </v-col>
    </v-row>

    <v-card
      elevation="0"
      min-height="400"
      width="100%"
      class="mx-auto mt-4 imagesCard white--text"
      :color="$vuetify.theme.themes.dark.background"
    >
      <v-card-text>
        <v-card
          elevation="0"
          v-if="mode == 'byCampaign'"
          class="mx-auto mt-4 imagesCard white--text"
          :color="$vuetify.theme.themes.dark.background"
        >
          <v-row class="ma-4">
            <v-col cols="6">
              <v-list
                dark
                :color="$vuetify.theme.themes.dark.background"
                class="white--text"
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Total Monitorings:
                      <span class="v-list-item-span">{{
                        +getMonitorings.monitoringCount
                      }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      >Total Billboards Posted:
                      <span class="v-list-item-span">{{
                        +getMonitorings.candidatesWithMonitoringCount
                      }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6">
              <v-list
                dark
                :color="$vuetify.theme.themes.dark.background"
                class="white--text"
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Billboards Not Posted:
                      <span class="v-list-item-span">
                        {{ +getMonitorings.candidatesWithoutMonitoringCount }}
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Campaign Progress:
                      <span class="v-list-item-span">{{
                        getMonitorings.campaignProgress
                      }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row
            v-for="candidate in getMonitorings.Candidates"
            :key="candidate.id"
          >
            <v-col class="px-0 ma-0" cols="12" md="2">
              <div class="d-flex flex-column">
                <p class="white--text">
                  Address:
                  <span class="v-list-item-span">
                    {{ candidate?.Billboard?.address }}</span
                  >
                </p>

                <p class="white--text">
                  Lighting:
                  <span class="v-list-item-span">{{
                    candidate.Billboard.lighting
                  }}</span>
                </p>

                <p class="white--text">
                  Dimensions:
                  <span class="v-list-item-span">
                    {{ candidate.Billboard.length }} *
                    {{ candidate.Billboard.width }} Metres
                  </span>
                </p>

                <p class="white--text">
                  Clutter:
                  <span class="v-list-item-span">
                    {{ candidate.Billboard.clutter }}
                  </span>
                </p>
                <p class="white--text">
                  Faces:
                  <span class="v-list-item-span">
                    {{ candidate.Billboard.faces }}
                  </span>
                </p>

                <p class="white--text">
                  Images Posted:
                  <span class="v-list-item-span">
                    {{ candidate.Monitorings.length }}
                  </span>
                </p>
              </div>
            </v-col>

            <v-col class="px-0 ma-0" cols="12" md="10">
              <div
                class="image-row"
                v-if="candidate?.Monitorings?.length"
                ref="imageRow"
              >
                <div
                  class="image-container images"
                  ref="imageContainer"
                  v-viewer
                >
                  <div v-for="monit in candidate.Monitorings" :key="monit.id">
                    <div class="d-flex justify-center">
                      <img
                        class="image"
                        :src="`${checkImageLink(monit.Image.path)}`"
                        :lazy-src="`${checkImageLink(monit.Image.path)}`"
                        alt="Billboard Image"
                      />
                    </div>
                    <div class="pt-2">
                      <p class="white--text">
                        Posted On:
                        <span class="v-list-item-span"
                          >{{ parseDate(monit.date) }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <p class="brown--text noBbs">No Postings Found.</p>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          width="100%"
          elevation="0"
          v-if="mode == 'byDate'"
          class="mx-auto mt-4"
          :color="$vuetify.theme.themes.dark.background"
        >
          <div class="d-flex flex-wrap">
            <div
              class="ma-3 pa-2 monitCard"
              v-for="monit in getMonitorings"
              :key="monit.id"
            >
              <div class="images pb-2" v-viewer>
                <img
                  class="image"
                  :src="`${checkImageLink(monit.Image.path)}`"
                  :lazy-src="`${checkImageLink(monit.Image.path)}`"
                  alt="Billboard Image"
                />
              </div>
              <div>
                <p class="white--text">
                  Campaign Name:
                  <span class="v-list-item-span">
                    {{ monit.Candidate?.Plan?.name }}
                  </span>
                </p>

                <p class="white--text">
                  Posted On:
                  <span class="v-list-item-span">
                    {{ parseDate(monit.date) }}
                  </span>
                </p>
                <p class="white--text">
                  Address:
                  <span class="v-list-item-span">
                    {{ monit.Candidate?.Billboard?.address }}</span
                  >
                </p>

                <p class="white--text">
                  Lighting:
                  <span class="v-list-item-span">{{
                    monit.Candidate?.Billboard?.lighting
                  }}</span>
                </p>

                <p class="white--text">
                  Dimensions:
                  <span class="v-list-item-span">
                    {{ monit.Candidate?.Billboard?.length }} *
                    {{ monit.Candidate?.Billboard?.width }} Metres
                  </span>
                </p>

                <p class="white--text">
                  Clutter:
                  <span class="v-list-item-span">
                    {{ monit.Candidate?.Billboard?.clutter }}
                  </span>
                </p>
                <p class="white--text">
                  Faces:
                  <span class="v-list-item-span">
                    {{ monit.Candidate?.Billboard?.faces }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </v-card>
      </v-card-text>
    </v-card>

    <alert></alert>
  </v-container>
</template>

<script>
import { handleHttpError } from "@/helpers/utils.js";

import { mapGetters, mapActions } from "vuex";
import { fetchUserCampaigns } from "@/helpers/campaigns";
import MonitoringPpt from "../../components/monitoring/MonitoringPpt.vue";

export default {
  data() {
    return {
      currentComponent: "MonitoringMap",
      buttons: [
        { name: "Map", icon: "mdi-map-legend", cmp: "MonitoringMap" },
        { name: "Table", icon: "mdi-table-large", cmp: "MonitoringList" },
        { name: "Help", icon: "mdi-desktop-mac", cmp: "DocumentationHelper" },
      ],

      campaign: null,
      shakeBtn: true,
      campaignList: [],
      currentCampaignId: null,

      showFilterDialog: false,

      helpButtons: [
        {
          name: "View Monitoring Help",
          cmp: "/abonten_manual/monitoring/monitoring.md",
        },
      ],

      activePicker1: null,
      activePicker2: null,
      activePicker3: null,

      startDate: null,
      startDateMenu: false,

      specificDate: null,
      specificDateMenu: false,

      endDate: null,
      endDateMenu: false,

      loading: false,
      mode: "",
    };
  },

  components: { MonitoringPpt },

  computed: {
    ...mapGetters({
      getCurrentCampaign: "campaigns/getCurrentCampaign",
      getMonitorings: "monitoring/getMonitorings",
      getCurrentUser: "getCurrentUser",
    }),
  },

  methods: {
    ...mapActions({
      fetchCampaign: "campaigns/fetchCampaign",
      fetchMonitoringByDate: "monitoring/fetchMonitoringByDate",
      fetchMonitoringByDates: "monitoring/fetchMonitoringByDates",
      fetchMonitoringByCandidate: "monitoring/fetchMonitoringByCandidate",
      fetchMonitoringByCampaign: "monitoring/fetchMonitoringByCampaign",
    }),

    checkImageLink(link) {
      if (/^nyc3/.test(link)) {
        return (
          "https://storage.abonten.com/" + link.split("/").slice(2).join("/")
        );
      } else {
        return link;
      }
    },

    setComponent(cmp) {
      this.currentComponent = cmp;
    },

    setCampaign() {
      this.campaign = this.getCurrentCampaign;
      this.showCampaignDialog = false;
    },

    save(date) {
      this.$refs.menu.save(date);
    },

    clearAll() {
      (this.startDate = null),
        (this.specificDate = null),
        (this.endDate = null),
        (this.currentCampaignId = null);
    },
    parseDate(date) {
      return new Date(date).toDateString();
    },
    async submit() {
      this.loading = true;
      if (this.specificDate) {
        this.mode = "byDate";
        await this.fetchMonitoringByDate(this.specificDate);
      } else if (this.currentCampaignId) {
        this.mode = "byCampaign";
        await this.fetchMonitoringByCampaign(this.currentCampaignId);
      } else if (this.startDate && this.endDate) {
        this.mode = "byDate";
        await this.fetchMonitoringByDates({
          start_date: this.startDate,
          end_date: this.endDate,
        });
      }
      this.loading = false;
      this.showFilterDialog = false;
    },
  },

  watch: {
    showCampaignDialog(state) {
      if (!state && !this.getCurrentCampaign) {
        handleHttpError("No Campaign was selected.");
        this.shakeBtn = true;
        return;
      }
      this.shakeBtn = false;
    },
  },

  async mounted() {
    this.campaignList = await fetchUserCampaigns(this.getCurrentUser.userId);
  },
};
</script>

<style scoped>
.container {
  font-family: Manrope, Arial, sans-serif;
  background: #424242;
  box-shadow: 10px 10px 8px -6px rgba(225, 218, 218, 0.919) !important;
  -webkit-box-shadow: 10px 10px 8px -6px rgba(248, 243, 243, 0.931) !important;
  -moz-box-shadow: 10px 10px 8px -6px rgba(247, 238, 238, 0.939) !important;
  border-right: 1px solid white !important;
  border-top: 0.4px solid rgb(113, 111, 111) !important;
  border-left: 0.4px solid rgb(113, 111, 111) !important;
  border-bottom: 1px solid white !important;
}
.controller {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.controller p {
  margin: 0;
  padding-right: 10px;
}

.v-btn {
  background: rgba(143, 146, 161, 0.05);
  border-radius: 32px;
  margin: 0 10px;
  text-transform: none;
}
.active {
  background: #f68625 !important;
}

.v-skeleton-loader__image {
  height: 100% !important;
}

.shake {
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.monitoringDetails {
  font-size: 13px;
  font-family: Manrope, Arial, sans-serif;
}

.image {
  max-height: 150px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
  transition: all 0.2s;
}
.image:hover {
  transform: scale(1.06);
  transition: all ease-in 0.2s;
}

.v-list-item-span {
  color: var(--v-secondary-lighten4) !important;
}

.image-row {
  display: flex;
  align-items: center;
  overflow-x: auto;
  position: relative;
}

.image-container {
  display: flex;
  flex-wrap: nowrap;
  height: 200px;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.monitCard {
  border: solid 1px gray;
  border-radius: 10px;
}

.noBbs {
  text-align: center;
  font-size: 1.5em;
}
</style>
