<template>
  <v-card
    width="100%"
    elevation="0"
    class="px-2 mx-auto white--text"
    :color="$vuetify.theme.themes.dark.background"
  >
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-card-title class="white--text">Campaign Details</v-card-title>

    <div class="controller__options">
      <p>Export:</p>
      <campaign-ppt :campaign="currentCampaign"></campaign-ppt>
    </div>

    <v-row class="ma-4">
      <v-col cols="6">
        <v-list
          dark
          :color="$vuetify.theme.themes.dark.background"
          class="white--text"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Name:
                <span class="v-list-item-span">{{ campaign_name }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Budget:

                <span class="v-list-item-span">GH₵ {{ parseBudget() }} </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Plan By:
                <span class="v-list-item-span"
                  >{{ planner_name }} ({{ planner_organization }})</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Brand:
                <span class="v-list-item-span">{{ brand_name }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Created On:
                <span class="v-list-item-span">{{ createdOn }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Total Potential Reach:
                <span class="v-list-item-span"
                  >{{ totalPotentialReach }} People</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="6">
        <v-list
          dark
          :color="$vuetify.theme.themes.dark.background"
          class="white--text"
          ><v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Duration:
                <span class="v-list-item-span">{{ duration }} Days</span>
                <span class="v-list-item-span">
                  ( {{ start_date }} : {{ end_date }} )
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Total Negotiated Cost:
                <span class="v-list-item-span"
                  >GH₵ {{ totalNegotiatedCost }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Total Billboards Rate Card:
                <span class="v-list-item-span">GH₵ {{ totalRateCard }} </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Sites:
                <span class="v-list-item-span">{{ sites }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Approval from Marketer:
                <v-chip text-color="white" :color="getApprovalColour()">
                  {{ approved }}</v-chip
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Marketer Comment:
                <span class="v-list-item-span">{{ truncatedComment }}</span>
                <a
                  v-if="currentCampaign?.PlanComments?.length"
                  @click="commentDialog = true"
                  class="read-more-link"
                  >Read More</a
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row class="bordered-row">
      <v-card width="100%" :color="$vuetify.theme.themes.dark.background">
        <v-toolbar
          :color="$vuetify.theme.themes.dark.background"
          height="34"
          flat
          class="my-1"
        >
          <v-spacer />
          <v-toolbar-title class="white--text">
            Billboards Sites
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <div class="d-flex justify-end align-center white--text">
          <p class="pa-0 ma-0">VIEW:</p>

          <v-btn
            v-for="btn in buttons"
            elevation="0"
            :key="btn.name"
            @click="setComponent(btn.cmp)"
            :class="{ active: btn.cmp === currentComponent }"
          >
            <v-icon color="black">{{ btn.icon }}</v-icon>
            {{ btn.name }}
          </v-btn>
        </div>

        <v-row v-if="currentCampaign.Candidates">
          <v-col cols="12" sm="12" lg="12" md="12" xl="12">
            <keep-alive>
              <component
                :is="currentComponent"
                :currentCampaign="currentCampaign"
              ></component>
            </keep-alive>
          </v-col>
        </v-row>
      </v-card>
    </v-row>

    <v-dialog dark v-model="commentDialog" max-width="500">
      <v-card
        class="pa-2 mx-auto white--text"
        :color="$vuetify.theme.themes.dark.background"
      >
        <v-toolbar
          elevation="0"
          :color="$vuetify.theme.themes.dark.background"
          class="white--text mb-2"
        >
          <v-spacer>
            <v-toolbar-title> Campaign Comments</v-toolbar-title></v-spacer
          >
          <v-btn icon @click="commentDialog = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <div v-if="currentCampaign?.PlanComments?.length">
            <div
              v-for="comment in currentCampaign.PlanComments"
              :key="comment.id"
            >
              <p class="white--text">
                <span class="purple--text"
                  >{{ new Date(comment.createdAt).toDateString() }} :
                </span>
                {{ comment.comment }}
              </p>
            </div>
          </div>
          <div v-else>
            <p class="white--text">Campaign does not have comments.</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click="commentDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <alert></alert>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("campaigns");

import CampaignitemBillboards from "./CampaignitemBillboards.vue";
import CampaignMap from "@/components/campaign/ViewCampaignMap.vue";

import CampaignPpt from "./CampaignPpt.vue";

import * as dayjs from "dayjs";
export default {
  data() {
    return {
      maxCommentLength: 15,
      commentDialog: false,

      currentCampaign: {
        id: null,
        budget: null,
        duration: null,
        start_date: null,
        end_date: null,
        name: null,
        PlanComments: [],
        Candidates: [
          {
            negotiated_net_rate: null,
            status: null,
            availability_confirmation: null,
            Comments: [],
            Billboard: {
              id: null,
              latitude: null,
              longitude: null,
              Images: [{ path: null }],
              MediaOwner: {
                User: {
                  organization: null,
                  first_name: null,
                  last_name: null,
                },
              },
            },
          },
        ],

        Planner: {
          User: {
            organization: null,
            first_name: null,
            last_name: null,
          },
        },
        Brief: {
          name: null,
          budget: null,
          BrandOwner: {
            Brand: {
              name: null,
            },
          },
        },
      },

      currentComponent: "CampaignMap",
      buttons: [
        {
          name: "Table",
          icon: "mdi-table-large",
          cmp: "CampaignitemBillboards",
        },
        { name: "Map", icon: "mdi-map-legend", cmp: "CampaignMap" },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getCurrentCampaign"]),

    excelExportFields() {
      return {
        "Price (GH₵)": "rate_card",
        "Current Brand": "brand_name",
        Lighting: "lighting",
        "Length (Metres)": "length",
        "Width (Metres)": "width",
        Clutter: "clutter",
        Medium: "medium",
        Orientation: "orientation",
        Faces: "faces",
        Address: "address",
        "Site Run Up (m)": "site_run_up",
        Images: {
          field: "images",
          callback: (val) => {
            return `${decodeURIComponent(val[0].path)},`;
          },
        },
      };
    },

    truncatedComment() {
      return this.currentCampaign.PlanComments.length ? "" : "Not Available";
    },

    exportName() {
      return `${this.campaign_name}_${this.createdOn}`;
    },
    campaign_name() {
      return this.currentCampaign.name;
    },
    planner_name() {
      return `${this.currentCampaign.Planner.User.first_name} ${this.currentCampaign.Planner.User.last_name}`;
    },
    planner_organization() {
      return this.currentCampaign.Planner.User.organization;
    },
    createdOn() {
      return new Date(this.currentCampaign.createdAt).toDateString();
    },
    brand_name() {
      return this.currentCampaign.Brief?.BrandOwner?.Brand?.name || "N/A";
    },
    start_date() {
      return new Date(this.currentCampaign.Brief?.start_date).toDateString();
    },
    end_date() {
      return new Date(this.currentCampaign.Brief?.end_date).toDateString();
    },
    sites() {
      return this.currentCampaign.Candidates.length;
    },
    bbs() {
      return this.currentCampaign.Billboards;
    },
    exportBBS() {
      return this.currentCampaign.Billboards;
    },
    duration() {
      if (this.currentCampaign?.Brief?.duration) {
        return this.currentCampaign.Brief.duration;
      } else {
        let end_date = dayjs(this.currentCampaign?.Brief?.end_date);
        let start_date = dayjs(this.currentCampaign?.Brief?.start_date);

        let diff = end_date.diff(start_date, "d");
        if (!diff) return 0;
        return this.parseNumber(diff);
      }
    },

    totalPotentialReach() {
      return new Intl.NumberFormat().format(
        this.currentCampaign?.totalOppContact
      );
    },
    totalNegotiatedCost() {
      if (!this.currentCampaign.Candidates.length) return "Not Established";
      const total = this.currentCampaign.Candidates.reduce((acc, curr) => {
        return acc + +curr.negotiated_net_rate;
      }, 0);
      return new Intl.NumberFormat().format(total);
    },

    totalRateCard() {
      if (!this.currentCampaign.Candidates.length) return "Not Established";
      const total = this.currentCampaign.Candidates.reduce((acc, curr) => {
        return acc + +curr.total_rate_card;
      }, 0);
      return new Intl.NumberFormat().format(total);
    },

    approved() {
      if (!this.currentCampaign.approval) return "Not Approved";
      return "Approved";
    },
  },

  components: { CampaignitemBillboards, CampaignPpt, CampaignMap },

  methods: {
    ...mapActions(["fetchCampaign"]),

    showLoading() {
      this.exportProgress = true;
    },
    removeLoading() {
      this.exportProgress = false;
    },

    parseNumber(amount) {
      return new Intl.NumberFormat().format(amount);
    },

    getApprovalColour() {
      if (this.currentCampaign.approval) {
        return "green";
      } else {
        return "var(--v-primary-lighten1)";
      }
    },

    parseBudget() {
      if (!this.currentCampaign?.Brief?.budget) return 0;
      return new Intl.NumberFormat().format(this.currentCampaign.Brief.budget);
    },

    setComponent(cmp) {
      this.currentComponent = cmp;
    },
  },

  watch: {
    getCurrentCampaign: {
      deep: true,
      handler(state) {
        this.currentCampaign = state;
      },
    },
  },
  created() {
    this.currentCampaign = this.getCurrentCampaign;
  },
};
</script>

<style scoped>
.v-card__title {
  padding: 5px;
  color: black;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
}

.controller__options {
  /* margin: 10px 0; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.controller__options p {
  margin: 0;
  padding-right: 10px;
}

.controller__options > .v-btn {
  background: rgba(143, 146, 161, 0.05);
  border-radius: 32px;
  margin: 0 10px;
}

.v-list-item-span {
  color: var(--v-secondary-lighten4) !important;
}
.bordered-row {
  border-top: solid 1px whitesmoke;
}
.read-more-link {
  color: rgb(221, 255, 0);
  text-decoration: underline;
  cursor: pointer;
}
</style>
