import Vue from "vue";
import VueRouter from "vue-router";

import AuthRequired from "./guards/auth_required";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/insights",
    component: () => import(/* webpackChunkName: "main" */ "@/pages"),
    children: [
      {
        path: "/insights",
        name: "Insights",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "insights" */ "@/pages/insights/MarketInsights.vue"
          ),
        meta: {
          resource: "insights",
        },
      },
      {
        path: "/briefs",
        name: "Brief",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "briefs" */ "@/pages/briefs/TheBrief.vue"
          ),
        meta: {
          resource: "briefs",
        },
      },
      {
        path: "/all-briefs",
        name: "AllBriefs",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "all-briefs" */ "@/pages/briefs/AllBriefs.vue"
          ),
        meta: {
          resource: "all-briefs",
        },
      },
      {
        path: "/monitoring",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "monitoring" */ "@/pages/monitoring/BillboardsMonitoring.vue"
          ),
        meta: {
          resource: "monitoring",
        },
      },
      {
        path: "/all-monitoring",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "all-monitoring" */ "@/pages/monitoring/AllMonitorings.vue"
          ),
        meta: {
          resource: "all-monitoring",
        },
      },
      {
        path: "/campaigns",
        name: "Campaign",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "campaigns" */ "@/pages/campaign/TheCampaign.vue"
          ),
        meta: {
          resource: "campaigns",
        },
      },
      {
        path: "/all-campaigns",
        name: "AllCampaigns",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "all-campaigns" */ "@/pages/campaign/AllCampaigns.vue"
          ),
        meta: {
          resource: "all-campaigns",
        },
      },
      {
        path: "/owner-campaigns",
        name: "Campaigns",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "campaigns" */ "@/pages/campaign/OwnerCampaigns.vue"
          ),
        meta: {
          resource: "owner-campaigns",
        },
      },
      {
        path: "/proposed-campaigns",
        name: "Proposed-Campaigns",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "proposed-campaigns" */ "@/pages/campaign/ProposedCampaigns.vue"
          ),
        meta: {
          resource: "proposed-campaigns",
        },
      },
      {
        path: "/proposed-campaign/:campaignToken",
        name: "Proposed-Campaign",
        component: () =>
          import(
            /* webpackChunkName: "proposed-campaign" */ "@/components/campaign/ProposedCampaign.vue"
          ),
        meta: {
          resource: "proposed-campaign",
        },
      },
      {
        path: "/cart",
        name: "Cart",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "cart" */ "@/pages/billboards/BillboardCart.vue"
          ),
        meta: {
          resource: "cart",
        },
      },
      {
        path: "/editBBs",
        name: "EditBillboards",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "editBBs" */ "@/components/billboard/BillboardList.vue"
          ),
        meta: {
          resource: "editBBs",
        },
      },
      {
        path: "/billboards",
        name: "Billboards",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "billboards" */ "@/pages/media_owner/MediaOwner.vue"
          ),
        meta: {
          resource: "billboards",
        },
      },
      {
        path: "/all-billboards",
        name: "AllBillboards",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "all-billboards" */ "@/pages/billboards/AllBillboards.vue"
          ),
        meta: {
          resource: "all-billboards",
        },
      },
      {
        path: "/account-details",
        name: "AccountDetails",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "account-details" */ "@/pages/user/AccountDetails.vue"
          ),
        meta: {
          resource: "account-details",
        },
      },
      {
        path: "/all-users",
        name: "AllUsers",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "all-users" */ "@/pages/user/AllUsers.vue"
          ),
        meta: {
          resource: "all-users",
        },
      },
      {
        path: "/pricing",
        name: "Pricing",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "pricing" */ "@/pages/subscription/ChooseSubscription.vue"
          ),
        meta: {
          resource: "choose-subscription",
        },
      },
      {
        path: "/billboard-availability/:token",
        name: "BillboardAvailability",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "billboard-availability" */ "@/components/campaign/BillboardAvailability.vue"
          ),
        meta: {
          resource: "billboard-availability",
        },
      },
      {
        path: "/inspections",
        name: "Inspections",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "inspections" */ "@/pages/inspections/InspectionsMap.vue"
          ),
        meta: {
          resource: "inspections",
        },
      },
      {
        path: "/brands",
        name: "Brands",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "brands" */ "@/pages/brands/TheBrand.vue"
          ),
        meta: {
          resource: "brands",
        },
      },
      {
        path: "/all-brands",
        name: "AllBrands",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "brands" */ "@/pages/brands/AllBrands.vue"
          ),
        meta: {
          resource: "all-brands",
        },
      },
    ],
  },
  {
    path: "/home",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "homepage" */ "@/pages/Homepage.vue"),
    meta: {
      resource: "homepage",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/pages/Login.vue"),
    meta: {
      resource: "login",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "@/pages/Register.vue"),
    meta: {
      resource: "register",
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: () =>
      import(/* webpackChunkName: "forgot" */ "@/pages/Forgot.vue"),
    meta: {
      resource: "Forgot Password",
    },
  },
  {
    path: "/reset-password/:token",
    name: "Reset Password",
    component: () =>
      import(/* webpackChunkName: "reset" */ "@/pages/Reset.vue"),
    meta: {
      resource: "Reset Password",
    },
  },
  {
    path: "/book-demo",
    name: "Book Demo",
    component: () =>
      import(/* webpackChunkName: "demo" */ "@/pages/BookDemo.vue"),
    meta: {
      resource: "Book Demo",
    },
  },
  {
    path: "/watch-demo",
    name: "Watch Demo",
    component: () =>
      import(/* webpackChunkName: "watch_demo" */ "@/pages/WatchDemo.vue"),
    meta: {
      resource: "Watch Demo",
    },
  },
  {
    path: "/verify-account/:token",
    name: "VerifyAccount",
    component: () =>
      import(
        /* webpackChunkName: "verify-account" */ "@/pages/auth/VerifyAccount.vue"
      ),
    meta: {
      resource: "verify-account",
    },
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: () =>
      import(/* webChunkName: "forbidden" */ "@/pages/Forbidden.vue"),
    meta: {
      resource: "forbidden",
    },
  },
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "@/pages/NotFound.vue"),
    meta: {
      resource: "not-found",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
