import { handleHttpError, baseUrl, getToken } from "./utils";
const axios = require("axios").default;

async function fetchUserDetails(payload) {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/users/${payload}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `Error fetching user details: ${err.response.data.error}`
      : "An error occurred fetching user details. Please try again or contact the system admin";

    return handleHttpError(error);
  }
}

async function fetchAllUsers() {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/users`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `Error fetching user details: ${err.response.data.error.message}`
      : "An error occurred fetching user details. Please try again or contact the system admin";

    return handleHttpError(error);
  }
}

async function forgotPassword({ email }) {
  try {
    const response = await axios.post(`${baseUrl}/users/password-reset`, {
      email,
    });
    if (response.status === 200) return true;

    // sth went wrong
    const error =
      "An error occurred resetting password. Please try again or contact the system admin";
    handleHttpError(error);
    return false;
  } catch (err) {
    const error =
      `Error resetting password: ${err.response.data.error}` ||
      "An error occurred resetting password. Please try again or contact the system admin";

    handleHttpError(error);
    return false;
  }
}

async function resetPassword(payload) {
  try {
    const response = await axios.post(
      `${baseUrl}/users/set-new-password/${payload.token}`,
      {
        password: payload.password,
      }
    );
    if (response.status === 200) return true;
    return false;
  } catch (err) {
    const error =
      `Error saving new password: ${err.response.data.data.message.error}` ||
      "An error occurred saving new password. Please try again or contact the system admin";

    handleHttpError(error);
    return false;
  }
}

async function verifyAccount(payload) {
  try {
    const response = await axios.post(
      `${baseUrl}/users/verify-account/${payload.token}`
    );
    if (response.status === 200) return true;
    return false;
  } catch (err) {
    const error =
      `Error verifying account: ${err.response.data.data.message.error}` ||
      "An error occurred verifying account. Please try again or contact the system admin";

    handleHttpError(error);
    return false;
  }
}

export {
  fetchUserDetails,
  forgotPassword,
  resetPassword,
  verifyAccount,
  fetchAllUsers,
};
