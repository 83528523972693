<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-row justify="center" align="center">
      <v-card width="100%" v-if="getCandidates">
        <v-toolbar elevation="0" color="expansionPanels">
          <v-row justify="center">
            <span class="text-h5">BILLBOARD STATUS</span>
          </v-row>
        </v-toolbar>

        <v-card-subtitle>
          <p class="text-h6">
            Please confirm the availability of each of the billboards.
          </p>
        </v-card-subtitle>

        <v-row class="plan_intro">
          <v-col class="plan_intro_row">
            <p><span>Plan Name:</span>{{ getCandidates.plan.name }}</p>
          </v-col>
          <v-col class="plan_intro_row">
            <p>
              <span>Plan By:</span>
              {{ getCandidates.plan.Planner.User.first_name }}
              {{ getCandidates.plan.Planner.User.last_name }}
            </p>
          </v-col>
          <v-col class="plan_intro_row">
            <p>
              <span>Planner's Organization:</span>
              {{ getCandidates.plan.Planner.User.organization }}
            </p>
          </v-col>
        </v-row>

        <v-card-text>
          <v-form ref="bbAvailabilityForm" v-model="valid">
            <v-row
              class="pa-3 bb"
              justify="center"
              align="center"
              v-for="candidate in getCandidates.candidates"
              :key="candidate.id"
            >
              <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <div v-viewer class="images">
                  <img
                    v-for="img in candidate.Billboard.Images"
                    class="image"
                    :key="img.id"
                    :src="img.path"
                    alt="Billboard Image"
                    :lazy-src="img.path"
                    loading="lazy"
                  />
                </div>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row>
                  <v-col class="search--cat--title">Dimensions</v-col>
                  <v-col>
                    {{ candidate.Billboard.length }} *
                    {{ candidate.Billboard.width }} Metres
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="search--cat--title">Location</v-col>
                  <v-col> {{ candidate.Billboard.address }}</v-col>
                </v-row>
                <v-row>
                  <v-col class="search--cat--title">Price</v-col>
                  <v-col> {{ candidate.Billboard.rate_card }} GH₵</v-col>
                </v-row>
                <v-row>
                  <v-col class="search--cat--title">Lighting</v-col>
                  <v-col> {{ candidate.Billboard.lighting }} </v-col>
                </v-row>
              </v-col>

              <v-col class="cta" cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                <v-row class="dr">
                  <v-col class="search--cat--title">Required On: </v-col>
                  <v-col
                    >{{ parse_date(candidate.start_date) }} To
                    {{ parse_date(candidate.end_date) }}</v-col
                  >
                </v-row>

                <v-row class="dr">
                  <v-col class="search--cat--title">
                    Is the Billboard Available ?
                  </v-col>
                  <v-col>
                    <v-checkbox
                      class="ma-0"
                      color="success"
                      :label="checkBoxLabel(candidate.status)"
                      v-model="candidate.status"
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <transition name="fade">
                  <v-row class="dr" v-if="!candidate.status">
                    <v-col class="search--cat--title"
                      >Date of availability</v-col
                    >
                    <v-col>
                      <v-dialog
                        ref="dialog"
                        v-model="datePickerModal"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="candidate.available_on"
                            label="Pick date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="ma-0 pa-0"
                            :rules="dateAvailability()"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="candidate.available_on"
                          :min="candidate.start_date"
                          @change="datePickerModal = false"
                        >
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </transition>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" @click="submit">Submit</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>

      <v-card width="100%" v-else>
        <v-toolbar elevation="0" color="expansionPanels">
          <v-row justify="center">
            <span class="text-h5">NO BILLBOARDS FOUND</span>
          </v-row>
        </v-toolbar>
      </v-card>
    </v-row>
    <alert></alert>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("billboards");

export default {
  data() {
    return {
      loading: true,
      valid: false,
      available: true,
      date: null,
      datePickerModal: false,
      nowDate: new Date().toISOString().slice(0, 10),
      token: null,

      rules: {
        dateRule: [(status) => !!status || "Field is required. Please Fill."],
      },
    };
  },
  computed: {
    ...mapGetters(["getCandidates"]),
  },

  methods: {
    ...mapActions(["fetchCandidates", "updateCandidates"]),

    bbImage(images) {
      return images[0].path;
    },
    parse_date(date) {
      return new Date(date).toDateString();
    },

    checkBoxLabel(status) {
      return status === true ? "Available" : "Not Available";
    },

    async submit() {
      this.$refs.bbAvailabilityForm.validate();

      if (!this.valid) return;

      const res = await this.updateCandidates({
        token: this.token,
        candidates: this.getCandidates.candidates,
      });

      this.loading = false;

      if (res) {
        setTimeout(() => this.$router.push({ path: "/billboards" }), 5000);
      }
    },
    dateAvailability() {
      return [(status) => !!status || "Field is required. Please Fill."];
    },
  },

  async mounted() {
    this.token = this.$route.params.token;
    await this.fetchCandidates({ token: this.token });
    this.loading = false;
  },
};
</script>

<style scoped>
.container {
  background: #fff;
}
.plan_intro {
  flex-direction: column;
  padding: 10px;
  text-align: center;
}
.plan_intro_row {
  padding: 0 !important;
}
.v-card__text {
  border-bottom: solid 2px rgb(230, 168, 98);
}
.bb {
  border-top: solid 2px rgb(230, 168, 98);
  font-family: Manrope, Arial, sans-serif;
  min-height: 250px;
}
.image {
  max-width: 200px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}
.image:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}

.dr {
  height: 4.2rem;
  margin: 0 !important;
  padding: 7px 0 !important;
}

.cta {
  background-color: var(--v-accent-lighten3);
  color: black;
}

.search--cat--title {
  color: black;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
