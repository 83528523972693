<template>
  <v-card
    width="100%"
    min-height="650"
    id="bus"
    class="py-5 px-2 mx-auto white--text"
    :color="$vuetify.theme.themes.dark.background"
  >
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-toolbar
      :color="$vuetify.theme.themes.dark.background"
      flat
      class="white--text mb-2"
    >
      <v-toolbar-title>All Billboards</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-data-table
        :items="getBillboards"
        :headers="headers"
        :search="search"
        class="elevation-1 pa-3"
        :loading="!getBillboards.length"
        :style="{
          'background-color': $vuetify.theme.themes.dark.background,
          color: $vuetify.theme.themes.dark.text,
        }"
        loading-text="Fetching All Billboards... Please wait"
        dark
      >
        <template slot="top">
          <v-toolbar color="grey darken-3" flat>
            <v-btn class="white--text" @click="fetchBbs">
              Refresh
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-responsive max-width="60%">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-responsive>

            <v-spacer></v-spacer>

            <p class="white--text">
              Total Billboards: {{ getBillboards.length }}
            </p>
          </v-toolbar>
        </template>

        <template v-slot:[`item.images`]="{ item }">
          <div class="p-2">
            <div v-viewer class="images">
              <img
                class="image"
                v-for="img in item.Images"
                :key="img.id"
                :src="img.path"
                :lazy-src="img.path"
                loading="lazy"
                alt="Billboard"
              />
            </div>
          </div>
        </template>

        <template v-slot:[`item.organization`]="{ item }">
          <span class="blue--text text--lighten-1">
            {{ item.MediaOwner?.User?.Organization?.name }}
          </span>
        </template>

        <template v-slot:[`item.address`]="{ item }">
          <span class="blue--text text--lighten-3">
            {{ item.address }}
          </span>
        </template>

        <template v-slot:[`item.dimensions`]="{ item }">
          <span class="indigo--text text--lighten-4">
            {{ item.length + " X " + item.width }}
          </span>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          {{ parseDate(item.createdAt) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            @click="
              (showMoreBillboardDetailsForm = true), (currentBillboard = item)
            "
          >
            <v-icon color="secondary" title="More Information">
              mdi-eye
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="showMoreBillboardDetailsForm" width="80%">
      <v-toolbar dark flat>
        <span class="text-h5"> Billboards Data</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="showMoreBillboardDetailsForm = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <billboard-view :currentBillboard="currentBillboard"></billboard-view>
    </v-dialog>

    <alert></alert>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("billboards");

import BillboardView from "@/components/billboard/BillboardFeature.vue";
export default {
  data() {
    return {
      loading: false,

      search: "",
      allBillboards: [],
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        {
          text: "Image",
          sortable: false,
          value: "images",
        },
        { text: "Organization", value: "organization" },
        { text: "Address", value: "address" },
        { text: "Lighting", value: "lighting" },
        { text: "Dimensions (Metres)", value: "dimensions" },
        { text: "Height", value: "height" },
        { text: "Clutter", value: "clutter" },
        { text: "Medium", value: "medium" },
        { text: "Faces", value: "faces" },
        { text: "Added On", value: "createdAt" },
        { text: "Actions", value: "actions" },
      ],

      showMoreBillboardDetailsForm: false,
      currentBillboard: {},
    };
  },

  computed: {
    ...mapGetters(["getBillboards"]),
  },
  components: { BillboardView },

  methods: {
    ...mapActions(["fetchAllBbs"]),

    async fetchBbs() {
      this.loading = true;
      await this.fetchAllBbs();
      this.loading = false;
    },

    parseDate(dateStr) {
      return new Date(dateStr).toDateString();
    },
  },

  async created() {
    await this.fetchBbs();
  },
};
</script>

<style scoped>
.v-card {
  font-family: Manrope, Arial, sans-serif;
  box-shadow: 10px 10px 8px -6px rgba(225, 218, 218, 0.919) !important;
  -webkit-box-shadow: 10px 10px 8px -6px rgba(248, 243, 243, 0.931) !important;
  -moz-box-shadow: 10px 10px 8px -6px rgba(247, 238, 238, 0.939) !important;
  border-right: 1px solid white !important;
  border-top: 0.4px solid rgb(113, 111, 111) !important;
  border-left: 0.4px solid rgb(113, 111, 111) !important;
  border-bottom: 1px solid white !important;
}

.image {
  height: 50px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}

.image:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}
</style>
