<template>
  <v-card
    width="100%"
    min-height="650"
    id="bus"
    class="py-5 px-2 mx-auto white--text"
    :color="$vuetify.theme.themes.dark.background"
  >
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-toolbar
      :color="$vuetify.theme.themes.dark.background"
      flat
      class="white--text mb-2"
    >
      <v-toolbar-title>All Campaigns</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-data-table
        :items="allCampaigns"
        :headers="headers"
        :search="search"
        class="pa-3"
        :style="{
          'background-color': $vuetify.theme.themes.dark.background,
          color: $vuetify.theme.themes.dark.text,
        }"
        dark
        loading-text="Fetching Campaigns... Please wait."
        no-data-text="There are no Campaigns currently"
      >
        <template slot="top">
          <v-toolbar color="grey darken-3" flat>
            <v-btn class="white--text" @click="fetchCampaigns">
              Refresh
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-responsive max-width="40%" class="mx-auto mb-2">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-responsive>
            <v-spacer></v-spacer>

            <p class="white--text">
              Total Campaigns: {{ allCampaigns.length }}
            </p>
          </v-toolbar>
        </template>

        <template v-slot:[`item.organization`]="{ item }">
          {{ getOrg(item.Planner) }}
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <span class="blue--text text--lighten-1">
            {{ item.name }}
          </span>
        </template>

        <template v-slot:[`item.planner`]="{ item }">
          {{ getPlanner(item.Planner) }}
        </template>

        <template v-slot:[`item.brand`]="{ item }">
          <span class="secondary--text text--lighten-1">
            {{ item.Brief?.BrandOwner?.Brand?.name || "Not Available" }}
          </span>
        </template>

        <template v-slot:[`item.budget`]="{ item }">
          <span id="formatBudget">
            {{ parseNumber(item.Brief?.budget) }}
          </span>
        </template>

        <template v-slot:[`item.duration`]="{ item }">
          {{ item.Brief?.duration || "Not Available" }}
        </template>

        <template v-slot:[`item.sites`]="{ item }">
          {{ item.Candidates.length }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            @click.stop="selectCampaign(item.id), (showCampaignDialog = true)"
          >
            <v-icon color="secondary" title="More Information">
              mdi-eye
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="showCampaignDialog" width="90%">
      <v-toolbar :color="$vuetify.theme.themes.dark.background">
        <v-spacer></v-spacer>

        <v-btn icon @click="showCampaignDialog = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <campaign-item></campaign-item>
    </v-dialog>

    <alert></alert>
  </v-card>
</template>

<script>
import { fetchAllCampaigns } from "@/helpers/campaigns";
import { mapActions, mapMutations } from "vuex";

import CampaignItem from "@/components/campaign/CampaignItem.vue";
export default {
  data() {
    return {
      loading: false,

      search: "",
      allCampaigns: [],
      headers: [
        {
          text: "Planner's Organization",
          align: "start",
          value: "organization",
          sortable: false,
        },
        { text: "Campaign Name", value: "name" },
        { text: "Campaign By", value: "planner" },
        { text: "Brand Name", value: "brand" },
        { text: "Duration (Days)", value: "duration", sortable: false },
        { text: "Budget(GH₵)", value: "budget", sortable: false },
        { text: "Sites", value: "sites" },
        { text: "Actions", value: "actions" },
      ],

      showCampaignDialog: false,
      currentCampaign: false,
    };
  },

  components: { CampaignItem },

  methods: {
    ...mapMutations({ setCurrentCampaign: "campaigns/setCurrentCampaign" }),

    ...mapActions({
      fetchCampaign: "campaigns/fetchCampaign",
    }),

    async fetchCampaigns() {
      this.loading = true;
      this.allCampaigns = await fetchAllCampaigns();
      this.loading = false;
    },

    getOrg(planner) {
      return planner.User.organization;
    },
    getPlanner(planner) {
      return planner.User.plannerName;
    },
    parseNumber(amount) {
      if (amount) {
        return new Intl.NumberFormat().format(amount);
      } else {
        return 0;
      }
    },

    async selectCampaign(campaignId) {
      this.currentCampaign = await this.fetchCampaign(campaignId);

      this.setCurrentCampaign(this.currentCampaign);

      this.$emit("select-campaign");
    },
  },

  async created() {
    await this.fetchCampaigns();
  },
};
</script>

<style scoped>
.v-card {
  font-family: Manrope, Arial, sans-serif;
  box-shadow: 10px 10px 8px -6px rgba(225, 218, 218, 0.919) !important;
  -webkit-box-shadow: 10px 10px 8px -6px rgba(248, 243, 243, 0.931) !important;
  -moz-box-shadow: 10px 10px 8px -6px rgba(247, 238, 238, 0.939) !important;
  border-right: 1px solid white !important;
  border-top: 0.4px solid rgb(113, 111, 111) !important;
  border-left: 0.4px solid rgb(113, 111, 111) !important;
  border-bottom: 1px solid white !important;
}
#formatBudget {
  background: rgba(32, 201, 172, 0.1);
  height: 48px !important;
  color: #20c9ac;
  padding: 5px 14px;
}
</style>
