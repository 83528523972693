<template>
  <section class="source-sans-pro">
    <div class="hero">
      <video controls width="100%" autoplay v-if="play">
        <source :src="src" type="video/mp4" />
      </video>
      <div class="thumb" v-if="!play" @click="playVideo">
        <img src="/images/redesign/Play.png" alt="Play" class="thumb-play" />
        <img
          src="/images/redesign/video-thumb.png"
          class="thumb-main"
          alt="Abonten Video thumb"
        />
      </div>
    </div>

    <GradientStrip></GradientStrip>

    <div class="book-demo">
      <img src="/images/redesign/pattern.png" alt="Abonten Pattern" />
      <div class="btn-wrapper">
        <v-btn
          rounded
          class="text-none demo-btn"
          elevation="2"
          to="/book-demo"
          x-large
          >Book A Demo</v-btn
        >
      </div>
    </div>
  </section>
</template>

<script>
import GradientStrip from "@/components/misc/GradientStrip.vue";

export default {
  components: { GradientStrip },
  data() {
    return {
      play: false,
      src: "/images/redesign/abonten_explainer.mp4",
    };
  },
  methods: {
    playVideo() {
      this.play = true;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.hero {
  position: relative;
  height: 720px;
  width: 100%;
}

.hero video {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 576px) {
  .hero {
    height: 480px;
  }
}

.book-demo {
  background-color: #f9f9f9;
  position: relative;
}
@media (prefers-color-scheme: dark) {
  .book-demo {
    background-color: rgb(30, 32, 33) !important;
  }
}

.book-demo img {
  object-fit: cover;
  width: 100%;
  height: 320px;
}

.thumb {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.thumb-main {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.thumb-play {
  object-fit: cover;
  width: 100px;
  height: 100px;
  z-index: 10;
}

.book-demo .btn-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo-btn {
  background: #262261 !important;
  color: #fff !important;
}
</style>
