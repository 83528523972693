<template>
  <v-card width="100%">
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-data-table
      :items="currentCampaign.Candidates"
      :headers="headers"
      sort-by="name"
      :style="{
        'background-color': $vuetify.theme.themes.dark.background,
        color: $vuetify.theme.themes.dark.text,
      }"
      dark
      class="elevation-1 pa-3"
      :loading="!currentCampaign.Candidates.length"
      loading-text="Fetching Billboards... Please wait"
    >
      <template v-slot:[`item.number`]="{ _, index }">
        {{ _ }} {{ index + 1 }}
      </template>

      <template v-slot:[`item.images`]="{ item }">
        <div class="p-2 images">
          <div v-viewer>
            <img
              class="image"
              v-for="img in item.Billboard.Images"
              :key="img.id"
              :src="img.path"
              alt="Billboard Image"
              :lazy-src="img.path"
              loading="lazy"
            />
          </div>
        </div>
      </template>

      <template v-slot:[`item.rate_card`]="{ item }">
        {{ new Intl.NumberFormat().format(item.Billboard.rate_card) }}
      </template>

      <template v-slot:[`item.total_rate_card`]="{ item }">
        {{ new Intl.NumberFormat().format(item.total_rate_card) }}
      </template>

      <template v-slot:[`item.negotiated_rate`]="{ item }">
        {{ new Intl.NumberFormat().format(item.negotiated_net_rate) }}
      </template>

      <template v-slot:[`item.address`]="{ item }">
        {{ item.Billboard.address }}
      </template>

      <template v-slot:[`item.lighting`]="{ item }">
        {{ item.Billboard.lighting }}
      </template>

      <template v-slot:[`item.faces`]="{ item }">
        {{ item.Billboard.faces }}
      </template>

      <template v-slot:[`item.dimensions`]="{ item }">
        {{ item.Billboard.length }} * {{ item.Billboard.width }}
      </template>

      <template v-slot:[`item.oppContact`]="{ item }">
        {{ new Intl.NumberFormat().format(item.oppContact) }}
      </template>

      <template v-slot:[`item.candidateAvailable`]="{ item }">
        <v-chip text-color="white" :color="getAvailableColour(item.status)">
          {{ isAvailable(item.status) }}
        </v-chip>
      </template>

      <template v-slot:[`item.approved`]="{ item }">
        <v-chip text-color="white" :color="getAvailableColour(item.approval)">
          {{ isAvailable(item.approval) }}
        </v-chip>
      </template>

      <template v-slot:[`item.candidateConfirmation`]="{ item }">
        <v-chip
          text-color="white"
          :color="getAvailableColour(item.availability_confirmation)"
        >
          {{ supConfirmation(item.availability_confirmation) }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          icon
          @click="(candidateDialog = true), (currentCandidate = item)"
        >
          <v-icon color="secondary" title="More Information">mdi-eye</v-icon>
        </v-btn>

        <v-btn v-if="viewOnCampaignEdit" icon
          ><v-icon
            color="success"
            title="Edit"
            @click.stop="(currentCandidate = item), show($event)"
            >mdi-pencil</v-icon
          ></v-btn
        >
      </template>
    </v-data-table>

    <v-dialog v-model="reminderDialog" max-width="500px">
      <v-card height="200px">
        <v-progress-linear
          color="deep-purple accent-4"
          :active="loading"
          :indeterminate="loading"
        ></v-progress-linear>
        <v-card-text class="mx-auto p-4">
          <p>
            The owner of the billboard will receive an email requesting them to
            confirm the availability of this billboard.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="candidateDialog" max-width="500">
      <v-card
        class="px-2 mx-auto"
        :color="$vuetify.theme.themes.dark.background"
      >
        <v-toolbar
          elevation="0"
          :color="$vuetify.theme.themes.dark.background"
          class="white--text mb-2"
        >
          <v-spacer>
            <v-toolbar-title> Billboard Comments</v-toolbar-title></v-spacer
          >
          <v-btn icon @click="candidateDialog = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div v-if="currentCandidate?.Comments?.length">
            <div v-for="comment in currentCandidate.Comments" :key="comment.id">
              <p class="white--text">
                <span class="purple--text"
                  >{{ new Date(comment.createdAt).toDateString() }} :
                </span>
                {{ comment.comment }}
              </p>
            </div>
          </div>
          <div v-else>
            <p class="white--text">Billboard does not have comments</p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-menu
      close-on-click
      origin="center center"
      transition="scale-transition"
      v-model="showEditNegotiatedRateMenu"
      offset-y
      :position-x="menuX"
      :position-y="menuY"
      dark
      :close-on-content-click="false"
    >
      <v-card>
        <v-card-subtitle>Edit Negotiated Rate</v-card-subtitle>
        <v-card-text>
          <v-text-field
            type="number"
            min="0"
            suffix="GH₵"
            dark
            label="Negotiated Price"
            :rules="rules.genRule"
            v-model.trim="currentCandidate.negotiated_net_rate"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="showEditNegotiatedRateMenu = false"
            >Cancel
          </v-btn>

          <v-btn color="success" @click="patchCandidate()">Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <alert></alert>
  </v-card>
</template>

<script>
import { updateCandidate } from "@/helpers/candidate";

export default {
  data() {
    return {
      reminderDialog: false,
      loading: false,
      headers: [
        { text: "#", align: "start", sortable: false, value: "number" },
        { text: "Image", sortable: false, value: "images" },
        { text: "Price(GH₵)", value: "rate_card" },
        { text: "Total Price(GH₵)", value: "total_rate_card" },
        { text: "Negotiated Rate(GH₵)", value: "negotiated_rate" },
        { text: "Address", value: "address" },
        { text: "Available", value: "candidateAvailable", sortable: false },
        {
          text: "Supplier Confirmation",
          value: "candidateConfirmation",
          sortable: false,
        },
        {
          text: "Approved",
          value: "approved",
          sortable: false,
        },
        { text: "Lighting", value: "lighting" },
        { text: "Faces", value: "faces" },
        { text: "Dimensions (Metres)", value: "dimensions", sortable: false },
        { text: "Potential Reach", value: "oppContact" },
        { text: "Actions", value: "actions" },
      ],

      candidateDialog: false,
      currentCandidate: {
        id: null,
        negotiated_net_rate: null,
      },

      showEditNegotiatedRateMenu: false,
      menuX: 0,
      menuY: 0,

      rules: {
        genRule: [(v) => !!v || "Please fill this."],
      },
    };
  },

  props: {
    currentCampaign: {
      type: Object,
      default: () => ({
        id: null,
        budget: null,
        duration: null,
        start_date: null,
        end_date: null,
        name: null,
        Candidates: [
          {
            negotiated_net_rate: null,
            status: null,
            availability_confirmation: null,
            Comments: [],
            Billboard: {
              id: null,
              latitude: null,
              longitude: null,
              Images: [{ path: null }],
              MediaOwner: {
                User: {
                  organization: null,
                  first_name: null,
                  last_name: null,
                },
              },
            },
          },
        ],
        PlanComments: [],
        Planner: {
          User: {
            organization: null,
            first_name: null,
            last_name: null,
          },
        },
        Brief: {
          name: null,
          budget: null,
          BrandOwner: {
            Brand: {
              name: null,
            },
          },
        },
      }),
      required: true,
    },
    viewOnCampaignEdit: {
      type: Boolean,
      required: false,
    },
  },

  methods: {
    getAvailableColour(status) {
      if (status) {
        return "green";
      } else {
        return "var(--v-primary-lighten2)";
      }
    },
    isAvailable(status) {
      if (status) return "Yes";
      return "No";
    },
    supConfirmation(status) {
      if (status) {
        return "Confirmed";
      } else if (status === false) {
        return "Confirmed";
      } else if (status === null) {
        return "Not Confirmed";
      }
    },
    formatDate(dateStr) {
      if (dateStr) {
        const date = new Date(dateStr);
        return date.toDateString();
      } else {
        return "Not Available";
      }
    },

    show(event) {
      this.showEditNegotiatedRateMenu = true;

      this.menuX = event.clientX;
      this.menuY = event.clientY;

      this.$nextTick(() => (this.showEditNegotiatedRateMenu = true));
    },

    async patchCandidate() {
      let payload = {
        id: this.currentCandidate.id,
        negotiated_net_rate: this.currentCandidate.negotiated_net_rate,
      };

      this.loading = true;
      await updateCandidate(payload);
      this.loading = false;
      this.showEditNegotiatedRateMenu = false;
    },
  },
};
</script>

<style scoped>
.images {
  /* overflow-x: scroll; */
  overflow: hidden;
  max-width: 5em;
}
.image {
  height: 50px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
  /* position: absolute; */
}
.image:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}

.v-btn:not(.v-btn--round).v-size--small {
  text-transform: none;
}
</style>
