import Store from "../../store";
import { fetchPop } from "../billboards";

import { filterScores, totalBbScore } from "./filterScores";

async function getBBScore(currentBillboard) {
  const areaPopulation = await fetchPop({
    long: currentBillboard.longitude,
    lat: currentBillboard.latitude,
  });

  Store.commit("billboards/setPopulation", areaPopulation);

  const lightSc =
    filterScores["lighting"][currentBillboard?.lighting?.toLowerCase()]
      ?.score || 0;

  const hgtSc =
    filterScores["height"][currentBillboard?.height?.toLowerCase()]?.score || 0;

  const strnSc =
    filterScores["site_run_up"](currentBillboard?.site_run_up)?.score || 0;

  const ortnSc =
    filterScores["orientation"][currentBillboard?.orientation?.toLowerCase()]
      ?.score || 0;

  const clutSc =
    filterScores["clutter"][currentBillboard?.clutter?.toLowerCase()]?.score ||
    0;

  const currentBbScore = lightSc + hgtSc + strnSc + ortnSc + clutSc;
  const visibilityAdj = `${Math.round((100 * currentBbScore) / totalBbScore)}%`;

  const oppContact = Math.round(
    (currentBbScore / totalBbScore) * areaPopulation.totalPop
  );

  return { visibilityAdj, oppContact };
}
export { getBBScore };
