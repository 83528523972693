export default {
  setBillboards(state, payLoad) {
    return (state.billboards = payLoad);
  },
  setPopulation(state, payLoad) {
    return (state.population = payLoad);
  },
  setBBToCart(state, payLoad) {
    return (state.bbCart = payLoad);
  },
  setSearchResults(state, payLoad) {
    return (state.search = payLoad);
  },
  setBrands(state, payLoad) {
    return (state.brands = payLoad);
  },
  setSuppliers(state, payLoad) {
    return (state.suppliers = payLoad);
  },
  setCandidates(state, payLoad) {
    return (state.candidates = payLoad);
  },
};
