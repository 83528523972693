<template>
  <v-container fluid fill-height>
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-row>
      <p class="white--text">Showing {{ billboards.length }} Billboards</p>
    </v-row>

    <GmapMap
      ref="googleMap"
      :center="center"
      :zoom="zoom"
      class="map"
      :options="mapOptions"
    >
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
        <ownerbb-feature :currentBillboard="currentBillboard"></ownerbb-feature>
      </gmap-info-window>

      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="poiInfoWinOpen"
        @closeclick="poiInfoWinOpen = false"
      >
        <v-card v-if="currentPoi" elevation="0" class="mx-auto" max-width="350">
          <v-card-text>
            <p>
              Name: <strong>{{ currentPoi.name || "Not Known" }}</strong>
            </p>
          </v-card-text>
        </v-card>
      </gmap-info-window>

      <div class="mx-auto" id="legend" ref="legend">
        <legend-area @enable-poi="addPoi">
          <template slot="allBillboards">
            <div class="d-flex justify-space-between align-center">
              <img
                height="20px"
                class="pa-0 mt-2"
                src="images/billboardColored.png"
                alt="billboard"
              />
              Billboards
              <input
                id="billboardChecked"
                type="checkbox"
                v-model="bbChecked"
                @change="addBBs"
              />
            </div>
          </template>
        </legend-area>
      </div>

      <div id="mapStyle">
        <v-radio-group v-model="mapStyle">
          <v-radio label="Dark" value="dark" color="black"></v-radio>
          <v-radio label="Light" value="light" color="grey"></v-radio>
        </v-radio-group>
      </div>

      <div id="zoomAddress">
        <gmap-autocomplete
          placeholder="Enter Address"
          class="autoComplete v-text-field v-text-field-outlined"
          @place_changed="setPlace"
          id="address"
          :options="autoCompleteBounds"
        >
        </gmap-autocomplete>
      </div>
    </GmapMap>
  </v-container>
</template>

<script>
import { gmapApi } from "gmap-vue";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import { mapGetters, mapActions } from "vuex";

import LegendArea from "../map/LegendArea.vue";
import OwnerbbFeature from "../media_owner/OwnerbbFeature.vue";
import { styles } from "@/components/map/mapstyle.js";

export default {
  data() {
    return {
      loading: false,
      map: null,
      api: null,
      apiLoaded: false,

      xmin: null,
      ymin: null,
      xmax: null,
      ymax: null,
      bounds: null,

      // map
      mapOptions: {
        zoomControl: true,
        zoomControlOptions: { position: 9 },
        mapTypeControl: true,
        mapTypeControlOptions: { position: 11 },
        scaleControl: false,
        streetViewControl: true,
        streetViewControlOptions: { position: 9 },
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        styles: styles.dark,
      },
      zoom: 9,
      center: { lat: 5.623028, lng: -0.176527 },

      // popup window
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },

      poiInfoWinOpen: false,

      // tabs for infowindow
      tab: null,

      legend: null,
      mapStyleToggle: null,
      mapStyle: "dark",
      billboards: [],
      bbMarkers: null,
      bbClusterer: [],
      bbChecked: true,

      // selected bb
      currentBillboard: {},
      currentBbScore: 0,
      visibilityAdj: 0,
      totalBbScore: 17,
      oppContact: 0,

      // pois
      poiBoundsChanged: {},
      poiClusterer: [],
      poiMarkers: {},
      pois: [],
      poiName: "",
      currentPoi: null,

      // spidifier options
      spiderfier: null,
      spiderfierOptions: {
        basicFormatEvents: true,
        circleFootSeparation: 40,
        keepSpiderfied: true,
        markersWontHide: true,
        markersWontMove: true,
      },

      zoomAddress: null,
      autoCompleteBounds: {
        bounds: {
          north: 11.234926,
          south: 4.737842,
          east: 1.201117,
          west: -3.265621,
        },
        strictBounds: true,
      },
    };
  },

  computed: {
    ...mapGetters({
      getPopulation: "billboards/getPopulation",
      getPoi: "pois/getPoi",
      getPois: "pois/getPois",
      getSearchResults: "billboards/getSearchResults",
      getBillboards: "billboards/getBillboards",
    }),

    google: gmapApi,
  },

  components: {
    LegendArea,
    OwnerbbFeature,
  },

  watch: {
    getSearchResults: {
      // immediate: true,
      handler(val) {
        if (!val) return;
        this.billboards = val;
        this.plotBBs();
      },
    },
    mapStyle: {
      handler(val) {
        this.map.setOptions({ styles: styles[val] });
      },
    },
  },

  methods: {
    ...mapActions({
      fetchPop: "billboards/fetchPop",
      fetchPoiByBbox: "pois/fetchPoiByBbox",
      fetchAllBbs: "billboards/fetchAllBbs",
    }),

    async setPlace(place) {
      if (!place.geometry) return;

      this.map.setZoom(15);
      this.map.setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    },

    getBounds() {
      this.bounds = this.map.getBounds();
      const ne = this.bounds.getNorthEast().toJSON(); // LatLng of the north-east corner
      const sw = this.bounds.getSouthWest().toJSON(); // LatLng of the south-west corder

      this.xmin = sw.lng;
      this.ymin = sw.lat;
      this.xmax = ne.lng;
      this.ymax = ne.lat;
    },

    addBBs() {
      if (this.bbChecked) {
        this.plotBBs();
      } else {
        this.bbClusterer.removeMarkers(this.bbMarkers);
      }
    },

    plotBBs() {
      this.loading = true;
      // if no bbs pass
      if (!this.billboards.length) {
        this.bbClusterer.clearMarkers();
        return;
      }
      // remove bbs
      this.bbClusterer.clearMarkers();

      // bbs from search query will drive the bounds of map
      this.bounds = new this.google.maps.LatLngBounds();

      this.bbMarkers = this.billboards.map((bb) => {
        let latlng = new this.google.maps.LatLng(
          parseFloat(bb.latitude),
          parseFloat(bb.longitude)
        );
        this.bounds.extend(latlng);

        let bbMarker = new this.google.maps.Marker({
          position: latlng,
          icon: {
            url: "/images/billboardColored.png",
            scaledSize: new this.google.maps.Size(25, 25),
          },
        });

        // set listener
        this.google.maps.event.addListener(bbMarker, "click", async () => {
          this.currentBillboard = bb;

          // position of the Popup window
          this.infoWindowPos = {
            lat: this.currentBillboard.latitude,
            lng: this.currentBillboard.longitude,
          };

          // get population around the area
          await this.fetchPop({
            lat: this.currentBillboard.latitude,
            long: this.currentBillboard.longitude,
          });

          // open info window
          this.infoWinOpen = true;
        });

        return bbMarker;
      });
      this.bbClusterer.addMarkers(this.bbMarkers);
      this.map.fitBounds(this.bounds);
      this.map.panToBounds(this.bounds);
      this.loading = false;
    },

    async addPoi(checkedPois) {
      // poi has been unchecked
      // this.pois has all the previous pois,
      // loop over this.pois and check if poi is in enabled
      this.pois.forEach((poi) => {
        if (checkedPois.indexOf(poi) == -1) {
          // is no longer checked
          // remove its markers from the clusterer
          // remove it from the poimakers
          if (this.poiMarkers[poi]?.length) {
            this.poiClusterer.removeMarkers(this.poiMarkers[poi]);
          }
          delete this.poiMarkers[poi];
          this.google.maps.event.removeListener(this.poiBoundsChanged[poi]);
        }
      });
      this.pois = checkedPois;

      for (const poi of checkedPois) {
        this.getBounds();
        await this.fetchPoiByBbox({
          xmin: this.xmin,
          ymin: this.ymin,
          xmax: this.xmax,
          ymax: this.ymax,
          poiType: poi,
        });
        this.plotPois();
      }
    },

    plotPois() {
      //
      this.pois.forEach((poi) => {
        // if poimarkers is already enabled then just pass
        if (poi in this.poiMarkers) return;

        // pois have markers within the bounds
        if (!this.getPoi(poi)?.length) return;
        let markers = this.getPoi(poi).map((el) => {
          // the x and y of the marker
          el.geojson = JSON.parse(el.geojson);
          let latitude = el.geojson.coordinates[0][1];
          let longitude = el.geojson.coordinates[0][0];

          let latlng = new this.google.maps.LatLng(latitude, longitude);
          let marker = new this.google.maps.Marker({
            position: latlng,

            icon: {
              url: `/images/${poi}.png`,
              scaledSize: new this.google.maps.Size(17, 17),
            },
            optimized: false,
          });

          // on click listener show name
          this.google.maps.event.addListener(marker, "click", async () => {
            this.currentPoi = el;

            // position of the Popup window
            this.infoWindowPos = {
              lat: latitude,
              lng: longitude,
            };

            // open info window
            this.poiInfoWinOpen = true;
          });

          return marker;
        });

        this.poiMarkers[poi] = markers;

        // add to map
        this.poiClusterer.addMarkers(this.poiMarkers[poi]);
      });
    },
  },

  async mounted() {
    // At this point, the child GmapMap has been mounted, but
    // its map has not been initialized.
    // Therefore we need to write googleMap.$mapPromise.then(() => ...)
    this.map = await this.$refs.googleMap.$mapPromise;

    // lazy loading
    // google maps api library is loaded asynchronously , thus is not immediately available even when the page is loaded.
    this.api = await this.$gmapApiPromiseLazy();
    this.apiLoaded = !!this.api;

    this.legend = document.querySelector("#legend");

    this.map.controls[this.google.maps.ControlPosition.RIGHT_TOP].push(
      this.legend
    );

    this.mapStyleToggle = document.querySelector("#mapStyle");

    this.map.controls[this.google.maps.ControlPosition.LEFT_TOP].push(
      this.mapStyleToggle
    );

    this.zoomAddress = document.querySelector("#zoomAddress");
    this.map.controls[this.google.maps.ControlPosition.TOP_CENTER].push(
      this.zoomAddress
    );

    // initialize points of interest clusterer
    this.poiClusterer = new MarkerClusterer({
      map: this.map,
      markers: [],
      averageCenter: true,
    });

    // Billboard cluster
    this.bbClusterer = new MarkerClusterer({
      map: this.map,
      markers: [],
      averageCenter: true,
    });

    if (!this.getSearchResults.length) {
      await this.fetchAllBbs();
      this.billboards = this.getBillboards;
    } else {
      this.billboards = this.getSearchResults;
    }

    this.plotBBs();
  },
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 80vh;
}
#infoWindow {
  max-width: 350px;
  margin: 10px;
}
.images {
  display: flex;
  justify-content: baseline;
  align-items: center;
  margin-bottom: 10px;
  transition: all 0.2s;
}
.image {
  cursor: pointer;
  margin: 5px;
}
.image:hover {
  transform: scale(1.07);
  transition: all 0.2s;
}
#infoTab {
  box-shadow: 0 0 15px rgb(0 0 0 20%);
}

#legend {
  margin-top: 10px;
}
.v-card__text {
  font-family: Manrope, Arial, sans-serif;
  padding: 10px;
}
#mapStyle {
  background: #fff176;
  padding: 0 10px;
  margin-top: 10px;
}

#address {
  width: 200px;
  padding: 0 5px 0 10px;
  height: 40px !important;
  background: white;
}
</style>
