<template>
  <v-card-text class="features">
    <div>
      Address:
      <b>
        {{ currentBillboard.address || "Not Available" }}
      </b>
    </div>

    <div>
      Price:
      <b> {{ rate_card }} </b>
    </div>

    <div>
      Lighting:
      <b>
        {{ currentBillboard.lighting || "Not Available" }}
      </b>
    </div>

    <div>
      Size:
      <b> {{ currentBillboard.length }} * {{ currentBillboard.width }} m</b>
    </div>

    <div>
      Height: <b> {{ currentBillboard.height || "Not Available" }}</b>
    </div>

    <div>
      Clutter: <b> {{ currentBillboard.clutter || "Not Available" }}</b>
    </div>

    <div>
      Brand: <b> {{ currentBillboard.brand_name || "Not Available" }}</b>
    </div>
    <div>
      Visibility Adjustment: <b>{{ visibilityAdj }}</b>
    </div>
    <div>
      Potential Reach: <b>{{ opportunityContact }} </b>
    </div>
  </v-card-text>
</template>

<script>
export default {
  props: {
    currentBillboard: { type: Object, required: true },
    oppContact: { type: Number, required: true },
    visibilityAdj: { type: String, required: true },
  },
  computed: {
    rate_card() {
      return this.currentBillboard.rate_card
        ? `${this.parseNumber(this.currentBillboard.rate_card)} GH₵`
        : "Not Available";
    },
    opportunityContact() {
      return this.oppContact ? `${this.oppContact} People` : "Not Available";
    },
  },

  methods: {
    parseNumber(amount) {
      return new Intl.NumberFormat().format(amount);
    },
  },
};
</script>

<style scoped>
.features > div {
  display: flex;
  justify-content: space-between;
  color: black;
  font-size: 13px;
}
</style>
