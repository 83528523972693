<template>
  <v-card
    elevation="0"
    :color="$vuetify.theme.themes.dark.background"
    class="white--text"
  >
    <v-card-text>
      <div>
        <p class="v-card__text__wrapper">
          {{ greeting }},
          <span v-if="getCurrentUser">{{ getCurrentUser.first_name }}</span>
        </p>
        <p class="v-card__text__news">
          You have {{ notifications }} New
          <span class="v-card__text__news--notification">Notification </span>.
          There are {{ billboards }} billboard near you.
        </p>
      </div>

      <div>
        <p class="v-card__text__date__lower">{{ day }}</p>
        <p class="v-card__text__date__lower">
          {{ date }}, {{ month }} {{ year }}.
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    dateObj: new Date(),
  }),

  computed: {
    ...mapGetters(["getCurrentUser"]),

    notifications() {
      return 0;
    },
    billboards() {
      return 0;
    },
    day() {
      return this.dateObj.toLocaleString("en-GB", { weekday: "long" });
    },
    date() {
      return this.dateObj.getDate();
    },
    month() {
      return this.dateObj.toLocaleString("en-GB", { month: "long" });
    },
    year() {
      return this.dateObj.getFullYear();
    },
    greeting() {
      const currHrs = this.dateObj.getHours();

      return `Good ${
        (currHrs < 12 && "Morning") ||
        (currHrs < 18 && "Afternoon") ||
        "Evening"
      }`;
    },
  },
  methods: {
    ...mapActions(["fetchCurrentUser"]),
  },
  async mounted() {
    await this.fetchCurrentUser();
  },
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0 !important;
}

.v-card {
  font-family: Manrope, Arial, sans-serif;
  text-align: right;
  padding: 10px 20px;
  font-weight: 500;
  margin: 0 !important;
}

.v-card__text {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0;
}

.v-card__text > div {
  display: flex;
  flex-direction: column;
}

.v-card__text__wrapper {
  font-weight: 600;
  font-size: 26px;
  line-height: 48px;
  color: #dddbe3;
  text-align: start;
}

.v-card__text__news {
  color: #aea9b8;
}

.v-card__text__news--notification {
  color: rgb(15, 151, 214);
}

.v-card__text__date__lower {
  color: #e5e1f1;
}
</style>
