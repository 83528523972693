<template>
  <v-container class="not-found ma-4" fluid fill-height>
    <v-row align="center" justify="center">
      <v-card
        height="400"
        color="white"
        width="500"
        class="text-center elevation-9"
      >
        <div class="text-center">
          <div class="text-h1 font-weight-black pt-2">403</div>
          <div class="text-h2 mb--3">Oops!! Access Forbidden.</div>
        </div>
        <div class="grey--text lighten-5">
          <br />
          The page you are trying to access has restricted access.
          <br />
          Try going back to<v-btn text link color="primary" :to="prev"
            >the previous page
          </v-btn>
          and repeating your action. Or, contact the admin for support.
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    prev() {
      return "/insights";
    },
  },
};
</script>
