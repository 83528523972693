<template>
  <v-container class="pt-0" fluid>
    <div class="pt-0">
      <v-container
        class="pt-0 mt-0 mb-3 bbRow"
        v-for="bb in billboards"
        :key="bb.id"
      >
        <v-overlay :value="loading">
          <v-progress-circular
            color="secondary"
            size="70"
            width="7"
            indeterminate
          ></v-progress-circular>
        </v-overlay>

        <v-row dense class="pa-2" align="center">
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <div class="p-2">
              <div v-viewer class="images">
                <img
                  class="image"
                  v-for="img in bb.Images"
                  :key="img.id"
                  :src="img.path"
                  alt="Billboard Image"
                  :lazy-src="img.path"
                  loading="lazy"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
            <v-row
              dense
              justify="center"
              align="center"
              class="grey--text text--lighten-2"
            >
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <p>
                  <span class="search--cat--title">Dimensions:</span>
                  {{ bb.length }} * {{ bb.width }} Metres
                </p>
                <p>
                  <span class="search--cat--title">Height: </span
                  >{{ bb.height }}
                </p>
                <p>
                  <span class="search--cat--title">Price: </span
                  >{{ bb.rate_card }} GH₵
                </p>
                <p>
                  <span class="search--cat--title">Lighting: </span
                  >{{ bb.lighting }}
                </p>
                <p>
                  <span class="search--cat--title">Brand: </span
                  >{{ bb.brand_name }}
                </p>
                <p>
                  <span class="search--cat--title">Medium: </span
                  >{{ bb.medium }}
                </p>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <p>
                  <span class="search--cat--title">Side of Road : </span
                  >{{ bb.side_of_road }}
                </p>
                <p>
                  <span class="search--cat--title">Orientation: </span
                  >{{ bb.orientation }}
                </p>
                <p>
                  <span class="search--cat--title">Clutter: </span
                  >{{ bb.clutter }}
                </p>
                <p>
                  <span class="search--cat--title">Site Run Up: </span
                  >{{ bb.site_run_up }}m
                </p>
                <p>
                  <span class="search--cat--title">Faces: </span>{{ bb.faces }}
                </p>
                <p>
                  <span class="search--cat--title">Address: </span
                  >{{ bb.address }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-btn color="secondary" @click="addToCart(bb)"> Book Now </v-btn>
        </v-row>
      </v-container>

      <v-dialog max-width="450" v-model="showCartDialog">
        <v-card
          class="white--text"
          :color="$vuetify.theme.themes.dark.background"
        >
          <v-card-title></v-card-title>
          <v-card-text>Billboard has been added to cart.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn width="200" @click="showCartDialog = false" outlined>
              Continue Browsing</v-btn
            >
            <v-btn color="secondary" width="100" to="/cart">Checkout</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EventBus from "@/components/events/EventBus";

export default {
  data() {
    return {
      showCartDialog: false,
      billboards: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getSearchResults: "billboards/getSearchResults",
    }),
  },
  methods: {
    ...mapActions({
      addBbToCart: "billboards/addBbToCart",
      fetchBBPaginated: "billboards/fetchBBPaginated",
    }),

    async addToCart(bb) {
      await this.addBbToCart(bb.id);
      this.showCartDialog = true;

      EventBus.$emit("bbPrice", bb.rate_card);
    },
  },
  watch: {
    getSearchResults: {
      handler(val) {
        if (!val) return;
        this.loading = true;
        this.billboards = val;
        console.log(this.billboards);
        this.loading = false;
      },
    },
  },
  async mounted() {
    this.loading = true;

    if (!this.getSearchResults.length) {
      const page = Math.floor(Math.random() * 80);
      let results = await this.fetchBBPaginated(page);
      this.billboards = results.billboards;
    } else {
      this.billboards = this.getSearchResults;
    }
    this.loading = false;
  },
};
</script>

<style scoped>
.bbRow {
  width: 100%;
  border-top: 2px solid #b39184;
}

.image {
  max-width: 200px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}

.image:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}

.search--cat--title {
  color: white;
  font-size: large;
}
</style>
