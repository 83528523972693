import { Ability, AbilityBuilder } from "@casl/ability";

export default function defineAbilityFor(permissions) {
  const { can, build } = new AbilityBuilder(Ability);
  for (const perm of permissions) {
    if (perm.includes("read")) {
      let resource = perm.split("_")[1];
      can("read", resource);
    }
  }
  return build();
}
