<template>
  <v-form v-model="valid" ref="form">
    <AuthLayout cardWidth="8">
      <v-row class="justify-center my-8">
        <v-col lg="10">
          <h4 class="mb-4">Create an Account</h4>
          <v-row>
            <v-col cols="12" sm="6">
              <BeautifulInput
                label="First Name"
                placeholder="Enter First Name"
                @input="setFirstName"
                :rules="rules.fieldRules"
                required
              ></BeautifulInput>
            </v-col>
            <v-col sm="6">
              <BeautifulInput
                label="Last Name"
                placeholder="Enter Last Name"
                @input="setLastName"
                :rules="rules.fieldRules"
                required
              ></BeautifulInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <BeautifulInput
                label="Job Title"
                placeholder="Enter Job Title"
                @input="setJobTitle"
                :rules="rules.fieldRules"
                required
              >
              </BeautifulInput>
            </v-col>
            <v-col sm="6">
              <BeautifulInput
                label="Email"
                placeholder="Enter Email"
                @input="setEmail"
                :rules="rules.emailRules"
                required
              ></BeautifulInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <BeautifulInput
                label="Organisation"
                placeholder="Enter Organisation"
                @input="setOrg"
                :rules="rules.fieldRules"
                required
              >
              </BeautifulInput>
            </v-col>
            <v-col>
              <BeautifulInput
                label="Occupation"
                placeholder="Enter Occupation"
                @input="setOccupation"
                :rules="rules.fieldRules"
                :items="occupations"
                required
                type="select"
              >
              </BeautifulInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <BeautifulInput
                label="Password"
                placeholder="Enter Password"
                icon="mdi-lock-open-outline"
                @input="setPassword"
                :rules="[...rules.passwordRules]"
                :password="true"
                required
              >
              </BeautifulInput>
            </v-col>
            <v-col sm="6">
              <BeautifulInput
                label="Confirm Password"
                placeholder="Confirm Password"
                icon="mdi-lock-open-outline"
                @input="setConfirmPassword"
                :rules="[...rules.confirmPasswordRules, passwordsMatch]"
                :password="true"
              >
              </BeautifulInput>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <template v-slot:bottom>
        <p class="text-center mt-6">
          <v-btn
            rounded
            class="text-none"
            @click="submitForm"
            :disabled="loading"
            :loading="loading"
            >Register</v-btn
          >
        </p>
        <p class="white--text text-center" style="font-weight: 300">
          Already a member?
          <a style="font-weight: 400" href="/login">Sign in</a>
        </p>
      </template>
    </AuthLayout>
  </v-form>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("auth");
import AuthLayout from "@/components/misc/AuthLayout.vue";
import BeautifulInput from "@/components/misc/BeautifulInput.vue";

export default {
  components: { AuthLayout, BeautifulInput },
  data() {
    return {
      show: false,
      loading: false,
      valid: false,
      rules: {
        fieldRules: [(v) => !!v || "Field is required"],
        passwordRules: [
          (v) => !!v || "Password cannot be Empty",
          (v) => (v && /[A-Z]{1}/.test(v)) || "At least one capital latter",
          (v) =>
            (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",
        ],
        confirmPasswordRules: [
          (v) => !!v || "Password cannot be Empty",
          (v) => (v && /[A-Z]{1}/.test(v)) || "At least one capital latter",
          (v) =>
            (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",
        ],

        emailRules: [
          (v) => !!v || "E-mail cannot be Empty",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
      },

      first_name: null,
      last_name: null,
      designation: null,
      email: null,
      organization: null,
      occupation: null,
      password: null,
      confirmPassword: null,

      occupations: [
        { value: "marketer", text: "Marketer" },
        { value: "media_owner", text: "Media Owner" },
        { value: "media_planner", text: "Media Planner" },
        { value: "monitor", text: "Monitor" },
        { value: "other", text: "Other" },
      ],
    };
  },
  computed: {
    passwordsMatch() {
      return () =>
        this.password === this.confirmPassword || "Passwords do not Match";
    },
  },
  methods: {
    ...mapActions(["register"]),

    async submitForm() {
      this.$refs.form.validate();
      if (!this.valid) return;

      this.loading = true;

      // form is valid
      const newUser = await this.register({
        first_name: this.first_name,
        last_name: this.last_name,
        designation: this.designation,
        email: this.email,
        organization: this.organization,
        userType: this.occupation,
        password: this.password,
        subscription: this.subscription,
      });
      if (newUser) {
        this.loading = false;
        setTimeout(() => this.$router.push({ path: "/login" }), 3000);
      }
    },
    setFirstName(v) {
      this.first_name = String(v).trim();
    },
    setLastName(v) {
      this.last_name = String(v).trim();
    },
    setJobTitle(v) {
      this.designation = String(v).trim();
    },
    setEmail(v) {
      this.email = String(v).trim();
    },
    setOrg(v) {
      this.organization = String(v).trim();
    },
    setOccupation(v) {
      this.occupation = String(v).trim();
    },
    setPassword(v) {
      this.password = String(v).trim();
    },
    setConfirmPassword(v) {
      this.confirmPassword = String(v).trim();
    },
  },
};
</script>

<style scoped>
h4 {
  font-size: 36px;
  font-weight: 400;
  color: #fff;
}
</style>
