<template>
  <v-container fill-height fluid>
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-toolbar
      flat
      :color="$vuetify.theme.themes.dark.background"
      class="white--text mb-2"
    >
      <slot name="title">
        <v-toolbar-title>Create Campaign</v-toolbar-title>
      </slot>
    </v-toolbar>

    <v-row class="controller py-1">
      <div class="text-center">
        <v-dialog v-model="showBriefListDialog" width="70%">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="accent" v-bind="attrs" v-on="on">Select Brief</v-btn>
          </template>
          <v-card
            class="white--text"
            :color="$vuetify.theme.themes.dark.background"
          >
            <v-toolbar
              :color="$vuetify.theme.themes.dark.background"
              flat
              class="white--text mb-2"
            >
              <v-spacer></v-spacer>

              <v-btn icon @click="showBriefListDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <briefs-list
              :viewOnCampaignsDialog="true"
              @select-brief="closeDialog"
            ></briefs-list>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="closeDialog">Close</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div class="controller__options">
        <p class="white--text">VIEW:</p>

        <v-btn
          v-for="btn in buttons"
          elevation="0"
          :key="btn.name"
          @click="setComponent(btn.cmp)"
          :class="{ active: btn.cmp === currentComponent }"
        >
          <v-icon color="black">{{ btn.icon }}</v-icon>
          {{ btn.name }}
        </v-btn>
      </div>
    </v-row>

    <v-row
      class="pa-2 my-3 mx-auto white--text"
      justify="center"
      align="center"
      v-if="getBriefsCart"
      id="priceDriver"
    >
      <div>
        <v-row>
          <div>Brief Budget: {{ getBriefsCart.budget }} GH₵</div>
        </v-row>
        <v-row>
          <div>Total Billboards Prices: {{ totalBbsCost }} GH₵</div>
        </v-row>
        <v-row>Price Difference: {{ budgetDiff() }} GH₵</v-row>
      </div>
    </v-row>

    <v-row>
      <v-col cols="12" sm="3" lg="3" md="3" xl="3">
        <insights-filter :viewOnCampaigns="true"></insights-filter>
      </v-col>
      <v-col cols="12" sm="9" lg="9" md="9" xl="9">
        <keep-alive>
          <component :is="currentComponent" v-bind="toggleInsights"></component>
        </keep-alive>
      </v-col>
    </v-row>
    <alert></alert>
  </v-container>
</template>
<script>
import CampaignMap from "@/components/campaign/CampaignMap.vue";
import BriefsList from "@/components/briefs/BriefsList.vue";

import { mapGetters, mapActions } from "vuex";

import { handleHttpError } from "@/helpers/utils.js";
import EventBus from "@/components/events/EventBus";
import InsightsFilter from "../insights/InsightsFilter.vue";
import CampaignTable from "./CampaignTable.vue";

export default {
  data() {
    return {
      campaign: {
        id: null,
        budget: null,
        duration: null,
        start_date: null,
        end_date: null,
        name: null,
        Candidates: [
          {
            negotiated_net_rate: null,
            status: null,
            availability_confirmation: null,
            Comments: [],
            Billboard: {
              id: null,
              latitude: null,
              longitude: null,
              Images: [{ path: null }],
              MediaOwner: {
                User: {
                  organization: null,
                  first_name: null,
                  last_name: null,
                },
              },
            },
          },
        ],
        Planner: {
          User: {
            organization: null,
            first_name: null,
            last_name: null,
          },
        },
        brand: {
          name: null,
        },
      },

      loading: false,
      totalBbsCost: 0,
      showBriefListDialog: false,
      currentComponent: "CampaignMap",
      buttons: [
        { name: "Map", icon: "mdi-map-legend", cmp: "CampaignMap" },
        { name: "Table", icon: "mdi-table-large", cmp: "CampaignTable" },
      ],
    };
  },

  components: {
    CampaignMap,
    BriefsList,
    InsightsFilter,
    CampaignTable,
  },

  computed: {
    ...mapGetters({
      getBriefsCart: "briefs/getBriefsCart",
      getBbCart: "billboards/getBbCart",
    }),

    toggleInsights() {
      return this.currentComponent === "CampaignTable"
        ? { viewOnCampaignCreation: true }
        : { viewOnCampaignCreation: false };
    },
  },

  methods: {
    ...mapActions({
      fetchBBPaginated: "billboards/fetchBBPaginated",
      fetchBB: "billboards/fetchBB",
    }),

    setComponent(cmp) {
      this.currentComponent = cmp;
    },

    closeDialog() {
      this.showBriefListDialog = false;
    },

    totalBbsPrice(price) {
      let cost = parseInt(price);
      if (!cost) cost = 0;

      this.totalBbsCost += cost;
    },

    calculateTotalCandidateRate(
      startDateStr,
      endDateStr,
      billboardCostPerMonth
    ) {
      const startDate = new Date(startDateStr);
      const endDate = new Date(endDateStr);

      if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
        throw new Error(
          "Invalid date format. Please provide valid Date objects for startDate and endDate."
        );
      }

      const startYear = startDate.getFullYear();
      const startMonth = startDate.getMonth();
      const endYear = endDate.getFullYear();
      const endMonth = endDate.getMonth();

      const monthsInCampaign =
        (endYear - startYear) * 12 + (endMonth - startMonth) + 1;

      // Calculate the total cost
      const totalCost = billboardCostPerMonth * monthsInCampaign;

      return totalCost;
    },

    budgetDiff() {
      const briefBudg = parseInt(this.getBriefsCart.budget) || 0;

      const diff = briefBudg - this.totalBbsCost;

      if (Math.sign(diff) > 0) {
        return `${diff}`;
      } else {
        return `- ${diff}`;
      }
    },

    async getBillboards() {
      if (this.getBbCart) {
        for (let bbID of this.getBbCart) {
          let bb = await this.fetchBB(bbID);
          this.totalBbsPrice(bb.rate_card);
        }
      }
    },
  },

  watch: {
    showBriefListDialog(state) {
      // dialog is being closed and no brief has been selected
      if (!state && !this.getBriefsCart) {
        handleHttpError("No brief was selected");
      }
    },
  },

  async mounted() {
    EventBus.$on("bbPrice", (val) => {
      this.totalBbsPrice(val);
    });

    await this.getBillboards();
  },
};
</script>

<style scoped>
.container {
  background: #424242;
  padding: 0 1.5em;
}
.controller {
  display: flex;
  justify-content: space-between;
}

.controller__options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.controller__options p {
  margin: 0;
  padding-right: 10px;
}

.v-btn {
  background: rgba(143, 146, 161, 0.05);
  border-radius: 32px;
  margin: 0 10px;
  text-transform: none;
}
.active {
  background: #f68625 !important;
}

#priceDriver {
  font-family: "Manrope", Arial, sans-serif;
}
</style>
