<template>
  <v-container fluid>
    <v-card
      :color="$vuetify.theme.themes.dark.background"
      class="card-shadow white--text pa-5"
      width="95%"
      elevation="0"
    >
      <v-row align="center" justify="center">
        <div>
          <v-avatar size="200">
            <v-icon size="200"> mdi-account-circle </v-icon>
          </v-avatar>
        </div>
      </v-row>

      <v-divider></v-divider>
      <v-row justify="center" class="mt-3">
        <div>
          <p class="text-h4">
            {{ getUserDetails.first_name }} {{ getUserDetails.last_name }}
          </p>
          <p class="text-h5 textGray--text">
            {{ getUserDetails.designation }}
          </p>
          <p>
            {{ getUserDetails.email }}
          </p>
        </div>
      </v-row>
      <v-divider></v-divider>

      <v-row class="mt-3" justify="center">
        <v-col cols="12" md="6" sm="6" xl="6" lg="6">
          Organization: {{ getUserDetails.organization }}
        </v-col>
        <v-col cols="12" md="6" sm="6" xl="6" lg="6" class="secondary--text">
          User Type: {{ userType() }}
        </v-col>
      </v-row>

      <v-row class="mt-3" justify="center">
        <v-col cols="12" md="6" sm="6" xl="6" lg="6">
          Joined On: {{ joinedOn() }}
        </v-col>
        <v-col cols="12" md="6" sm="6" xl="6" lg="6">
          Is verified: {{ getUserDetails.is_verified }}
        </v-col>
      </v-row>

      <alert></alert>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getUserDetails: "user/getUserDetails",
      getCurrentUser: "getCurrentUser",
    }),
  },

  methods: {
    ...mapActions({
      fetchUserDetails: "user/fetchUserDetails",
    }),
    userType() {
      if (this.getUserDetails.userType === "media_planner") {
        return "Media Planner";
      } else if (this.getUserDetails.userType === "marketer") {
        return "Marketer";
      } else if (this.getUserDetails.userType === "media_owner") {
        return "Media Owner";
      } else if (this.getUserDetails.userType === "other") {
        return "Not Specified";
      } else if (this.getUserDetails.userType === "manager") {
        return "Manager";
      }
    },
    joinedOn() {
      return new Date(this.getUserDetails.createdAt).toDateString();
    },
  },

  async mounted() {
    await this.fetchUserDetails(this.getCurrentUser.userId);
  },
};
</script>

<style scoped>
.container {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.card-shadow {
  box-shadow: 10px 10px 8px -6px rgba(225, 218, 218, 0.919) !important;
  -webkit-box-shadow: 10px 10px 8px -6px rgba(248, 243, 243, 0.931) !important;
  -moz-box-shadow: 10px 10px 8px -6px rgba(247, 238, 238, 0.939) !important;
  border-right: 1px solid white !important;
  border-top: 0.4px solid rgb(113, 111, 111) !important;
  border-left: 0.4px solid rgb(113, 111, 111) !important;
  border-bottom: 1px solid white !important;
}

.row {
  font-family: Manrope, Arial, sans-serif !important;
  text-align: center;
}

.text-h5 > .textGray--text {
  text-align: center;
}
</style>
