<template>
  <v-card
    width="100%"
    min-height="650"
    elevation="1"
    :color="$vuetify.theme.themes.dark.background"
    class="white--text"
  >
    <v-toolbar
      :color="$vuetify.theme.themes.dark.background"
      height="34"
      flat
      class="py-3"
    >
      <v-spacer />
      <v-toolbar-title class="white--text">My Brands</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-card-text>
      <v-data-table
        :items="getUserBrands"
        :headers="headers"
        :search="search"
        class="elevation-1 pa-3"
        :loading="!getUserBrands.length"
        :style="{
          'background-color': $vuetify.theme.themes.dark.background,
          color: $vuetify.theme.themes.dark.text,
        }"
        loading-text="Fetching Brands... Please wait"
        dark
      >
        <template slot="top">
          <v-toolbar color="grey darken-3" flat>
            <v-btn class="white--text" @click="fetchMyBrands">
              Refresh
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-responsive max-width="60%">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-responsive>

            <v-spacer></v-spacer>

            <p class="white--text">Total Brands: {{ getUserBrands.length }}</p>
          </v-toolbar>
        </template>

        <template v-slot:[`item.images`]="{ item }">
          <div class="p-2">
            <div v-viewer class="images">
              <img
                class="image"
                v-for="img in item.BrandImages"
                :key="img.id"
                :src="img.path"
                :lazy-src="img.path"
                alt="billboard image"
              />
            </div>
          </div>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <span class="blue--text text--lighten-1">
            {{ item.name }}
          </span>
        </template>

        <template v-slot:[`item.brandCategory`]="{ item }">
          {{ getBrandCategoryName(item) }}
        </template>

        <template v-slot:[`item.briefs`]="{ item }">
          <span class="primary--text text--lighten-3">
            {{ getBriefsCount(item) }}
          </span>
        </template>

        <template v-slot:[`item.plans`]="{ item }">
          <span class="primary--text text--lighten-3">
            {{ getMediaPlansCount(item) }}
          </span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            class="actions--icon"
            color="green"
            @click.stop="(currentBrand = item), (showBrandForm = true)"
          >
            mdi-pencil</v-icon
          >

          <v-icon
            class="actions--icon"
            color="error"
            title="Delete"
            @click="deleteItem(item.id)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="showBrandForm">
      <v-toolbar :color="$vuetify.theme.themes.dark.background" flat>
        <v-spacer></v-spacer>
        <v-btn icon @click="showBrandForm = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <create-brand :brand="currentBrand" operation="edit"></create-brand>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card dark>
        <v-card-title class="text-h6"
          >Are you sure you want to delete this Brand?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteBrandConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <alert />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CreateBrand from "./CreateBrand.vue";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Image", value: "images" },
        { text: "Name", value: "name" },
        { text: "Brand Category", value: "brandCategory" },
        { text: "Number of Briefs", value: "briefs" },
        { text: "Number of Media Plans", value: "plans" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      currentBrand: {},
      currentBrandId: null,
      showBrandForm: false,

      deleteDialog: false,
      loading: false,
    };
  },
  components: { CreateBrand },
  computed: {
    ...mapGetters({
      getUserBrands: "brands/getUserBrands",
      getCurrentUser: "getCurrentUser",
    }),
  },
  methods: {
    ...mapActions({
      deleteBrand: "brands/deleteBrand",
      fetchUserBrands: "brands/fetchUserBrands",
    }),
    getBriefsCount(brand) {
      return brand?.BrandOwners?.flatMap((x) => x.Briefs).length || 0;
    },
    getMediaPlansCount(brand) {
      return (
        brand?.BrandOwners.flatMap((owner) => {
          return owner.Briefs.flatMap((brief) => brief.Plans);
        }).length || 0
      );
    },
    getBrandCategoryName(brand) {
      if (brand.BrandCategory) {
        return brand.BrandCategory.name;
      } else return "Not Established";
    },

    deleteItem(id) {
      this.currentBrandId = id;
      this.deleteDialog = true;
    },

    closeDelete() {
      this.deleteDialog = false;
    },

    async deleteBrandConfirm() {
      this.loading = true;
      await this.deleteBrand(this.currentBrandId);
      this.deleteDialog = false;

      await this.fetchMyBrands();
      this.loading = false;
    },
    async fetchMyBrands() {
      this.loading = true;
      await this.fetchUserBrands(this.getCurrentUser.userId);
      this.loading = false;
    },
  },

  watch: {
    deleteDialog(val) {
      val || this.closeDelete();
    },
  },

  async created() {
    await this.fetchMyBrands();
  },
};
</script>

<style scoped>
.v-card {
  font-family: Manrope, Arial, sans-serif !important;
}

.image {
  height: 50px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}

.image:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}
</style>
