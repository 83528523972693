<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-btn color="secondary lighten-1" elevation="0" @click="createPPt()">
      PPT
      <v-icon color="white">mdi-file-powerpoint </v-icon>
    </v-btn>
  </div>
</template>

<script>
import PptxGenJS from "pptxgenjs";

import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { Buffer } from "buffer";
import html2canvas from "html2canvas";
import EventBus from "@/components/events/EventBus";
import { handleHttpError } from "@/helpers/utils";

export default {
  data() {
    return {
      loading: false,
      isInvisible: false,

      snapshotImageData: null,
      mapComponent: null,
      pptx: null,
    };
  },

  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },

  components: {},

  computed: {
    ...mapGetters({
      getPopulation: "billboards/getPopulation",
    }),
  },

  methods: {
    ...mapActions({
      fetchPop: "billboards/fetchPop",
    }),

    parseDate(dateStr) {
      return new Date(dateStr).toDateString();
    },
    async toDataURL(url) {
      try {
        const responseBlob = await axios.get(url, { responseType: "blob" });
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(responseBlob);
        });
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async createPPt() {
      // add loader
      if (!this.mapComponent)
        return handleHttpError("Please wait as the Map loads.");
      try {
        this.loading = true;
        this.pptx = new PptxGenJS();

        this.pptx.defineLayout({ name: "HD", width: 20, height: 11.25 });

        // Set presentation to use new layout
        this.pptx.layout = "HD";

        let slide = this.pptx.addSlide();

        let svgContent = await this.fetchSvgSlideContent("slide_1");

        let modifiedSvgText = svgContent.replace(
          "{campaign_name}",
          `${this.campaign.name}`
        );
        svgContent = this.encodeBase64(modifiedSvgText);

        slide
          .addImage({
            data: svgContent,
            x: 0,
            y: 0,
            w: "100%",
            h: "100%",
          })
          .addImage({
            path: "/images/Abonten Logos-01.png",
            x: "87%",
            y: "75%",
            w: "20%",
            h: "35%",
          });

        slide = this.pptx.addSlide();
        svgContent = await this.fetchSvgSlideContent("slide_2");

        let startDate = this.parseDate(this.campaign.Brief.start_date);
        let endDate = this.parseDate(this.campaign.Brief.end_date);
        const negBbCost = this.campaign.Candidates.reduce((acc, cand) => {
          return acc + cand.negotiated_net_rate;
        }, 0);

        const totalBbCost = this.campaign.Candidates.reduce((acc, cand) => {
          return acc + cand.total_rate_card;
        }, 0);

        console.log(totalBbCost);

        modifiedSvgText = svgContent
          .replace("Campaign_Name", `${this.campaign.name}`)
          .replace("Brand_Name", `${this.campaign.Brief.BrandOwner.Brand.name}`)
          .replace("Number_of_Sites", `${this.campaign.Candidates.length}`)
          .replace(
            "Audience_Reach",
            `${new Intl.NumberFormat().format(this.campaign.totalOppContact)}`
          )
          .replace(
            "Campaign_Budget",
            `${new Intl.NumberFormat().format(this.campaign.Brief.budget)}`
          )
          .replace(
            "Planner_Name",
            `${this.campaign.Planner.User.first_name} ${this.campaign.Planner.User.last_name}`
          )
          .replace(
            "Planner_Organization_Name",
            `${this.campaign.Planner.User.organization}`
          )
          .replace("Campaign_Start_Date", `${startDate}`)
          .replace("Campaign_End_Date", `${endDate}`)
          .replace("Campaign_Start_Date", `${this.startDate}`)
          .replace(
            "Neg_Bb_Cost",
            `${new Intl.NumberFormat().format(negBbCost)}`
          )
          .replace(
            "Tot_Bb_Cost",
            `${new Intl.NumberFormat().format(totalBbCost)}`
          );

        svgContent = this.encodeBase64(modifiedSvgText);

        slide
          .addImage({
            data: svgContent,
            x: 0,
            y: 0,
            w: "100%",
            h: "100%",
          })
          .addImage({
            path: "/images/Abonten Logos-01.png",
            x: "87%",
            y: "75%",
            w: "20%",
            h: "35%",
          });

        // add campaign map
        await this.createMapString();

        slide = this.pptx.addSlide();

        slide
          .addText("CAMPAIGN GEO POSITION", {
            x: "40%",
            y: 0.2,
            bold: true,
            underline: true,
            fontSize: 24,
          })
          .addImage({
            data: this.snapshotImageData,
            x: 0.5,
            y: 0.5,
            w: "95%",
            h: "90%",
          })
          .addImage({
            path: "/images/Abonten Logos-01.png",
            x: "87%",
            y: "75%",
            w: "20%",
            h: "35%",
          });

        for (const candidate of this.campaign.Candidates) {
          let candidateStatus = candidate.status
            ? "Confirmed"
            : "Yet to be confirmed";
          slide = this.pptx.addSlide();
          svgContent = await this.fetchSvgSlideContent("slide_4");

          let dynamicImageUrl = candidate.Billboard.Images[0]?.path;

          let imageBase64 = await this.getImageBase64(dynamicImageUrl);

          modifiedSvgText = svgContent
            .replace("Billboard_Medium", `${candidate.Billboard.medium}`)
            .replace(
              "Billboard_Dimensions",
              `${candidate.Billboard.length} * ${candidate.Billboard.width} Metres`
            )
            .replace("Billboard_Address", `${candidate.Billboard.address}`)
            .replace("Billboard_Ligthing", `${candidate.Billboard.lighting}`)
            .replace(
              "Billboard_Reach",
              `${new Intl.NumberFormat().format(candidate.oppContact)}`
            )
            .replace(
              "Billboard_Cost",
              `GH₵ ${new Intl.NumberFormat().format(candidate.total_rate_card)}`
            )
            .replace("Billboard_Status", `${candidateStatus}`);

          svgContent = this.encodeBase64(modifiedSvgText);

          slide
            .addImage({
              data: svgContent,
              x: 0,
              y: 0,
              w: "100%",
              h: "100%",
            })
            .addImage({
              path: imageBase64,
              y: 2.294,
              x: 11.47,
              sizing: {
                type: "contain",
                w: 8.18, // Width (inches)
                h: "80%", // Height (inches)
              },
            })
            .addImage({
              path: "/images/Abonten Logos-01.png",
              x: "87%",
              y: "75%",
              w: "20%",
              h: "35%",
            });
        }

        slide = this.pptx.addSlide();
        svgContent = await this.fetchSvgSlideContent("slide_5");
        svgContent = this.encodeBase64(svgContent);
        slide
          .addImage({
            data: svgContent,
            x: 0,
            y: 0,
            w: "100%",
            h: "100%",
          })
          .addImage({
            path: "/images/Abonten Logos-01.png",
            x: "87%",
            y: "75%",
            w: "20%",
            h: "35%",
          });

        this.pptx.author = "abonten.com";
        this.pptx.company = "Abonten";
        this.pptx.subject = "Campaign Master Plan";
        this.pptx.title = `${this.campaign.name} Presentation`;

        await this.pptx.writeFile({
          fileName: `${this.campaign.name} campaign report.pptx`,
        });

        // remove loader
        this.loading = false;
        this.pptx = null;
      } catch (err) {
        this.loading = false;
        handleHttpError(`Error creating PPT. ${err}`);
      }
    },

    async createMapString() {
      this.snapshotImageData = await this.getSnapshotOfElement(
        this.mapComponent,
        0,
        0,
        1024,
        768
      );
    },

    async getSnapshotOfElement(mapComponent, posX, posY) {
      const canvas = await html2canvas(mapComponent, {
        useCORS: true,
        allowTaint: false,
      });
      const context = canvas.getContext("2d");
      const imageData = context.getImageData(
        posX,
        posY,
        canvas.width,
        canvas.height
      ).data;
      const outputCanvas = document.createElement("canvas");
      const outputContext = outputCanvas.getContext("2d");
      outputCanvas.width = canvas.width;
      outputCanvas.height = canvas.height;

      const outputIData = outputContext.createImageData(
        canvas.width,
        canvas.height
      );
      outputIData.data.set(imageData);
      outputContext.putImageData(outputIData, 0, 0);

      return outputCanvas.toDataURL();
    },

    async fetchSvgSlideContent(slide) {
      let res = await fetch(`/templates_svg/campaign/${slide}.svg`);
      let svgContent = await res.text();
      return svgContent;
    },

    encodeBase64(data) {
      try {
        const svgTextBuffer = Buffer.from(data);
        const base64Data = `data:image/svg+xml;base64,${svgTextBuffer.toString(
          "base64"
        )}`;
        return base64Data;
      } catch (err) {
        console.error(err);
      }
    },
    async getImageDimensions(imageUrl) {
      const image = new Image();
      await new Promise((resolve, reject) => {
        image.onload = () => {
          resolve();
        };
        image.onerror = (error) => {
          reject(error);
        };
        image.src = imageUrl;
      });
      return { width: image.width, height: image.height };
    },

    async getImageBase64(imageUrl) {
      if (!imageUrl) return;
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    },

    calculateFitDimensions(
      containerWidth,
      containerHeight,
      imageWidth,
      imageHeight
    ) {
      let newWidth, newHeight;

      const aspectRatio = imageWidth / imageHeight;
      const maxWidth = containerWidth;
      const maxHeight = containerHeight;

      if (imageWidth > maxWidth || imageHeight > maxHeight) {
        if (aspectRatio > 1) {
          newWidth = maxWidth;
          newHeight = newWidth / aspectRatio;
        } else {
          newHeight = maxHeight;
          newWidth = newHeight * aspectRatio;
        }
      } else {
        newWidth = imageWidth;
        newHeight = imageHeight;
      }

      return { width: newWidth, height: newHeight };
    },
  },
  async mounted() {
    EventBus.$on("mapContent", (val) => {
      this.mapComponent = val;
    });
  },
};
</script>
<style>
.v-btn {
  background: rgba(143, 146, 161, 0.05);
  border-radius: 32px;
  margin: 0 10px;
}
</style>
