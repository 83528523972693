<template>
  <v-card
    width="100%"
    min-height="650"
    id="bus"
    class="py-5 px-2 mx-auto white--text"
    :color="$vuetify.theme.themes.dark.background"
  >
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-toolbar
      :color="$vuetify.theme.themes.dark.background"
      flat
      class="white--text mb-2"
    >
      <v-toolbar-title>All Briefs</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-data-table
        :items="allBriefs"
        :headers="headers"
        :search="search"
        class="pa-3"
        :style="{
          'background-color': $vuetify.theme.themes.dark.background,
          color: $vuetify.theme.themes.dark.text,
        }"
        dark
        loading-text="Fetching Briefs... Please wait."
        no-data-text="There are no Briefs currently"
      >
        <template slot="top">
          <v-toolbar color="grey darken-3" flat>
            <v-btn class="white--text" @click="fetchBriefs">
              Refresh
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-responsive max-width="40%" class="mx-auto mb-2">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-responsive>
            <v-spacer></v-spacer>

            <p class="white--text">Total Briefs: {{ allBriefs.length }}</p>
          </v-toolbar>
        </template>

        <template v-slot:[`item.brand`]="{ item }">
          <span class="secondary--text text--lighten-1">
            {{ item.BrandOwner.Brand.name || "Not Available" }}
          </span>
        </template>

        <template v-slot:[`item.budget`]="{ item }">
          <span id="formatBudget">
            {{ formatBudget(item.budget) }}
          </span>
        </template>

        <template v-slot:[`item.duration`]="{ item }">
          {{ duration(item.start_date, item.end_date) }}
        </template>

        <template v-slot:[`item.campaign_period`]="{ item }">
          {{ parseDate(item.start_date) + "  :  " + parseDate(item.end_date) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            @click.stop="
              (currentBriefId = item.id), (showBriefDetailsDialog = true)
            "
          >
            <v-icon color="secondary" title="More Information">
              mdi-eye
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="showBriefDetailsDialog" width="60%">
      <v-card width="100%" elevation="0">
        <v-toolbar
          :color="$vuetify.theme.themes.dark.background"
          flat
          class="white--text mb-2"
        >
          <v-spacer></v-spacer>

          <v-btn icon @click="showBriefDetailsDialog = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div>
          <brief-details :currentBrief="currentBrief"></brief-details>
        </div>
      </v-card>
    </v-dialog>

    <alert></alert>
  </v-card>
</template>

<script>
import { fetchAllBriefs } from "@/helpers/briefs";
import * as dayjs from "dayjs";
import { mapActions } from "vuex";

import BriefDetails from "@/components/briefs/BriefDetails.vue";

export default {
  data() {
    return {
      loading: false,

      search: "",
      allBriefs: [],
      headers: [
        { text: "Brand", value: "brand" },
        { text: "Budget(GH₵)", value: "budget" },
        { text: "Duration (Days)", value: "duration" },
        { text: "Campaign Period", value: "campaign_period" },
        { text: "Campaign Type", value: "campaignType" },
        { text: "Preferred Regions", value: "region" },
        { text: "Actions", value: "actions" },
      ],

      showBriefDetailsDialog: false,
      currentBrief: {
        id: null,
        BrandOwner: {
          Marketer: {
            client: null,
          },
          Planner: {
            client: null,
          },
          Brand: {
            name: null,
          },
        },
        budget: 0,
        region: [],
        campaignType: null,
        sec: [],
        ageGroup: [],
        gender: [],
        start_date: null,
        end_date: null,
        description: null,
      },

      currentBriefId: null,
    };
  },

  components: { BriefDetails },

  methods: {
    ...mapActions({
      fetchBrief: "briefs/fetchBrief",
    }),
    async fetchBriefs() {
      this.loading = true;
      this.allBriefs = await fetchAllBriefs();
      this.loading = false;
    },

    parseDate(dateStr) {
      return new Date(dateStr).toDateString();
    },

    formatBudget(budget) {
      return new Intl.NumberFormat().format(budget);
    },

    duration(start_date, end_date) {
      let end = dayjs(end_date);
      let start = dayjs(start_date);

      let diff = end.diff(start, "d");

      if (!diff) return 0;
      return diff;
    },
  },
  watch: {
    currentBriefId: {
      async handler(val) {
        this.loading = true;
        this.currentBrief = await this.fetchBrief(val);
        this.loading = false;
      },
    },
  },

  async created() {
    await this.fetchBriefs();
  },
};
</script>

<style scoped>
.v-card {
  font-family: Manrope, Arial, sans-serif;
  box-shadow: 10px 10px 8px -6px rgba(225, 218, 218, 0.919) !important;
  -webkit-box-shadow: 10px 10px 8px -6px rgba(248, 243, 243, 0.931) !important;
  -moz-box-shadow: 10px 10px 8px -6px rgba(247, 238, 238, 0.939) !important;
  border-right: 1px solid white !important;
  border-top: 0.4px solid rgb(113, 111, 111) !important;
  border-left: 0.4px solid rgb(113, 111, 111) !important;
  border-bottom: 1px solid white !important;
}

#formatBudget {
  background: rgba(32, 201, 172, 0.1);
  height: 48px !important;
  color: #20c9ac;
  padding: 5px 14px;
}
</style>
