import {
  createSubscription,
  fetchMySubscriptions,
} from "@/helpers/subscription";

export default {
  async createSubscription(_, payload) {
    return await createSubscription(payload);
    // await dispatch("fetchMySubscriptions");
  },

  async fetchMySubscriptions({ commit }) {
    const subscriptions = await fetchMySubscriptions();
    return commit("setSubscription", subscriptions);
  },
};
