<template>
  <v-card v-if="currentBillboard" elevation="0" class="mx-auto" max-width="350">
    <v-card-text>
      <v-row dense v-viewer>
        <v-col v-for="img in currentBillboard.Images" :key="img.id" cols="12">
          <v-tabs centered slider-color="secondary" v-model="tab" grow>
            <v-tab ripple href="#images">Images</v-tab>
            <v-tab ripple href="#features">Features</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="images">
              <v-card>
                <v-card-text>
                  <v-container fluid>
                    <v-row dense v-viewer>
                      <v-col
                        v-for="img in currentBillboard.Images"
                        :key="img.id"
                        cols="12"
                        md="4"
                      >
                        <v-card elevation="0">
                          <img
                            class="white--text align-end image"
                            gradient="to bottom, rgba(0,0,0,.1),rgba(0,0,0,.5)"
                            height="200px"
                            alt="Billboard image"
                            :src="img.path"
                            :lazy-src="img.path"
                            loading="lazy"
                          />
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="features">
              <v-card elevation="0">
                <v-card-text class="features">
                  <div>
                    Location:
                    <b>
                      {{ currentBillboard.address }}
                    </b>
                  </div>
                  <div>
                    Price:
                    <b> {{ parseNumber(currentBillboard.rate_card) }} GH₵ </b>
                  </div>

                  <div>
                    Lighting:
                    <b>
                      {{ currentBillboard.lighting }}
                    </b>
                  </div>

                  <div>
                    Size:
                    <b>
                      {{ currentBillboard.length }} *
                      {{ currentBillboard.width }} m</b
                    >
                  </div>

                  <div>
                    Height:
                    <b> {{ currentBillboard.height }}</b>
                  </div>

                  <div>
                    Site Run Up:
                    <b>{{ currentBillboard.site_run_up }}m</b>
                  </div>

                  <div>
                    Clutter:
                    <b> {{ currentBillboard.clutter }}</b>
                  </div>

                  <div>
                    Faces:
                    <b>
                      {{ currentBillboard.faces }}
                    </b>
                  </div>

                  <div>
                    Medium
                    <b>{{ currentBillboard.medium }}</b>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      // tabs for infowindow
      tab: null,
    };
  },
  props: {
    currentBillboard: {
      type: Object,
      required: true,
    },
  },
  methods: {
    parseNumber(amount) {
      return new Intl.NumberFormat().format(amount);
    },
  },
};
</script>

<style scoped>
.features > div {
  display: flex;
  justify-content: space-between;
  color: black;
  font-size: 13px;
}
</style>
