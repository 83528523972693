<template>
  <v-card width="100%" height="100%">
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <GmapMap
      ref="googleMap"
      :center="center"
      :zoom="zoom"
      class="map"
      :options="mapOptions"
    >
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
        <ownerbb-feature :currentBillboard="currentBillboard"></ownerbb-feature>
      </gmap-info-window>
    </GmapMap>

    <div id="mapStyle">
      <v-radio-group v-model="mapStyle">
        <v-radio label="Dark" value="dark" color="black"></v-radio>
        <v-radio label="Light" value="light" color="grey"></v-radio>
      </v-radio-group>
    </div>
  </v-card>
</template>

<script>
import { gmapApi } from "gmap-vue";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import { createNamespacedHelpers } from "vuex";
import OwnerbbFeature from "./OwnerbbFeature.vue";
const { mapGetters } = createNamespacedHelpers("billboards");
import { styles } from "@/components/map/mapstyle.js";

export default {
  data() {
    return {
      loading: false,
      infoOptions: { pixelOffset: { width: 0, height: -35 } },
      // popup window
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,

      mapOptions: {
        zoomControl: true,
        zoomControlOptions: { position: 9 },
        mapTypeControl: true,
        mapTypeControlOptions: { position: 11 },
        scaleControl: false,
        streetViewControl: true,
        streetViewControlOptions: { position: 9 },
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        styles: styles.dark,
      },

      mapStyleToggle: null,
      mapStyle: "dark",

      map: null,
      api: null,
      apiLoaded: false,

      zoom: 15,
      center: { lat: 5.623028, lng: -6.176527 },

      bbClusterer: null,
      bbMarkers: null,
      bounds: null,
      currentBillboard: {},
    };
  },

  components: { OwnerbbFeature },

  computed: {
    google: gmapApi,
    ...mapGetters(["getBillboards"]),
  },

  watch: {
    mapStyle: {
      handler(val) {
        this.map.setOptions({ styles: styles[val] });
      },
    },
  },

  methods: {
    plotBillboards() {
      if (!this.getBillboards.length) {
        this.bbClusterer.clearMarkers();
        return;
      }
      // show loader
      this.loading = true;
      // remove bbs
      this.bbClusterer.clearMarkers();

      this.bounds = new this.google.maps.LatLngBounds();

      this.bbMarkers = this.getBillboards.map((bb) => {
        let latlng = new this.google.maps.LatLng(+bb.latitude, +bb.longitude);

        this.bounds.extend(latlng);

        let bbMarker = new this.google.maps.Marker({
          position: latlng,
          icon: {
            url: "/images/billboardColored.png",
            scaledSize: new this.google.maps.Size(25, 25),
          },
        });

        // set listener
        this.google.maps.event.addListener(bbMarker, "click", async () => {
          this.currentBillboard = bb;

          // position of the Popup window
          this.infoWindowPos = {
            lat: this.currentBillboard.latitude,
            lng: this.currentBillboard.longitude,
          };

          // open info window
          this.infoWinOpen = true;

          //calculate score
          // this.getBBScore();
        });

        return bbMarker;
      });

      this.bbClusterer.addMarkers(this.bbMarkers);
      this.map.fitBounds(this.bounds);
      this.map.panToBounds(this.bounds);

      this.loading = false;
    },
  },

  async mounted() {
    // Therefore we need to write googleMap.$mapPromise.then(() => ...)
    this.map = await this.$refs.googleMap.$mapPromise;

    // lazy loading
    // google maps api library is loaded asynchronously , thus is not immediately available even when the page is loaded.
    this.api = await this.$gmapApiPromiseLazy();
    this.apiLoaded = !!this.api;

    this.mapStyleToggle = document.querySelector("#mapStyle");

    this.map.controls[this.google.maps.ControlPosition.LEFT_TOP].push(
      this.mapStyleToggle
    );

    // Billboard cluster
    this.bbClusterer = new MarkerClusterer({
      map: this.map,
      markers: [],
      averageCenter: true,
    });

    this.plotBillboards();
  },
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 70vh;
}
#infoWindow {
  max-width: 350px;
  margin: 10px;
}
.images {
  display: flex;
  justify-content: baseline;
  align-items: center;
  margin-bottom: 10px;
  transition: all 0.2s;
}
.image {
  cursor: pointer;
  margin: 5px;
}
.image:hover {
  transform: scale(1.07);
  transition: all 0.2s;
}

#mapStyle {
  background: #fff176;
  padding: 0 10px;
  margin-top: 10px;
}
</style>
