<template>
  <div class="gradient-strip">
    <span
      v-for="n in count ?? stripCount"
      :key="n"
      :style="{ width: `${width ?? stripWidth}px` }"
    ></span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      width: null,
      count: null,
    };
  },
  props: {},
  computed: {
    stripCount() {
      return window.innerWidth <= 420 ? 5 : window.innerWidth <= 768 ? 10 : 20;
    },
    stripWidth() {
      return window.innerWidth / this.stripCount;
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.count =
        window.innerWidth <= 420 ? 5 : window.innerWidth <= 768 ? 10 : 20;
      this.width = window.innerWidth / this.count ?? 1;
    });
  },
};
</script>

<style scoped>
.gradient-strip {
  display: flex;
  height: 14px;
}

.gradient-strip span:nth-child(odd) {
  background: linear-gradient(90deg, #d7272f 0%, #542458 100%), #d9d9d9;
}

.gradient-strip span:nth-child(even) {
  background: linear-gradient(90deg, #d7272f 0%, #f0a022 100%), #d9d9d9;
}
</style>
