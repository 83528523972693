<template>
  <v-form v-model="valid" ref="setNewPwdForm">
    <AuthLayout>
      <div class="py-8">
        <h4 class="mb-6" style="font-size: 28px">Reset your password</h4>
        <BeautifulInput
          placeholder="Enter Password"
          icon="mdi-key"
          @input="setPassword"
          :rules="[...passwordRules]"
          :disabled="success"
          :password="true"
          required
        ></BeautifulInput>
        <BeautifulInput
          placeholder="Confirm Password"
          icon="mdi-key-plus"
          @input="setConfirmPassword"
          :rules="[...confirmPasswordRules, passwordsMatch]"
          :disabled="success"
          :password="true"
        ></BeautifulInput>
        <p
          class="white-text mb-8 mx-6"
          style="font-size: 12px; margin-top: -12px; font-weight: 300"
        >
          Minimum 10 characters. Must contain upper and lowercase, numbers, and
          symbols
        </p>
        <div class="d-flex">
          <v-btn
            rounded
            class="text-none"
            @click="submitForm"
            :disabled="loading"
            :loading="loading"
            >{{ success ? `Successful ✅` : "Submit" }}</v-btn
          >
        </div>
      </div>
    </AuthLayout>
    <alert></alert>
  </v-form>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("user");
import AuthLayout from "@/components/misc/AuthLayout.vue";
import BeautifulInput from "@/components/misc/BeautifulInput.vue";

export default {
  components: { AuthLayout, BeautifulInput },
  data() {
    return {
      showPwd: false,

      password: null,
      confirmPassword: null,

      valid: false,
      loading: false,
      success: false,

      passwordRules: [
        (v) => !!v || "Password cannot be Empty",
        (v) => (v && /\d/.test(v)) || "At least one digit",
        (v) => (v && /[A-Z]{1}/.test(v)) || "At least one capital latter",
        (v) =>
          (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",
      ],
      confirmPasswordRules: [
        (v) => !!v || "Password cannot be Empty",
        (v) => (v && /\d/.test(v)) || "At least one digit",
        (v) => (v && /[A-Z]{1}/.test(v)) || "At least one capital latter",
        (v) =>
          (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",
      ],
    };
  },
  computed: {
    passwordsMatch() {
      return () =>
        this.password === this.confirmPassword || "Passwords do not Match";
    },
  },

  methods: {
    ...mapActions(["resetPassword"]),

    async submitForm() {
      if (this.success) return;

      this.$refs.setNewPwdForm.validate();
      if (!this.valid) return;
      this.loading = true;

      const res = await this.resetPassword({
        password: this.password,
        token: this.$route.params.token,
      });
      this.loading = false;
      this.success = true;

      if (res) {
        setTimeout(() => {
          this.$router.replace("/login");
        }, 3000);
      }
    },
    setPassword(v) {
      this.password = String(v).trim();
    },
    setConfirmPassword(v) {
      this.confirmPassword = String(v).trim();
    },
  },
};
</script>

<style scoped>
h4 {
  font-size: 36px;
  font-weight: 400;
  color: #fff;
}
</style>
