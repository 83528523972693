<template>
  <v-container fluid>
    <v-row justify="center">
      <v-btn
        v-for="btn in buttons"
        :key="btn.name"
        color="white"
        @click="setComponent(btn.view)"
        :class="{ active: btn.view === currentView }"
        >{{ btn.name }}</v-btn
      >
    </v-row>
    <v-row>
      <keep-alive>
        <component :is="currentView"></component>
      </keep-alive>
    </v-row>
  </v-container>
</template>

<script>
import MarketingInsights from "./MarketingInsights.vue";
import BrandCompetitors from "../../components/brands/BrandCompetitors.vue";

export default {
  data() {
    return {
      currentView: "MarketingInsights",
      buttons: [
        {
          name: "Global",
          view: "MarketingInsights",
        },
        {
          name: "My Brands",
          view: "BrandCompetitors",
        },
      ],
    };
  },
  components: { MarketingInsights, BrandCompetitors },
  methods: {
    setComponent(view) {
      this.currentView = view;
    },
  },
};
</script>

<style scoped>
.container {
  background: #424242;
}

.active {
  background: #f68625 !important;
}
</style>
