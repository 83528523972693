<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <GmapMap
      ref="googleMap"
      :center="center"
      :zoom="zoom"
      class="map"
      :options="mapOptions"
    >
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
        <v-card
          v-if="currentBillboard"
          elevation="0"
          class="mx-auto"
          max-width="350"
        >
          <v-tabs centered slider-color="secondary" v-model="tab" grow>
            <v-tab ripple href="#images">Images</v-tab>
            <v-tab ripple href="#features">Features</v-tab>
            <v-tab ripple href="#population">Population</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="images">
              <v-card>
                <v-card-text>
                  <v-container fluid>
                    <v-row dense v-viewer>
                      <v-col
                        v-for="img in currentBillboard.Images"
                        :key="img.id"
                        cols="12"
                        md="4"
                      >
                        <v-card elevation="0">
                          <img
                            class="white--text align-end image"
                            gradient="to bottom, rgba(0,0,0,.1),rgba(0,0,0,.5)"
                            height="200px"
                            :src="img.path"
                            alt="Billboard Image"
                            :lazy-src="img.path"
                            loading="lazy"
                          />
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="features">
              <v-card elevation="0">
                <billboard-features
                  :currentBillboard="currentBillboard"
                  :oppContact="oppContact"
                  :visibilityAdj="visibilityAdj"
                ></billboard-features>
              </v-card>
            </v-tab-item>
            <v-tab-item value="population">
              <v-card>
                <v-card-text>
                  <population-view
                    :population="getPopulation"
                  ></population-view>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </gmap-info-window>

      <div id="mapStyle">
        <v-radio-group v-model="mapStyle">
          <v-radio label="Dark" value="dark" color="black"></v-radio>
          <v-radio label="Light" value="light" color="grey"></v-radio>
        </v-radio-group>
      </div>
    </GmapMap>
  </v-container>
</template>

<script>
import { styles } from "@/components/map/mapstyle.js";
import { gmapApi } from "gmap-vue";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import PopulationView from "../population/PopulationView.vue";
import filterScores from "@/mixins/filterScores.js";
import BillboardFeatures from "@/components/billboard/BillboardFeatures.vue";

import { mapGetters, mapActions } from "vuex";
import EventBus from "@/components/events/EventBus";

export default {
  mixins: [filterScores],

  data() {
    return {
      loading: false,
      map: null,
      api: null,
      apiLoaded: false,

      xmin: -3.269471,
      ymin: 4.736839,
      xmax: 1.21373,
      ymax: 11.219829,
      bounds: null,

      //
      infoTab: null,
      legend: null,

      mapOptions: {
        zoomControl: true,
        zoomControlOptions: { position: 9 },
        mapTypeControl: true,
        mapTypeControlOptions: { position: 11 },
        scaleControl: false,
        streetViewControl: true,
        streetViewControlOptions: { position: 9 },
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        styles: styles.dark,
      },
      zoom: 13,
      center: { lat: 5.623028, lng: -0.176527 },

      billboards: [],
      bbClusterer: [],
      bbChecked: false,
      bbMarkers: [],
      currentBillboard: null,
      currentBbScore: 0,
      visibilityAdj: 0,
      totalBbScore: 17,
      oppContact: 0,

      trafficChecked: false,
      trafficLayer: null,

      // tabs for infowindow
      tab: null,

      // popup window
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },

      mapStyleToggle: null,
      mapStyle: "dark",
    };
  },

  props: {
    currentCampaign: {
      type: Object,
      default: () => ({
        id: null,
        budget: null,
        duration: null,
        start_date: null,
        end_date: null,
        name: null,
        Candidates: [
          {
            negotiated_net_rate: null,
            status: null,
            availability_confirmation: null,
            Comments: [],
            Billboard: {
              id: null,
              latitude: null,
              longitude: null,
              Images: [{ path: null }],
              MediaOwner: {
                User: {
                  organization: null,
                  first_name: null,
                  last_name: null,
                },
              },
            },
          },
        ],
        planner: {
          user: {
            organization: null,
            planner_name: null,
          },
        },
        Brief: {
          name: null,
          budget: null,
          BrandOwner: {
            Brand: {
              name: null,
            },
          },
        },
      }),
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      getBillboards: "billboards/getBillboards",
      getPopulation: "billboards/getPopulation",
    }),

    google: gmapApi,

    mapReady() {
      return this.google && this.map !== null;
    },
    mapAndItsContentsReady() {
      return this.mapReady && !this.loading && this.apiLoaded;
    },
  },

  components: {
    PopulationView,
    BillboardFeatures,
  },

  methods: {
    ...mapActions({
      fetchPop: "billboards/fetchPop",
    }),

    plotBillboards() {
      if (!this.currentCampaign.Candidates.length) return;
      this.loading = true;
      this.bounds = new this.google.maps.LatLngBounds();

      this.bbClusterer.removeMarkers(this.bbMarkers);
      this.bbMarkers = [];
      this.bbMarkers = this.currentCampaign.Candidates.map(
        ({ Billboard: bb }) => {
          let latlng = new this.google.maps.LatLng(
            parseFloat(bb.latitude),
            parseFloat(bb.longitude)
          );

          this.bounds.extend(latlng);

          let bbMarker = new this.google.maps.Marker({
            position: latlng,
            icon: {
              url: "/images/billboardColored.png",
              scaledSize: new this.google.maps.Size(25, 25),
            },
            data: {
              lighting: bb.lighting,
              height: bb.height,
              orientation: bb.orientation,
              clutter: bb.clutter,
              site_run_up: bb.site_run_up,
            },
          });

          // set listener
          this.google.maps.event.addListener(bbMarker, "click", async () => {
            this.currentBillboard = { type: "candidateBillboard", ...bb };

            // position of the Popup window
            this.infoWindowPos = {
              lat: this.currentBillboard.latitude,
              lng: this.currentBillboard.longitude,
            };

            // get population around the area
            await this.fetchPop({
              lat: this.currentBillboard.latitude,
              long: this.currentBillboard.longitude,
            });

            // open info window
            this.infoWinOpen = true;

            //calculate score
            this.getBBScore();
          });

          return bbMarker;
        }
      );

      this.bbClusterer.clearMarkers();
      this.bbClusterer.addMarkers(this.bbMarkers);
      this.map.fitBounds(this.bounds);
      this.map.panToBounds(this.bounds);
      this.loading = false;

      this.sendMapContent();
    },
    sendMapContent() {
      if (!this.mapAndItsContentsReady) return this.sendMapContent();
      EventBus.$emit("mapContent", document.querySelector(".map"));
    },

    getBBScore() {
      const lightSc =
        this.filterScores["lighting"][
          this.currentBillboard.lighting.toLowerCase()
        ].score;

      const hgtSc =
        this.filterScores["height"][this.currentBillboard.height.toLowerCase()]
          .score;

      const strnSc = this.filterScores["site_run_up"](
        this.currentBillboard.site_run_up
      ).score;

      const ortnSc =
        this.filterScores["orientation"][
          this.currentBillboard.orientation.toLowerCase()
        ].score;

      const clutSc =
        this.filterScores["clutter"][
          this.currentBillboard.clutter.toLowerCase()
        ].score;

      this.currentBbScore = lightSc + hgtSc + strnSc + ortnSc + clutSc;
      this.visibilityAdj = `${Math.round(
        (100 * this.currentBbScore) / this.totalBbScore
      )}%`;

      this.oppContact = Math.round(
        (this.currentBbScore / this.totalBbScore) * this.getPopulation.totalPop
      );
    },
  },

  watch: {
    currentCampaign: {
      deep: true,
      handler() {
        if (this.mapReady) this.plotBillboards();
      },
    },

    mapStyle: {
      handler(val) {
        this.map.setOptions({ styles: styles[val] });
      },
    },
  },

  async mounted() {
    // At this point, the child GmapMap has been mounted, but
    // its map has not been initialized.
    // Therefore we need to write googleMap.$mapPromise.then(() => ...)
    this.map = await this.$refs.googleMap.$mapPromise;

    // lazy loading
    // google maps api library is loaded asynchronously , thus is not immediately available even when the page is loaded.
    this.api = await this.$gmapApiPromiseLazy();
    this.apiLoaded = !!this.api;

    this.mapStyleToggle = document.querySelector("#mapStyle");

    this.map.controls[this.google.maps.ControlPosition.LEFT_TOP].push(
      this.mapStyleToggle
    );

    // Billboard cluster
    this.bbClusterer = new MarkerClusterer({
      map: this.map,
      markers: [],
      averageCenter: true,
    });

    this.plotBillboards();
  },
};
</script>

<style>
.map {
  width: 100%;
  height: 65vh;
}

#mapStyle {
  background: #fff176;
  padding: 0 10px;
  margin-top: 10px;
}
</style>
