<template>
  <v-dialog v-model="dialog" max-width="80%">
    <template v-slot:activator="{ on, attrs }">
      <v-icon :id="operation" :title="operation" v-on="on" v-bind="attrs">
        <slot></slot>
      </v-icon>
    </template>

    <v-card :color="$vuetify.theme.themes.dark.background" class="white--text">
      <v-toolbar dark>
        <v-row justify="center">
          <span class="text-h5">BILLBOARD FORM</span>
        </v-row>
      </v-toolbar>

      <v-progress-linear
        color="deep-purple accent-4"
        :active="loading"
        :indeterminate="loading"
      ></v-progress-linear>

      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-container fluid>
            <v-row justify="center" v-if="currentBillboard.id">
              <span class="text-h4 green--text text--darken-4 font-weight-bold"
                >ID: {{ currentBillboard.bb_id }}</span
              >

              <v-col v-for="(img, idx) in currentBillboard.Images" :key="idx">
                <div v-viewer v-if="currentBillboard.Images[0].path">
                  <v-card>
                    <v-card-text>
                      <img
                        class="previewImage"
                        :src="img.path"
                        :lazy-src="img.path"
                        alt="Billboard Image"
                        loading="lazy"
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-icon
                        color="error"
                        title="Delete"
                        @click="deleteItem(img.id)"
                      >
                        mdi-delete
                      </v-icon>
                    </v-card-actions>
                  </v-card>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model.trim="currentBillboard.rate_card"
                  type="number"
                  min="0"
                  suffix="GH₵"
                  label="Price"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model.trim="currentBillboard.brand_name"
                  :rules="rules.genRule"
                  label="Brand Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model.trim="currentBillboard.address"
                  label="Location"
                  prepend-inner-icon="mdi-google-maps"
                  :rules="rules.genRule"
                  placeholder="Location Of Billboard"
                  ref="input"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :rules="rules.selectRule"
                  :items="lightingChoices"
                  v-model="currentBillboard.lighting"
                  label="Lighting"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :rules="rules.selectRule"
                  :items="mediumChoices"
                  v-model="currentBillboard.medium"
                  label="Medium"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :rules="rules.selectRule"
                  :items="orientationChoices"
                  v-model="currentBillboard.orientation"
                  label="Orientation"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :rules="rules.selectRule"
                  :items="sideOfRoadChoices"
                  v-model="currentBillboard.side_of_road"
                  label="Side of Road"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model.trim="currentBillboard.industry"
                  :items="industryChoices"
                  :rules="rules.genRule"
                  required
                  label="Industry"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model.trim="currentBillboard.site_run_up"
                  :rules="rules.site_run_upRules"
                  required
                  type="number"
                  label="Site Run Up"
                  suffix="Metres"
                  placeholder="Maximum distance the Billboard is visible"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model.trim="currentBillboard.faces"
                  :rules="rules.faceRules"
                  type="number"
                  label="Faces"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6"
                ><v-select
                  :rules="rules.selectRule"
                  :items="clutterChoices"
                  v-model="currentBillboard.clutter"
                  label="Clutter"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-select
                  :rules="rules.selectRule"
                  :items="heightChoices"
                  v-model="currentBillboard.height"
                  label="Height"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model.trim="currentBillboard.length"
                  :rules="rules.genRule"
                  label="Length"
                  suffix="Metres"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model.trim="currentBillboard.width"
                  :rules="rules.genRule"
                  label="Width"
                  suffix="Metres"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model.trim="currentBillboard.supplier"
                  label="Supplier Name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="submitForm"> Save </v-btn>
      </v-card-actions>

      <v-dialog v-model="deleteDialog" max-width="550px">
        <v-card
          class="white--text"
          :color="$vuetify.theme.themes.dark.background"
        >
          <v-progress-linear
            color="deep-purple accent-4"
            :active="loading"
            :indeterminate="loading"
          ></v-progress-linear>

          <v-card-title class="text-h5"
            >Are you sure you want to delete this Image?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteImageConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <alert></alert>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("billboards");

export default {
  data() {
    return {
      images64: [],
      image_id: null,

      loading: false,
      dialog: false,
      valid: false,
      rules: {
        imageRules: [(v) => !!v || "Image is required"],
        selectRule: [(v) => !!v || "Please Select an Option"],
        genRule: [(v) => !!v || "Please fill this."],
        site_run_upRules: [
          (v) => !!v || "Required",
          (v) => v >= 0 || "Distance should be above 0",
          (v) => v <= 200 || "Max should not be above 200",
        ],
        faceRules: [
          (v) => !!v || "Required",
          (v) => v >= 0 || "Faces should be above 0",
          (v) => v <= 4 || "Max should not be above 4",
        ],
      },
      heightChoices: ["Eye Level", "Moderate", "High"],
      clutterChoices: ["Solus", "Average", "Cluttered", "Solus"],
      lightingChoices: ["Frontlit", "Backlit", "Unlit", "LED"],
      mediumChoices: [
        "Wall Wrap",
        "RoadArch/Gantry",
        "Rooftops",
        "LED",
        "Unipole",
        "Medium",
        "Bus Stop",
        "Bus Stop Shelter",
      ],
      orientationChoices: ["Landscape", "Portrait", "Square"],
      sideOfRoadChoices: ["Left", "Middle", "Right"],
      industryChoices: [
        "None",
        "Agriculture",
        "Forestry",
        "Fishing & Hunting",
        "Mining",
        "Utilities",
        "Construction",
        "Manufacturing",
        "Wholesale Trade",
        "Retail Trade",
        "Transportation and Warehousing",
        "Real Estate and Rental and Leasing",
        "Professional, Scientific and Technical Services",
        "Educational Services",
      ],

      deleteDialog: false,
    };
  },

  props: {
    currentBillboard: {
      type: Object,
      default: () => ({
        Images: [],
        industry: null,
        site_run_up: null,
        faces: null,
        length: null,
        width: null,
        height: null,
        clutter: null,
        lighting: null,
        medium: null,
        orientation: null,
        side_of_road: null,
        address: null,
        latitude: null,
        location: null,
        longitude: null,
        rate_card: null,
        brand_name: null,
        direction: null,
        id: null,
        owner_id: null,
        status: true,
        bb_id: null,
        supplier: null,
      }),
    },
    operation: String,
  },

  methods: {
    ...mapActions(["addBillboards", "updateBillboard"]),

    deleteItem(id) {
      this.image_id = id;
      this.deleteDialog = true;
    },

    closeDelete() {
      this.deleteDialog = false;
    },

    deleteImageConfirm() {
      this.deleteImage();
      this.closeDelete();
      this.loading = false;
    },
    deleteImage() {
      const rmIdx = this.currentBillboard.images.findIndex(
        (img) => img.id === this.image_id
      );
      this.currentBillboard.images.splice(rmIdx, 1);
    },

    close() {
      this.dialog = false;
    },

    async submitForm() {
      this.$refs.form.validate();

      if (!this.valid) return;

      this.loading = true;
      const payload = { ...this.currentBillboard, newImages: this.images64 };

      if (this.operation === "edit") {
        await this.updateBillboard(payload);
      } else if (this.operation === "create") {
        await this.addBillboards(payload);
      }

      this.loading = false;

      // reset
      this.$refs.form.reset();
    },
    async reverseGeocode() {},
  },
};
</script>

<style>
.v-container {
  background: "#424242";
}
#create {
  background-color: #66bb6a;
  color: white;
  padding: 7px;
}

#edit {
  color: #66bb6a;
}
.previewImage {
  height: 150px;
  cursor: pointer;
}
.previewImage:hover {
  transform: scale(1.05);
  transition: all 0.2s;
}
</style>
