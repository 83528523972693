<template>
  <v-form v-model="isFormValid" ref="form" method="post">
    <AuthLayout cardWidth="10" noCard>
      <v-row class="justify-space-between">
        <v-col lg="5">
          <v-card class="booking-card">
            <div class="py-8">
              <h4 class="mb-2">Contact Details</h4>
              <BeautifulInput
                placeholder="Enter Phone"
                icon="mdi-phone-outline"
                type="tel"
                required
                @input="setPhone"
                :rules="rules.fieldRules"
              ></BeautifulInput>
              <BeautifulInput
                placeholder="Enter Email"
                icon="mdi-email-outline"
                required
                @input="setEmail"
                :rules="rules.emailRules"
              ></BeautifulInput>
              <div class="d-flex mt-8">
                <v-btn
                  rounded
                  class="text-none"
                  @click="submitForm"
                  :disabled="loading"
                  :loading="loading"
                  >Send</v-btn
                >
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col lg="5">
          <v-card class="booking-card">
            <h4 class="mb-8 text-center">Book a Date</h4>
            <v-date-picker
              full-width
              no-title
              dark
              required
              event-color="#b90000"
              :events="booked"
              :min="minDate"
              v-model="day"
              class="booking-calendar"
            >
            </v-date-picker>
          </v-card>
          <div class="d-flex justify-space-around mt-4 align-center">
            <select
              name="hh"
              id="hh"
              placeholder="HH"
              class="time-input"
              v-model="time.hr"
              required
            >
              <option disabled selected :value="null">Hr</option>
              <option v-for="n in [12, 1, 2, 3, 4]" :key="`hh-${n}`">
                {{ formatTime(n) }}
              </option>
            </select>
            <select
              name="mm"
              id="mm"
              placeholder="MM"
              class="time-input"
              v-model="time.min"
              required
            >
              <option disabled selected :value="null">Min</option>
              <option v-for="n in [0, 10, 20, 30, 40, 50]" :key="`mm-${n}`">
                {{ formatTime(n) }}
              </option>
            </select>
            <select
              name="day"
              id="day"
              placeholder="day"
              class="time-input"
              v-model="time.period"
              required
            >
              <option v-for="n in ['PM']" :key="`day-${n}`">{{ n }}</option>
            </select>
            <v-btn rounded dark class="white--border">GMT</v-btn>
          </div>
          <div
            class="white--text mt-4 text-center"
            v-if="localTime"
            style="font-size: 12px"
          >
            <em>Local Time: {{ localTime }}</em>
          </div>
        </v-col>
      </v-row>
    </AuthLayout>
    <Alert></Alert>
  </v-form>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import AuthLayout from "@/components/misc/AuthLayout.vue";
import BeautifulInput from "@/components/misc/BeautifulInput.vue";
import EventBus from "@/components/events/EventBus";
import Alert from "@/components/notifications/Alert";

export default {
  components: { AuthLayout, BeautifulInput, Alert },
  data() {
    return {
      email: null,
      phone: null,
      day: null,
      booked: [],
      time: { hr: null, min: null, period: "PM" },

      isFormValid: false,
      loading: false,

      minDate: dayjs(new Date()).format("YYYY-MM-DD"),
      rules: {
        emailRules: [
          (v) => !!v || "E-mail cannot be Empty",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        fieldRules: [(v) => !!v || "Phone number is required"],
      },
    };
  },
  computed: {
    localTime() {
      if (Object.values(this.time).every((item) => !!item) && !!this.day) {
        return dayjs(
          new Date(
            `${this.day} ${this.time.hr}:${this.time.min} ${this.time.period} GMT`
          )
        ).format("DD-MM-YYYY hh:mm A");
      }
      return null;
    },
  },
  methods: {
    formatTime(v) {
      return Number(v).toLocaleString("en-US", { minimumIntegerDigits: 2 });
    },
    setEmail(v) {
      this.email = String(v).trim();
    },
    setPhone(v) {
      this.phone = String(v).trim();
    },

    reset() {
      this.phone = null;
      this.email = null;
      this.day = null;
      this.time = { hr: null, min: null, period: "PM" };
      this.$refs.form.reset();
    },
    showErrors(err) {
      EventBus.$emit("notify", { message: err, status: "error", x: "center" });
    },
    async submitForm() {
      this.$refs.form.validate();

      if (!this.isFormValid) return;
      if (!this.day) return this.showErrors("Please pick a date");
      if (!Object.values(this.time).every((item) => !!item))
        return this.showErrors("Please fill in the time");
      if (this.booked.includes(this.day))
        return this.showErrors("Please choose an unmarked date");

      this.loading = true;

      axios
        .post("https://www.app.abonten.com/api/meetings", {
          email: this.email,
          subject: "Demo",
          description: "Demo",
          date: this.day,
          timeZone: "Africa/Accra",
          attendees: [],
          phone_number: this.phone,
        })
        .then(({ data: { meeting, message } }) => {
          this.booked = [
            ...this.booked,
            dayjs(meeting.date).format("YYYY-MM-DD"),
          ];
          this.loading = false;
          this.reset();
          EventBus.$emit("notify", {
            message,
            status: "success",
            x: "center",
            y: "top",
          });
        });
    },
  },
  mounted() {
    axios
      .get("https://www.app.abonten.com/api/meetings")
      .then(({ data: { data } }) => {
        this.booked = data.map((item) => dayjs(item.date).format("YYYY-MM-DD"));
      })
      .catch((e) => {
        console.log("***", e);
      });
  },
};
</script>

<style scoped>
h4 {
  font-size: 36px;
  font-weight: 400;
  color: #fff;
}

.booking-card {
  background: linear-gradient(
    253.09deg,
    rgba(217, 217, 217, 0.25) -7.53%,
    rgba(217, 217, 217, 0) 97.04%
  );
  backdrop-filter: blur(25px);
  border-radius: 33px;
  color: #fff;
  padding: 64px 60px;
}

.booking-calendar {
  /* background: linear-gradient(253.09deg, rgba(217, 217, 217, 0.25) -7.53%, rgba(217, 217, 217, 0) 97.04%) !important; */
  border-radius: 33px;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}

.compact-form {
  /* transform: scale(0.75); */
  border-radius: 8px;
}

.time-input {
  width: 100px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: #333;
  height: 100%;
}

.white--border {
  border: 1px solid rgba(255, 255, 255, 0.1);
}
</style>
