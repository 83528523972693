<template>
  <div class="select-container">
    <span
      class="option"
      v-for="(option, index) in list"
      :key="option.value"
      @click="() => onClickSelect(option, index)"
    >
      {{ option.label }}
      <img
        v-if="index === 0"
        src="/images/redesign/angle-right.svg"
        width="12"
        height="12"
      />
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expand: false,
      selected: null,
    };
  },
  props: {
    placeholder: String,
    options: Array,
    onSelect: Function,
  },
  computed: {
    list() {
      return [
        !this.selected || this.expand === true
          ? { label: this.placeholder, value: this.placeholder }
          : { label: this.selected.label, value: this.selected.value },
        ...(this.expand ? this.options : []),
      ];
    },
  },
  methods: {
    onClickSelect(option, index) {
      if (index === 0) {
        this.expand ? (this.expand = false) : (this.expand = true);
        return;
      }

      this.onSelect?.(option);
      this.selected = option;
      this.expand = false;
    },
  },
};
</script>

<style scoped>
.select-container {
  display: flex;
  flex-direction: column;
  width: 160px;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 8px 16px;
  border: 1px solid #fff;
  border-radius: 8px;
  margin-bottom: 1px;
  cursor: pointer;
  user-select: none;
}
</style>
