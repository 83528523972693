import defineAbilityFor from "./abilities";
import { getToken } from "@/helpers/utils";
import router from "../index";
import VueJwtDecode from "vue-jwt-decode";

export default async () => {
  // control user access of routes

  const token = await getToken();
  if (token) {
    const { payload } = VueJwtDecode.decode(token);
    const { scope } = payload;

    const abilities = defineAbilityFor(scope);

    router.beforeEach((to, from, next) => {
      const canNavigate = to.matched.some(async (route) => {
        return abilities.can("read", route.meta.resource);
      });
      if (!canNavigate) {
        next("/forbidden");
        return;
      }
      next();
    });
  }
};
