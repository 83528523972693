<template>
  <v-card
    width="100%"
    min-height="650"
    id="bus"
    class="py-5 px-2 mx-auto white--text"
    :color="$vuetify.theme.themes.dark.background"
  >
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-toolbar
      :color="$vuetify.theme.themes.dark.background"
      flat
      class="white--text mb-2"
    >
      <v-toolbar-title>All Monitoring Postings</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-data-table
        :items="allMonitorings"
        :headers="headers"
        :search="search"
        class="pa-3"
        :style="{
          'background-color': $vuetify.theme.themes.dark.background,
          color: $vuetify.theme.themes.dark.text,
        }"
        dark
        loading-text="Fetching Monitorings... Please wait."
        no-data-text="There are no Monitorings currently"
      >
        <template slot="top">
          <v-toolbar color="grey darken-3" flat>
            <v-btn class="white--text" @click="fetchMonitorings">
              Refresh
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-responsive max-width="40%" class="mx-auto mb-2">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-responsive>
            <v-spacer></v-spacer>

            <p class="white--text">
              Total Monitorings: {{ allMonitorings.length }}
            </p>
          </v-toolbar>
        </template>

        <template v-slot:[`item.images`]="{ item }">
          <div class="p-2">
            <div v-viewer class="images">
              <img
                class="image"
                :src="item.Image.path"
                :lazy-src="item.Image.path"
                alt="Billboard"
              />
            </div>
          </div>
        </template>

        <template v-slot:[`item.campaign_name`]="{ item }">
          <span class="blue--text text--lighten-1">
            {{ item.Candidate?.Plan?.name || "Not Available" }}
          </span>
        </template>

        <template v-slot:[`item.posting_date`]="{ item }">
          {{ parseDate(item.createdAt) }}
        </template>

        <template v-slot:[`item.address`]="{ item }">
          {{ item.Candidate?.Billboard?.address || "Not available" }}
        </template>

        <template v-slot:[`item.lighting`]="{ item }">
          {{ item.Candidate?.Billboard?.lighting || "Not available" }}
        </template>

        <template v-slot:[`item.dimensions`]="{ item }">
          {{ getDimensions(item) }}
        </template>
      </v-data-table>
    </v-card-text>

    <alert></alert>
  </v-card>
</template>

<script>
import { fetchAllMonitorings } from "@/helpers/monitoring";

export default {
  data() {
    return {
      loading: false,
      search: "",

      headers: [
        {
          text: "Posting",
          sortable: false,
          value: "images",
        },
        { text: "Campaign Name", value: "campaign_name" },
        { text: "Posted On", value: "posting_date" },
        { text: "Address", value: "address" },
        { text: "Lighting", value: "lighting" },
        { text: "Dimensions (Metres)", value: "dimensions" },
      ],
      allMonitorings: [],
    };
  },
  methods: {
    async fetchMonitorings() {
      this.loading = true;
      this.allMonitorings = await fetchAllMonitorings();
      this.loading = false;
    },
    parseDate(dateStr) {
      return new Date(dateStr).toDateString();
    },
    getDimensions(item) {
      return item.Candidate?.Billboard
        ? `${item.Candidate?.Billboard?.length} X ${item.Candidate?.Billboard?.width}`
        : item.Candidate?.Billboard?.width || "Not available";
    },
  },
  async created() {
    await this.fetchMonitorings();
  },
};
</script>

<style scoped>
.v-card {
  font-family: Manrope, Arial, sans-serif;
  box-shadow: 10px 10px 8px -6px rgba(225, 218, 218, 0.919) !important;
  -webkit-box-shadow: 10px 10px 8px -6px rgba(248, 243, 243, 0.931) !important;
  -moz-box-shadow: 10px 10px 8px -6px rgba(247, 238, 238, 0.939) !important;
  border-right: 1px solid white !important;
  border-top: 0.4px solid rgb(113, 111, 111) !important;
  border-left: 0.4px solid rgb(113, 111, 111) !important;
  border-bottom: 1px solid white !important;
}

.image {
  height: 50px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}

.image:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}
</style>
