import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions.js";

export default {
  namespaced: true,
  state() {
    return {
      regions: [],
    };
  },
  mutations,
  getters,
  actions,
};
