<template>
  <v-form v-model="valid" ref="loginForm">
    <AuthLayout>
      <h4 class="mb-8">Sign in</h4>
      <BeautifulInput
        label="Email"
        placeholder="Enter Email"
        icon="mdi-email-outline"
        required
        @input="setEmail"
        :rules="rules.emailRules"
        :error-messages="formErrors"
      ></BeautifulInput>
      <BeautifulInput
        label="Password"
        placeholder="Enter Password"
        icon="mdi-lock-open-outline"
        required
        @input="setPassword"
        :error-messages="formErrors"
        :rules="rules.pwdRules"
        :password="true"
      ></BeautifulInput>
      <div class="d-flex mt-8">
        <v-btn rounded class="text-none" @click="submitForm" :disabled="loading"
          >Sign In</v-btn
        >
        <v-btn rounded class="ml-4 text-none" to="/register">Register</v-btn>
      </div>
      <template v-slot:bottom>
        <p class="white--text text-center" style="font-weight: 300">
          Don't have an account?
          <a style="font-weight: normal" href="/register">Sign up</a>
        </p>
        <p class="white--text text-center" style="font-weight: 300">
          <a href="/forgot-password">Forgot password?</a>
        </p>
      </template>
    </AuthLayout>
  </v-form>
</template>

<script>
import { getToken } from "@/helpers/utils";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("auth");
import AuthLayout from "@/components/misc/AuthLayout.vue";
import BeautifulInput from "@/components/misc/BeautifulInput.vue";

export default {
  components: { AuthLayout, BeautifulInput },
  data() {
    return {
      rules: {
        pwdRules: [(v) => !!v || "Password is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
      },

      show: false,
      loading: false,
      valid: false,
      formErrors: [],
      formErrMsg: "false",

      email: null,
      password: null,
    };
  },
  methods: {
    ...mapActions(["login"]),
    async submitForm() {
      this.$refs.loginForm.validate();
      if (!this.valid) return;

      this.loading = true;

      const res = await this.login({
        email: this.email,
        password: this.password,
      });

      if (!res.user) {
        this.formErrMsg = res.error.message;
        this.$refs.loginForm.resetValidation();
        this.loading = false;
        return;
      }

      this.loading = false;
      this.$router.push(this.$route.query.returnUrl || "/insights");
    },
    setEmail(v) {
      this.email = String(v).trim();
    },
    setPassword(v) {
      this.password = String(v).trim();
    },
  },
  watch: {
    formErrMsg(val) {
      this.formErrors =
        [val] || "Something wen't wrong. Please contact the System Admin";
      this.loading = false;
    },
  },
  async mounted() {
    const token = await getToken();
    if (token) {
      this.$router.replace("/insights");
    }
    this.$refs.loginForm.reset();
  },
};
</script>

<style scoped>
h4 {
  font-size: 36px;
  font-weight: 400;
  color: #fff;
}
</style>
