<template>
  <section class="source-sans-pro">
    <div class="hero">
      <div v-if="country === null" class="hero-content">
        <beautiful-select
          placeholder="Select Country"
          :options="[
            { label: 'Ghana', value: 'gh' },
            { label: 'Nigeria', value: 'ng' },
            { label: 'Cameroon', value: 'cm' },
            { label: `Cote D'Ivoire`, value: 'ci' },
          ]"
          :onSelect="onSelectCountry"
        >
        </beautiful-select>
        <img width="80" height="80" src="/images/redesign/logo.png" />
      </div>
      <div v-if="country" class="hero-content">
        <div class="hero-content-auth">
          <v-btn
            outlined
            color="white"
            rounded
            to="/register"
            class="mr-2 mr-md-8 mb-2"
            >Register</v-btn
          >
          <v-btn outlined color="white" rounded class="" to="/login"
            >Login</v-btn
          >
        </div>
        <v-btn outlined color="white" @click="onClearCountry">
          {{ country.label }}
          <v-icon size="14" class="ml-8">mdi-arrow-left</v-icon>
        </v-btn>
      </div>

      <div class="hero-img">
        <img
          :src="backgrounds[country ? country.value : 'default']"
          alt="Hero Background"
        />
      </div>
    </div>

    <GradientStrip></GradientStrip>

    <div class="mantra">
      <v-container>
        <v-row class="justify-center">
          <v-col cols="8" sm="4">
            <h4>Plan</h4>
            <p>
              Create an OOH media plan with <br />
              pictures, photo illustration & <br />
              analytics of each site.
            </p>
          </v-col>
          <v-col cols="8" sm="4">
            <h4>Monitor</h4>
            <p>
              Monitor campaigns with up to date <br />
              intelligence data from a single <br />
              dashboard.
            </p>
          </v-col>
          <v-col cols="8" sm="4">
            <h4>Track</h4>
            <p>
              View and track all billboards with up to <br />
              date data and insights for each site.
            </p>
            <v-btn
              rounded
              class="text-none demo-btn"
              elevation="2"
              to="/watch-demo"
              >Watch A Demo</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div v-if="country" class="for-experts for-ebo-container">
      <v-container>
        <v-row class="align-center">
          <v-col lg="6">
            <span class="for-ebo-title">FOR MEDIA PLANNERS/ BUYERS</span>
            <h4 class="for-ebo-main-title">
              Abonten has powerful tools for media planners and buyers
            </h4>
            <p class="for-ebo-body">
              Our platform makes it easy foryou as buyer/planner to plan,
              optimize and create market intelligence reports for
              yourout-of-home campaigns.
            </p>
            <p class="for-ebo-body">
              Easilyto view plans by month, by brand and by category all at the
              click of a button.
            </p>
          </v-col>
          <v-col lg="6">
            <v-img src="/images/redesign/for-experts.png"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div v-if="country" class="for-brands for-ebo-container">
      <v-container>
        <v-row class="align-center flex-row-reverse">
          <v-col lg="6">
            <span class="for-ebo-title">FOR BRANDS</span>
            <h4 class="for-ebo-main-title">
              Leverage the Abonten platform to view any OOH plan for your brand
            </h4>
            <p class="for-ebo-body">
              View and review all Billboard opportunities available all over the
              country with access to gross rates.
            </p>
            <p class="for-ebo-body">
              Assess the potential impact of various OOH opportunities and view
              the latest market intelligence reports.
            </p>
          </v-col>
          <v-col lg="6">
            <v-img src="/images/redesign/for-brands.png"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div v-if="country" class="for-owners for-ebo-container">
      <v-container>
        <v-row class="align-center">
          <v-col lg="6">
            <span class="for-ebo-title">FOR MEDIA OWNERS</span>
            <h4 class="for-ebo-main-title">Media Owners, we have your back</h4>
            <p class="for-ebo-body">
              Showcase and manage your OOH media inventory within a single easy
              to use interface that enables discovery and inclusion in campaigns
              from brands! Gain insights on average costs of billboards for each
              location.
            </p>
          </v-col>
          <v-col lg="6">
            <v-img src="/images/redesign/for-owners.png"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div v-if="country" class="innovation">
      <v-container>
        <v-row class="justify-center">
          <h4 class="for-section-title mb-8">Innovative Technology</h4>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            lg="3"
            class="text-center"
            v-for="col in innovationRow"
            :key="col.title"
          >
            <v-img :src="col.img" height="80" contain class="mb-4"></v-img>
            <h4 class="mb-4">{{ col.title }}</h4>
            <p class="for-ebo-body">
              {{ col.content }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div v-if="country?.value === 'gh'" class="mps innovation">
      <v-container>
        <v-row class="justify-center">
          <h4 class="for-section-title mb-8">Current Media Partners</h4>
        </v-row>
        <v-row>
          <v-col lg="12" class="text-center">
            <v-img
              src="/images/redesign/mps.png"
              width="100%"
              contain
              class="mb-4"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div v-if="country" class="get-in-touch py-8">
      <v-container class="py-8 my-8">
        <div class="git-header">
          <h4>Get in touch</h4>
          <img src="/images/redesign/hi.png" alt="get in touch hi" />
        </div>
        <v-row class="py-8 mb-8">
          <v-col v-for="col in contact" :key="col.header" cols="12" sm="4">
            <div class="git-cols">
              <span class="git-cols-head">{{ col.header }}</span>
              <span class="git-cols-body">{{ col.content }}</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import BeautifulSelect from "@/components/misc/BeautifulSelect.vue";
import GradientStrip from "@/components/misc/GradientStrip.vue";

export default {
  components: { BeautifulSelect, GradientStrip },
  data() {
    return {
      show: false,
      backgrounds: {
        default: "/images/redesign/home-hero.png",
        gh: "/images/redesign/gh-hero.png",
        ng: "/images/redesign/ng-hero.png",
        cm: "/images/redesign/cm-hero.png",
        ci: "/images/redesign/ci-hero.png",
      },
      innovationRow: [
        {
          img: "/images/redesign/innovation-plan.png",
          title: "Plan",
          content:
            "Plan, buy, & measure traditional out-of-home advertising from the broadest selection of inventory",
        },
        {
          img: "/images/redesign/innovation-billboards.png",
          title: "View billboards",
          content:
            "View and review all Billboard opportunities available across the whole countrywith the gross rates for each outdoor media.",
        },
        {
          img: "/images/redesign/innovation-insights.png",
          title: "Get insights",
          content:
            "OOH analyticsand data – Get asnapshot access to competitive information and see how you brand is performing vis a vis other competitor brands.",
        },
        {
          img: "/images/redesign/innovation-support.png",
          title: "We support you",
          content:
            "Get the best support and advise and how to best optimise your media campaigns",
        },
      ],
      contact: [
        {
          header: "Call us at",
          content: "+233 2031 20384",
        },
        {
          header: "E-mail us at",
          content: "sonaivi@mediareachomd.com",
        },
        {
          header: "Our location",
          content: "157 Obenesu Close East Catonment Ext Accra",
        },
      ],
    };
  },
  methods: {
    onSelectCountry(option) {
      this.$store.dispatch("saveCountry", option);
    },
    onClearCountry() {
      this.$store.dispatch("saveCountry", null);
    },
  },
  computed: {
    ...mapState(["country"]),
  },
  mounted() {
    this.$store.dispatch("fetchCountry");

    for (const key in this.backgrounds) {
      if (Object.hasOwnProperty.call(this.backgrounds, key)) {
        const imgPath = this.backgrounds[key];
        const img = new Image();
        img.src = `${location.origin}${imgPath}`;
      }
    }
  },
};
</script>

<style scoped>
.hero {
  position: relative;
  height: 720px;
  width: 100%;
}

@media screen and (max-width: 576px) {
  .hero {
    height: 420px;
  }
}

.hero-img {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.hero-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hero-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  width: 100%;
  padding: 32px;
}

.hero-content-auth {
  display: flex;
  flex-wrap: wrap;
}

.mantra {
  background-color: #d83b33;
  padding: 40px 0;
}

.mantra h4 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 16px;
}

.mantra p {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.demo-btn {
  background: #262261 !important;
  color: #fff !important;
}

.for-ebo-title {
  color: #ce3f32;
  font-size: 17px;
  text-transform: uppercase;
}

.for-ebo-main-title {
  color: #323c40;
  font-size: 36px;
  line-height: 43px;
  margin-bottom: 39px;
}

.for-ebo-body {
  color: #586368;
  font-size: 16px;
  font-weight: 400;
}

.for-ebo-container {
  padding: 120px 0;
}

.for-experts {
  background: #f3f5f7;
}

.for-brands {
  background: #fff;
}

.for-owners {
  background: #f1f0fa;
}

@media (prefers-color-scheme: dark) {
  .for-experts,
  .for-owners {
    background-color: rgb(30, 32, 33) !important;
  }
  .for-brands,
  .innovation,
  .get-in-touch {
    background-color: rgb(24, 26, 27) !important;
  }

  .innovation,
  .get-in-touch {
    color: rgb(171, 164, 153);
  }
  .for-ebo-main-title,
  .for-ebo-body {
    color: rgb(171, 164, 153);
  }
}

.for-section-title {
  color: #ce3f32;
  font-size: 19px;
  text-transform: uppercase;
  text-align: center;
}

.innovation {
  padding: 16px 0;
  background-color: #fff;
}

.get-in-touch {
  background-color: #fff;
}

.get-in-touch .git-header {
  display: flex;
  justify-content: center;
}

.get-in-touch h4 {
  font-size: 37px;
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
}

.get-in-touch h4::after {
  content: " ";
  display: block;
  height: 14px;
  background-color: #e8ad4e;
  width: 170px;
  position: absolute;
  right: 0;
}

.get-in-touch .git-cols {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #dddddd;
  height: 160px;
}

.get-in-touch .git-cols-head {
  color: #323c40;
  font-weight: 600;
}

.get-in-touch .git-cols-body {
  color: #8c8c8c;
}
</style>
