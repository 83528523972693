import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      briefs: [],
      // currently handle one brief at a time
      briefsCart: {
        region: [],
        sec: [],
        ageGroup: [],
        gender: [],
        start_date: null,
        end_date: null,
        duration: null,
        id: null,
        campaignType: null,
        description: null,
        createdAt: null,
        budget: null,
        BrandOwner: {
          Planner: {
            User: { client: null, organization: null },
          },
          Marketer: null,
          Brand: {
            name: null,
          },
        },
      },
      currentBrief: null,
      briefCampaigns: [],
    };
  },
  mutations,
  getters,
  actions,
};
