<template>
  <nav>
    <v-app-bar
      height="80"
      elevation="0"
      :style="{
        'background-color': $vuetify.theme.themes.dark.background,
        text: $vuetify.theme.themes.dark.text,
      }"
    >
      <v-avatar size="150" class="mr-2">
        <v-img :src="'/images/abonten_logo.png'" contain alt="logo"></v-img>
      </v-avatar>

      <v-divider vertical></v-divider>

      <v-toolbar-items class="hidden-sm-and-down ml-16">
        <v-btn
          id="btn"
          class="mx-1 white--text"
          active-class="primary"
          v-for="nav in linkItems"
          :key="nav.resource"
          :to="nav.link"
          text
          >{{ nav.title }}
        </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-toolbar-items>
        <div v-if="bbsInCart && canViewCart">
          <v-badge
            :content="bbsInCart"
            color="primary"
            class="v-toolbar__items__el"
          >
            <v-btn to="/cart" id="cart" text icon>
              <v-icon color="white">mdi-cart </v-icon>
            </v-btn>
          </v-badge>
        </div>
        <v-icon class="v-toolbar__items__el" color="white">mdi-bell </v-icon>

        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="white">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list :color="$vuetify.theme.themes.dark.background">
            <v-list-item>
              <v-btn
                :color="$vuetify.theme.themes.dark.background"
                class="white--text"
                outlined
                elevation="0"
                to="/account-details"
                >Account Details</v-btn
              >
            </v-list-item>
            <v-list-item>
              <v-btn
                :color="$vuetify.theme.themes.dark.background"
                class="white--text"
                outlined
                @click="logoutClient"
                elevation="0"
              >
                Logout
                <v-icon
                  title="Logout"
                  class="v-toolbar__items__els"
                  color="secondary"
                  >mdi-logout
                </v-icon></v-btn
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <the-date></the-date>
  </nav>
</template>

<script>
import TheDate from "@/layouts/TheDate.vue";

import { mapActions, mapGetters } from "vuex";

import defineAbilityFor from "@/router/guards/abilities";
import { getToken } from "@/helpers/utils";
import VueJwtDecode from "vue-jwt-decode";

export default {
  data() {
    return {
      navItems: [
        {
          title: "Users",
          link: "/all-users",
          resource: "all-users",
        },
        { title: "Billboards", link: "/billboards", resource: "billboards" },
        {
          title: "Billboards",
          link: "/all-billboards",
          resource: "all-billboards",
        },
        {
          title: "Brands",
          link: "/brands",
          resource: "brands",
        },
        {
          title: "Brands",
          link: "/all-brands",
          resource: "all-brands",
        },
        { title: "Briefs", link: "/briefs", resource: "briefs" },
        { title: "Briefs", link: "/all-briefs", resource: "all-briefs" },
        { title: "Campaigns", link: "/campaigns", resource: "campaigns" },
        {
          title: "Campaigns",
          link: "/all-campaigns",
          resource: "all-campaigns",
        },
        {
          title: "Campaigns",
          link: "/owner-campaigns",
          resource: "owner-campaigns",
        },
        {
          title: "Campaigns",
          link: "/proposed-campaigns",
          resource: "proposed-campaigns",
        },
        { title: "Monitoring", link: "/monitoring", resource: "monitoring" },
        {
          title: "Monitoring",
          link: "/all-monitoring",
          resource: "all-monitoring",
        },
        {
          title: "Marketing Insights",
          link: "/insights",
          resource: "insights",
        },
        {
          title: "Inspections",
          link: "/inspections",
          resource: "inspections",
        },
      ],
      subMenuItems: [
        { title: "Profile Settings", link: "/account-details" },
        { title: "Logout" },
      ],
      abilities: null,
    };
  },
  components: { TheDate },

  computed: {
    ...mapGetters({
      getBbCart: "billboards/getBbCart",
      getCurrentUser: "getCurrentUser",
    }),

    bbsInCart() {
      return this.getBbCart.length;
    },
    linkItems() {
      // control user access of nav bar items
      return this.navItems.filter((btn) => {
        if (this.abilities) {
          return this.abilities.can("read", btn.resource);
        }
      });
    },
    canViewCart() {
      return this.abilities.can("read", "cart");
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      fetchBbCart: "billboards/fetchBbCart",
    }),

    async logoutClient() {
      this.$Tawk?.$endChat();
      await this.logout();
      this.$router.replace("/login");
    },
  },
  async created() {
    const token = await getToken();
    const { payload } = VueJwtDecode.decode(token);
    const { scope } = payload;

    if (!scope) return await this.logoutClient();

    this.abilities = defineAbilityFor(scope);
  },
  async mounted() {
    await this.fetchBbCart();
  },
};
</script>
<style scoped>
.v-toolbar__title {
  font-family: DM, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 30px;

  /* identical to box height, or 107% */
  letter-spacing: -0.4px;

  /* Primary #1 */
  color: #ec2327;
  padding: 0 10px;
}

.v-toolbar__items {
  display: flex;
  height: inherit;
  justify-content: center;
  align-items: center;
}

.v-toolbar__items > .v-btn {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.v-btn::before {
  background-color: transparent;
}

.v-toolbar__items__el {
  margin: 7px;
}

.v-btn {
  text-transform: none;
}

#btn {
  height: 40px !important;
  border-radius: 20px !important;
  padding: 8px 16px 8px 16px;
  text-transform: none;
}

#btn:hover {
  background: #ec2327;
  color: #ffffff;
  padding: 8px 16px !important;
  border-radius: 20px;
}

#cart {
  margin: 0;
  height: 24px;
  width: 24px;
}

.news {
  font-family: Manrope, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
}
</style>
