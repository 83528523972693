<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-row class="my-2 grey darken-2" justify="end" align="center">
      <v-col cols="12" lg="2">
        <p class="white--text">
          Showing
          <span class="secondary--text">
            {{ inspections.length }}
          </span>
          results
        </p>
      </v-col>
      <v-col cols="12" lg="2">
        <v-menu
          ref="menu"
          v-model="startDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="Start date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dark
            ></v-text-field>
          </template>
          <v-date-picker
            dark
            v-model="startDate"
            :active-picker.sync="activePicker1"
            :max="
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            "
            min="1950-01-01"
            @change="save"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" lg="2">
        <v-menu
          ref="menu"
          v-model="endDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              label="End date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dark
            ></v-text-field>
          </template>
          <v-date-picker
            dark
            v-model="endDate"
            :active-picker.sync="activePicker2"
            :max="
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            "
            min="1950-01-01"
            @change="save"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" lg="2">
        <v-btn depressed color="primary" @click="fetchInsp">Submit</v-btn>
      </v-col>
    </v-row>

    <GmapMap
      ref="googleMap"
      :center="center"
      :zoom="zoom"
      class="map"
      :options="mapOptions"
    >
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
        <v-card
          v-if="currentInspection"
          elevation="0"
          class="mx-auto"
          max-width="350"
        >
          <v-tabs centered slider-color="secondary" v-model="tab" grow>
            <v-tab ripple href="#images">Images</v-tab>
            <v-tab ripple href="#features">Features</v-tab>
            <v-tab v-if="getPopulation" ripple href="#population"
              >Population</v-tab
            >
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="images">
              <v-card>
                <v-card-text>
                  <v-container fluid>
                    <v-row justify="center" dense v-viewer>
                      <v-col
                        v-for="img in currentInspection.images"
                        :key="img.id"
                        cols="12"
                        md="4"
                      >
                        <v-card elevation="0">
                          <img
                            class="white--text align-end image"
                            gradient="to bottom, rgba(0,0,0,.1),rgba(0,0,0,.5)"
                            height="200px"
                            :src="img.path"
                            alt="Billboard Image"
                            :lazy-src="img.path"
                            loading="lazy"
                          />
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="features">
              <v-card elevation="0">
                <billboard-features
                  :current-billboard="currentInspection"
                  :oppContact="oppContact"
                  :visibilityAdj="visibilityAdj"
                ></billboard-features>
              </v-card>
            </v-tab-item>
            <v-tab-item value="population">
              <v-card>
                <v-card-text>
                  <population-view
                    :population="getPopulation"
                  ></population-view>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </gmap-info-window>

      <div id="mapStyle">
        <v-radio-group v-model="mapStyle">
          <v-radio label="Dark" value="dark" color="black"></v-radio>
          <v-radio label="Light" value="light" color="grey"></v-radio>
        </v-radio-group>
      </div>
    </GmapMap>
  </v-container>
</template>

<script>
import { fetchFilteredInspections } from "../../helpers/inspections";
import { gmapApi } from "gmap-vue";

import { MarkerClusterer } from "@googlemaps/markerclusterer";
import filterScores from "@/mixins/filterScores.js";
import BillboardFeatures from "../../components/billboard/BillboardFeatures.vue";

import { mapGetters, mapActions } from "vuex";
import { styles } from "@/components/map/mapstyle.js";
import PopulationView from "../../components/population/PopulationView.vue";

export default {
  data() {
    return {
      loading: true,

      map: null,
      api: null,
      apiLoaded: false,

      xmin: -3.269471,
      ymin: 4.736839,
      xmax: 1.21373,
      ymax: 11.219829,

      bounds: null,
      mapOptions: {
        zoomControl: true,
        zoomControlOptions: { position: 9 },
        mapTypeControl: true,
        mapTypeControlOptions: { position: 11 },
        scaleControl: false,
        streetViewControl: true,
        streetViewControlOptions: { position: 9 },
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        styles: styles.dark,
      },
      zoom: 13,
      center: { lat: 5.623028, lng: -0.176527 },

      totalInspections: 0,
      inspections: [],
      inspClusterer: [],
      inspMarkers: [],
      currentInspection: {},

      currentBbScore: 0,
      visibilityAdj: 0,
      totalBbScore: 17,
      oppContact: 0,

      // tabs for infowindow
      tab: null,

      // popup window
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },

      activePicker1: null,
      activePicker2: null,
      startDate: null,
      startDateMenu: false,

      endDate: null,
      endDateMenu: false,

      mapStyleToggle: null,
      mapStyle: "dark",
    };
  },

  computed: {
    ...mapGetters({
      getPopulation: "billboards/getPopulation",
    }),

    google: gmapApi,

    mapReady() {
      return this.google && this.map !== null;
    },
  },

  components: {
    BillboardFeatures,
    PopulationView,
  },
  watch: {
    mapStyle: {
      handler(val) {
        this.map.setOptions({ styles: styles[val] });
      },
    },
  },
  methods: {
    ...mapActions({
      fetchPop: "billboards/fetchPop",
    }),

    plotInspections() {
      if (!this.inspections.length) return;

      this.totalInspections = this.inspections.length;
      this.loading = true;
      this.bounds = new this.google.maps.LatLngBounds();

      this.inspMarkers = this.inspections.map((bb) => {
        let latlng = new this.google.maps.LatLng(
          parseFloat(+bb.latitude),
          parseFloat(+bb.longitude)
        );

        this.bounds.extend(latlng);
        let icon = {
          url: "/images/billboardColored.png",
          scaledSize: new this.google.maps.Size(25, 25),
        };

        let inspMarker = new this.google.maps.Marker({
          position: latlng,
          icon: icon,
          data: {
            lighting: bb.lighting,
            height: bb.height,
            orientation: bb.orientation,
            clutter: bb.clutter,
            site_run_up: +bb.site_run_up,
          },
        });

        // set listener
        this.google.maps.event.addListener(inspMarker, "click", async () => {
          this.currentInspection = bb;

          // position of the Popup window
          this.infoWindowPos = {
            lat: +this.currentInspection.latitude,
            lng: +this.currentInspection.longitude,
          };

          // get population around the area
          await this.fetchPop({
            lat: +this.currentInspection.latitude,
            long: +this.currentInspection.longitude,
          });

          // open info window
          this.infoWinOpen = true;

          //calculate score
          this.getBBScore();
        });

        return inspMarker;
      });

      this.inspClusterer.clearMarkers();
      this.inspClusterer.addMarkers(this.inspMarkers);
      this.map.fitBounds(this.bounds);
      this.map.panToBounds(this.bounds);
      this.loading = false;
    },

    getBBScore() {
      const lightSc =
        this.filterScores["lighting"][
          this.currentInspection.lighting.toLowerCase()
        ].score;

      const hgtSc =
        this.filterScores["height"][this.currentInspection.height.toLowerCase()]
          .score;

      const strnSc = this.filterScores["site_run_up"](
        +this.currentInspection.site_run_up
      ).score;

      const ortnSc =
        this.filterScores["orientation"][
          this.currentInspection.orientation.toLowerCase()
        ].score;

      const clutSc =
        this.filterScores["clutter"][
          this.currentInspection.clutter.toLowerCase()
        ].score;

      this.currentBbScore = lightSc + hgtSc + strnSc + ortnSc + clutSc;

      this.visibilityAdj = `${Math.round(
        (100 * this.currentBbScore) / this.totalBbScore
      )}%`;

      this.oppContact = Math.round(
        (this.currentBbScore / this.totalBbScore) * this.getPopulation.totalPop
      );
    },

    save(date) {
      this.$refs.menu.save(date);
    },

    prePopulateData() {
      this.endDate = new Date().toISOString().substring(0, 10);

      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      this.startDate = oneWeekAgo.toISOString().substring(0, 10);
    },

    async fetchInsp() {
      if (this.startDate && this.endDate) {
        this.loading = true;
        this.inspections = await fetchFilteredInspections(
          `start_date=${this.startDate}&end_date=${this.endDate}`
        );
        this.plotInspections();
        this.loading = false;
      }
    },
  },

  menu(val) {
    val && setTimeout(() => (this.activePicker = "YEAR"));
  },

  async mounted() {
    this.loading = true;
    this.map = await this.$refs.googleMap.$mapPromise;
    this.api = await this.$gmapApiPromiseLazy();
    this.apiLoaded = !!this.api;

    this.mapStyleToggle = document.querySelector("#mapStyle");

    this.map.controls[this.google.maps.ControlPosition.LEFT_TOP].push(
      this.mapStyleToggle
    );

    // Billboard cluster
    this.inspClusterer = new MarkerClusterer({
      map: this.map,
      markers: [],
      averageCenter: true,
    });
    this.prePopulateData();

    this.inspections = await fetchFilteredInspections(
      `start_date=${this.startDate}&end_date=${this.endDate}`
    );

    this.plotInspections();
    this.loading = false;
  },

  mixins: [filterScores],
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 70vh;
}
#infoWindow {
  max-width: 350px;
  margin: 10px;
}

.images {
  display: flex;
  justify-content: baseline;
  align-items: center;
  margin-bottom: 10px;
  transition: all 0.2s;
}
.image {
  cursor: pointer;
  margin: 5px;
}
.image:hover {
  transform: scale(1.07);
  transition: all 0.2s;
}
#infoTab {
  box-shadow: 0 0 15px rgb(0 0 0 20%);
}

#legend {
  margin-top: 10px;
}
.v-card__text {
  font-family: Manrope, Arial, sans-serif;
  font-size: 1.5em;
  text-align: center;
}

#poiCard > .v-card__text {
  font-family: Manrope, Arial, sans-serif;
  padding: 10px;
  font-size: 1rem;
  color: black !important;
}

#mapStyle {
  background: #fff176;
  padding: 0 10px;
  margin-top: 10px;
}
</style>
