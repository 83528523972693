<template>
  <div class="input-container mt-2">
    <label class="ml-6" v-if="label">{{ label }}</label>
    <v-text-field
      v-if="type !== 'select'"
      :placeholder="placeholder"
      :rules="rules"
      :error-messages="errorMessages"
      :append-icon="
        password === true
          ? inputType === 'password'
            ? 'mdi-eye'
            : 'mdi-eye-off'
          : undefined
      "
      :type="inputType"
      @click:append="show = !show"
      @input="handleInput"
      outlined
      rounded
      dark
      class="mt-2"
      v-bind="$attrs"
    >
      <v-icon slot="prepend-inner" class="pr-2">{{ icon }}</v-icon>
    </v-text-field>
    <v-select
      v-if="type === 'select'"
      :placeholder="placeholder"
      :rules="rules"
      :error-messages="errorMessages"
      :append-icon="
        password === true
          ? inputType === 'password'
            ? 'mdi-eye'
            : 'mdi-eye-off'
          : undefined
      "
      :type="inputType"
      @click:append="show = !show"
      @input="handleInput"
      outlined
      rounded
      dark
      class="mt-2"
      v-bind="$attrs"
    >
      <v-icon slot="prepend-inner" class="pr-2">{{ icon }}</v-icon>
    </v-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  props: {
    label: String,
    placeholder: String,
    icon: String,
    appendIcon: String,
    type: String,
    rules: Array,
    errorMessages: Array,
    password: Boolean,
  },
  methods: {
    handleInput(v) {
      this.$emit("input", v);
    },
    setFormErrorToBeError() {
      this.errorMessages = [];
    },
  },
  computed: {
    inputType() {
      if (this.show || this.password !== true) return this.type ?? "text";
      return "password";
    },
  },
  mounted() {
    // console.log('***', this.password);
  },
};
</script>

<style scoped>
.input-container input {
  display: block;
  margin-top: 4px;
  border: 1px solid #fff;
  width: 100%;
}

/* .v-text-field--outlined {
    color: blue !important;
	} */
</style>
