<template>
  <v-card
    width="100%"
    min-height="650"
    id="bus"
    elevation="0"
    class="px-2 mx-auto white--text"
    :color="$vuetify.theme.themes.dark.background"
  >
    <v-toolbar elevation="0" color="grey darken-3">
      <v-row justify="center">
        <span class="text-h5 white--text">PROPOSED CAMPAIGNS</span>
      </v-row>
    </v-toolbar>

    <v-data-table
      :items="getBriefCampaigns.Plans"
      :headers="headers"
      :search="search"
      sort-by="plan_name"
      class="elevation-1 pa-3"
      no-data-text="No campaigns have been created yet."
      loading-text="Fetching Campaigns... Please wait."
      :style="{
        'background-color': $vuetify.theme.themes.dark.background,
        color: $vuetify.theme.themes.dark.text,
      }"
      dark
    >
      <template slot="top">
        <v-toolbar color="grey darken-3" flat>
          <v-btn class="white--text" @click="fetchProposedCampaigns">
            Refresh
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-responsive max-width="40%" class="mx-auto mb-2">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-responsive>
        </v-toolbar>
      </template>

      <template v-slot:[`item.organization`]="{ item }">
        {{ getOrg(item.Planner) }}
      </template>

      <template v-slot:[`item.planner`]="{ item }">
        {{ getPlanner(item.Planner) }}
      </template>

      <template v-slot:[`item.budget`]>
        <span id="formatBudget">
          {{ getBudget() }}
        </span>
      </template>

      <template v-slot:[`item.total`]="{ item }">
        <span id="formatBudget">
          {{ getTotal(item.Candidates) }}
        </span>
      </template>

      <template v-slot:[`item.duration`]> {{ getDuration() }} Days </template>

      <template v-slot:[`item.sites`]="{ item }">
        {{ item.Candidates.length }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-if="!viewOnMonitoring"
          text
          small
          class="createCampaign"
          @click.stop="selectCampaign(item.id), (showCampaignDialog = true)"
        >
          More Information
        </v-btn>

        <v-btn
          id="selectCurrentCampaign"
          small
          elevation="0"
          v-if="viewOnMonitoring"
          @click="selectCampaign(item.id)"
        >
          Select Campaign
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="showCampaignDialog" width="90%">
      <v-toolbar :color="$vuetify.theme.themes.dark.background">
        <v-spacer></v-spacer>

        <v-btn icon @click="showCampaignDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <campaign-item></campaign-item>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import * as dayjs from "dayjs";
import CampaignItem from "../campaign/CampaignItem.vue";

export default {
  components: { CampaignItem },
  data() {
    return {
      search: "",
      loading: false,

      currentCampaign: {},
      currentCampaignID: null,
      showCampaignDialog: false,

      headers: [
        { text: "Company Name", align: "start", value: "organization" },
        { text: "Campaign Name", value: "name" },
        { text: "Campaign By", value: "planner" },
        { text: "Duration", value: "duration" },
        { text: "Budget(GH₵)", value: "budget" },
        { text: "Total(GH₵)", value: "total" },
        { text: "Sites", value: "sites" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getBriefCampaigns: "briefs/getBriefCampaigns",
      getCurrentBrief: "briefs/getCurrentBrief",
    }),
  },

  props: { viewOnMonitoring: Boolean },

  emits: ["select-campaign"],

  watch: {
    getCurrentBrief: {
      async handler(val) {
        if (!val) return;

        await this.fetchBriefCampaigns(val);
      },
    },
  },

  methods: {
    ...mapMutations({
      setCurrentCampaign: "campaigns/setCurrentCampaign",
    }),

    ...mapActions({
      fetchCampaign: "campaigns/fetchCampaign",

      fetchBriefCampaigns: "briefs/fetchBriefCampaigns",
    }),

    async fetchProposedCampaigns() {
      this.loading = true;
      await this.fetchBriefCampaigns(this.getCurrentBrief);
      this.loading = false;
    },

    getDuration() {
      let end_date = dayjs(this.getBriefCampaigns.end_date);
      let start_date = dayjs(this.getBriefCampaigns.start_date);

      let diff = end_date.diff(start_date, "d");

      if (!diff) return 0;
      return this.parseNumber(diff);
    },
    getOrg(planner) {
      return planner.User.organization;
    },
    getPlanner(planner) {
      return planner.User.plannerName;
    },
    getBudget() {
      return this.getBriefCampaigns.estimate;
    },

    getTotal(bbs) {
      let x = bbs.reduce((acc, { Billboard: bb }) => {
        return acc + bb.rate_card;
      }, 0);

      return this.parseNumber(x);
    },

    parseNumber(amount) {
      return new Intl.NumberFormat().format(amount);
    },

    async selectCampaign(campaignId) {
      this.currentCampaign = await this.fetchCampaign(campaignId);
      this.setCurrentCampaign(this.currentCampaign);
      this.$emit("select-campaign");
    },
  },
  async created() {
    await this.fetchProposedCampaigns();
  },
};
</script>

<style scoped>
.v-card {
  font-family: Manrope, Arial, sans-serif;
}
.v-toolbar {
  font-size: 24px;
  margin: 0 !important;
}
.v-data-table-header {
  background: #f9f9fa;
}
#formatBudget {
  background: rgba(32, 201, 172, 0.1);
  height: 48px !important;
  color: #20c9ac;
  padding: 5px 14px;
}
.v-data-table__wrapper tr {
  min-height: 70px !important;
}

#selectCurrentCampaign {
  text-transform: none;
  background: rgba(0, 165, 255, 0.1);
  border-radius: 4px;
  padding: 15px 5px;

  color: #00a5ff;
  height: 24px;
}
.v-btn {
  margin: 15px 0;
  text-transform: none;
}
.createCampaign {
  background: rgba(0, 165, 255, 0.1);
  border-radius: 4px;
  padding: 15px 5px;
  color: #00a5ff;
  height: 24px;
}
</style>
