<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col id="driver" cols="12" sm="3" lg="3" xl="3" md="3">
        <v-card
          :color="$vuetify.theme.themes.dark.background"
          class="white--text"
          width="100%"
          elevation="0"
        >
          <v-btn
            color="expansionPanels"
            elevation="0"
            v-for="btn in buttons"
            :key="btn.name"
            @click="setComponent(btn.cmp)"
            :class="{ active: btn.cmp === currentComponent }"
            :id="btn.id"
          >
            <v-icon color="black">{{ btn.icon }}</v-icon>
            {{ btn.name }}
          </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" sm="9" lg="9" xl="9" md="9">
        <keep-alive>
          <component :is="currentComponent" :buttons="helpButtons"></component>
        </keep-alive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BriefsList from "@/components/briefs/BriefsList.vue";
import CreateBrief from "@/components/briefs/CreateBrief.vue";
import DocumentationHelper from "../../components/DocumentationHelper.vue";

export default {
  data() {
    return {
      currentComponent: "BriefsList",
      buttons: [
        {
          name: "Create Briefs",
          icon: "mdi-compass-outline",
          cmp: "CreateBrief",
        },
        {
          name: "View Briefs",
          icon: "mdi-home",
          cmp: "BriefsList",
        },
        {
          name: "Help/Support",
          icon: "mdi-desktop-mac",
          cmp: "DocumentationHelper",
        },
      ],

      helpButtons: [
        {
          name: "Create Briefs Help",
          cmp: "/abonten_manual/briefs/create/create_brief.md",
        },
        {
          name: "View Briefs Help",
          cmp: "/abonten_manual/briefs/view/view_brief.md",
        },
      ],
    };
  },
  components: { BriefsList, CreateBrief, DocumentationHelper },
  methods: {
    setComponent(cmp) {
      this.currentComponent = cmp;
    },
  },
};
</script>

<style scoped>
#driver {
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.v-btn {
  margin: 10px;
  height: 48px !important;
  width: 90%;
  text-transform: none;
}

.active {
  background: #f68625 !important;
}
</style>
