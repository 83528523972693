import { handleHttpError, baseUrl, getToken, reportSuccess } from "./utils";
const axios = require("axios").default;

async function createSubscription(payload) {
  try {
    const token = await getToken();
    const response = await axios.post(
      `${baseUrl}/subscriptions/`,
      {
        ...payload,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.data.data.message) {
      const message = `Created subscription successfully.`;
      reportSuccess(message);
      return response.data.data;
    }
  } catch (err) {
    const error =
      `Error creating subscription ${err.response.data.message}` ||
      "An error occurred creating subscription. Please try again or contact the system admin";

    handleHttpError(error);
  }
}

async function fetchMySubscriptions() {
  try {
    const token = await getToken();
    const response = await axios.get(`${baseUrl}/subscriptions`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (err) {
    const error =
      `Error fetching subscriptions ${err.response.data.message}` ||
      "An error occurred fetching subscriptions. Please try again or contact the system admin";

    handleHttpError(error);
  }
}

export { createSubscription, fetchMySubscriptions };
