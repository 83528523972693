<template>
  <div>
    <top-nav></top-nav>

    <router-view></router-view>
  </div>
</template>

<script>
import TopNav from "@/layouts/TopNav.vue";

export default {
  name: "App",
  components: {
    TopNav,
  },
};
</script>
