<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <GmapMap
      ref="googleMap"
      :center="center"
      :zoom="zoom"
      class="map"
      :options="mapOptions"
    >
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
        <v-card
          v-if="currentBillboard"
          elevation="0"
          class="mx-auto"
          max-width="350"
        >
          <v-tabs centered slider-color="secondary" v-model="tab" grow>
            <v-tab ripple href="#images">Images</v-tab>
            <v-tab ripple href="#features">Features</v-tab>
            <v-tab ripple href="#population">Population</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="images">
              <v-card>
                <v-card-text>
                  <v-container fluid>
                    <v-row dense v-viewer>
                      <v-col
                        v-for="img in currentBillboard.Images"
                        :key="img.id"
                        cols="12"
                        md="4"
                      >
                        <v-card elevation="0">
                          <img
                            class="white--text align-end image"
                            gradient="to bottom, rgba(0,0,0,.1),rgba(0,0,0,.5)"
                            height="200px"
                            :src="img.path"
                            alt="Billboard Image"
                            :lazy-src="img.path"
                            loading="lazy"
                          />
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="features">
              <v-card elevation="0">
                <billboard-features
                  :currentBillboard="currentBillboard"
                  :oppContact="oppContact"
                  :visibilityAdj="visibilityAdj"
                ></billboard-features>
              </v-card>
            </v-tab-item>
            <v-tab-item value="population">
              <v-card>
                <v-card-text>
                  <population-view
                    :population="getPopulation"
                  ></population-view>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="addToCart"> Book Now </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </gmap-info-window>
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="poiInfoWinOpen"
        @closeclick="poiInfoWinOpen = false"
      >
        <v-card
          v-if="currentPoi"
          elevation="0"
          class="mx-auto"
          id="poiCard"
          max-width="350"
        >
          <v-card-text>
            <p>
              Name: <strong>{{ currentPoi.name || "Not Known" }}</strong>
            </p>
          </v-card-text>
        </v-card>
      </gmap-info-window>
    </GmapMap>

    <div class="mx-auto" id="legend" ref="legend">
      <legend-area @enable-poi="addPoi" @enable-traffic="getTraffic">
        <template slot="allBillboards">
          <div class="d-flex justify-space-between align-center">
            <img
              height="20px"
              class="pa-0 mt-2"
              src="images/billboardColored.png"
              alt="billboard"
            />
            Billboards
            <input
              id="billboardChecked"
              type="checkbox"
              v-model="bbChecked"
              @change="addBBs"
            />
          </div>
        </template>
      </legend-area>
    </div>

    <v-dialog max-width="450" v-model="showCartDialog">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>Billboard has been added to cart.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn width="200" @click="showCartDialog = false" outlined>
            Continue Browsing</v-btn
          >
          <v-btn color="secondary" width="100" to="/cart">Checkout</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div id="zoomAddress">
      <gmap-autocomplete
        placeholder="Enter Address"
        class="autoComplete v-text-field v-text-field-outlined"
        @place_changed="setPlace"
        id="address"
        :options="autoCompleteBounds"
      >
      </gmap-autocomplete>
    </div>

    <div id="mapStyle">
      <v-radio-group v-model="mapStyle">
        <v-radio label="Dark" value="dark" color="black"></v-radio>
        <v-radio label="Light" value="light" color="grey"></v-radio>
      </v-radio-group>
    </div>
  </v-container>
</template>

<script>
import { gmapApi } from "gmap-vue";

import { MarkerClusterer } from "@googlemaps/markerclusterer";
import filterScores from "@/mixins/filterScores.js";

import { mapGetters, mapActions } from "vuex";
import BillboardFeatures from "../../components/billboard/BillboardFeatures.vue";
import LegendArea from "../../components/map/LegendArea.vue";

import { reportSuccess } from "@/helpers/utils.js";
import EventBus from "@/components/events/EventBus";

import { styles } from "@/components/map/mapstyle.js";
import { getBBScore } from "../../helpers/scores/bbScore";
import PopulationView from "../population/PopulationView.vue";

export default {
  data() {
    return {
      map: null,
      api: null,
      apiLoaded: false,

      xmin: -3.269471,
      ymin: 4.736839,
      xmax: 1.21373,
      ymax: 11.219829,
      bounds: null,

      //
      infoTab: null,
      legend: null,

      mapOptions: {
        zoomControl: true,
        zoomControlOptions: { position: 9 },
        mapTypeControl: true,
        mapTypeControlOptions: { position: 11 },
        scaleControl: false,
        streetViewControl: true,
        streetViewControlOptions: { position: 9 },
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        styles: styles.dark,
      },
      zoom: 13,
      center: { lat: 5.623028, lng: -0.176527 },

      billboards: [],
      bbClusterer: [],
      bbChecked: true,
      currentBillboard: null,
      currentBbScore: 0,
      visibilityAdj: 0,
      totalBbScore: 17,
      oppContact: 0,

      trafficChecked: false,
      trafficLayer: null,

      mapStyleToggle: null,
      mapStyle: "dark",

      // spidifier options
      spiderfier: null,
      spiderfierOptions: {
        basicFormatEvents: true,
        circleFootSeparation: 40,
        keepSpiderfied: true,
        markersWontHide: true,
        markersWontMove: true,
      },

      currentPoi: null,
      poiInfoWinOpen: false,
      loading: true,

      // tabs for infowindow
      tab: null,

      // popup window
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },

      filterTable: null,
      displMoreInfo: false,
      dispCatInfo: false,
      currentCategory: null,
      displFiltInfo: false,
      filters: null,
      bbCount: 0,

      // pois
      poiBoundsChanged: {},
      poiClusterer: [],
      poiMarkers: {},
      pois: [],

      showCartDialog: false,
      bbSvgContent: null,

      zoomAddress: null,
      autoCompleteBounds: {
        bounds: {
          north: 11.234926,
          south: 4.737842,
          east: 1.201117,
          west: -3.265621,
        },
        strictBounds: true,
      },
    };
  },

  computed: {
    ...mapGetters({
      getBillboards: "billboards/getBillboards",
      getSearchResults: "billboards/getSearchResults",
      getPopulation: "billboards/getPopulation",
      getPoi: "pois/getPoi",
      getPois: "pois/getPois",
      getCurrentCampaign: "campaigns/getCurrentCampaign",
    }),

    google: gmapApi,

    mapReady() {
      return this.google && this.map !== null;
    },
  },

  components: {
    PopulationView,
    BillboardFeatures,
    LegendArea,
  },

  methods: {
    ...mapActions({
      fetchBbsByBbox: "billboards/fetchBbsByBbox",
      addBbToCart: "billboards/addBbToCart",
      fetchPoiFromGoogle: "pois/fetchPoiFromGoogle",
    }),

    getTraffic(enabled) {
      this.trafficChecked = enabled;
      if (this.trafficChecked) {
        this.trafficLayer.setMap(this.map);
      } else {
        this.trafficLayer.setMap(null);
      }
    },

    async setPlace(place) {
      if (!place.geometry) return;

      this.map.setZoom(15);
      this.map.setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    },

    getBounds() {
      this.bounds = this.map.getBounds();
      const ne = this.bounds.getNorthEast().toJSON(); // LatLng of the north-east corner
      const sw = this.bounds.getSouthWest().toJSON(); // LatLng of the south-west corder

      this.xmin = sw.lng;
      this.ymin = sw.lat;
      this.xmax = ne.lng;
      this.ymax = ne.lat;
    },

    async fetchSvgData() {
      let res = await fetch("images/billboard1.svg");
      this.bbSvgContent = await res.text();
    },

    async addBBs() {
      if (this.bbChecked) {
        this.getBounds();

        await this.fetchBbsByBbox({
          xmin: this.xmin,
          ymin: this.ymin,
          xmax: this.xmax,
          ymax: this.ymax,
        });
        this.plotBillboards();
      } else {
        this.bbClusterer.removeMarkers(this.bbMarkers);
      }
    },

    async fetchBBs() {
      // tilesloaded, bounds_changed

      await this.fetchBbsByBbox({
        xmin: this.xmin,
        ymin: this.ymin,
        xmax: this.xmax,
        ymax: this.ymax,
      });
      if (!this.getBillboards?.length) return;
      this.billboards = this.getBillboards;
      this.plotBillboards();
    },

    plotBillboards() {
      // check if bb are in store
      this.bounds = new this.google.maps.LatLngBounds();

      this.bbMarkers = this.billboards.map((bb) => {
        let latlng = new this.google.maps.LatLng(
          parseFloat(bb.latitude),
          parseFloat(bb.longitude)
        );

        this.bounds.extend(latlng);

        let bbMarker = new this.google.maps.Marker({
          position: latlng,
          icon: {
            url: "/images/billboardColored.png",
            scaledSize: new this.google.maps.Size(25, 25),
          },
          data: {
            lighting: bb.lighting,
            height: bb.height,
            orientation: bb.orientation,
            clutter: bb.clutter,
            site_run_up: bb.site_run_up,
          },
          // label: {
          //   text: `GH₵ ${this.parseNumber(bb.rate_card)}`,
          //   fontFamily: "font-family: Manrope",
          //   fontSize: "10px",
          //   fontWeight: "bold",
          //   color: "white",
          // },
        });

        // set listener
        this.google.maps.event.addListener(bbMarker, "click", async () => {
          this.currentBillboard = bb;

          // position of the Popup window
          this.infoWindowPos = {
            lat: this.currentBillboard.latitude,
            lng: this.currentBillboard.longitude,
          };

          // open info window
          this.infoWinOpen = true;

          //calculate score
          const { visibilityAdj: vis, oppContact: opp } = await getBBScore(bb);
          (this.visibilityAdj = vis), (this.oppContact = opp);
        });

        return bbMarker;
      });
      this.bbClusterer.clearMarkers();
      this.bbClusterer.addMarkers(this.bbMarkers);
      this.map.fitBounds(this.bounds);
      this.map.panToBounds(this.bounds);
      this.loading = false;
    },

    parseData(val) {
      if (val == null || val == undefined) {
        return "Not Available";
      }
      return val;
    },

    parseNumber(amount) {
      if (amount) {
        return new Intl.NumberFormat().format(amount);
      } else {
        return 0;
      }
    },

    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async searchPOI(poiQuery) {
      this.loading = true;
      await this.fetchPoiFromGoogle(poiQuery);
      this.loading = false;
    },

    async addPoi({ checkedPois, poiQuery }) {
      // poi has been unchecked
      // this.pois has all the previous pois,
      // loop over this.pois and check if poi is in enabled
      this.pois.forEach((poi) => {
        if (checkedPois.indexOf(poi) == -1) {
          // is no longer checked
          // remove its markers from the clusterer
          // remove it from the poi markers
          if (this.poiMarkers[poi]?.length) {
            this.poiClusterer.removeMarkers(this.poiMarkers[poi]);
          }
          delete this.poiMarkers[poi];
          this.google.maps.event.removeListener(this.poiBoundsChanged[poi]);
        }
      });
      this.pois = checkedPois;

      await this.searchPOI(poiQuery);
      this.plotPois();
      EventBus.$emit("closePoiModal");
    },

    plotPois() {
      //
      // check if bb are in store
      this.bounds = new this.google.maps.LatLngBounds();

      this.pois.forEach((poi) => {
        // if poi markers is already enabled then just pass
        if (poi in this.poiMarkers) return;

        // pois have markers within the bounds
        if (!this.getPoi(poi)?.length) return;
        let markers = this.getPoi(poi).map((el) => {
          // the x and y of the marker

          let latitude = el.location.lat;
          let longitude = el.location.lng;

          let latlng = new this.google.maps.LatLng(latitude, longitude);
          this.bounds.extend(latlng);

          let marker = new this.google.maps.Marker({
            position: latlng,

            icon: {
              url: `/images/${poi}.png`,
              scaledSize: new this.google.maps.Size(17, 17),
            },
            optimized: false,
          });

          // on click listener show name
          this.google.maps.event.addListener(marker, "click", async () => {
            this.currentPoi = el;

            // position of the Popup window
            this.infoWindowPos = {
              lat: latitude,
              lng: longitude,
            };

            // open info window
            this.poiInfoWinOpen = true;
          });

          return marker;
        });

        this.poiMarkers[poi] = markers;

        // add to map
        this.poiClusterer.addMarkers(this.poiMarkers[poi]);
        this.map.fitBounds(this.bounds);
        this.map.panToBounds(this.bounds);
      });
    },

    async addToCart() {
      await this.addBbToCart(this.currentBillboard.id);
      reportSuccess("Billboard Added to cart. Click on the Cart to checkout");
      this.showCartDialog = true;
      EventBus.$emit("bbPrice", this.currentBillboard.rate_card);
    },

    async removeBooking(bbId) {
      //
      console.log(bbId);
    },
  },

  watch: {
    // mapReady: {
    //   // immediate: true,
    //   handler(val) {
    //     if (!val) return;
    //     this.fetchBBs();
    //   },
    // },

    getSearchResults: {
      handler(val) {
        if (!val) return;
        this.billboards = val;
        this.plotBillboards();
      },
    },

    mapStyle: {
      handler(val) {
        this.map.setOptions({ styles: styles[val] });
      },
    },
  },

  async mounted() {
    // At this point, the child GmapMap has been mounted, but
    // its map has not been initialized.
    // Therefore we need to write googleMap.$mapPromise.then(() => ...)
    this.map = await this.$refs.googleMap.$mapPromise;

    // lazy loading
    // google maps api library is loaded asynchronously , thus is not immediately available even when the page is loaded.
    this.api = await this.$gmapApiPromiseLazy();
    this.apiLoaded = !!this.api;

    this.legend = document.querySelector("#legend");
    this.infoTab = document.querySelector("#infoTab");

    this.map.controls[this.google.maps.ControlPosition.RIGHT_TOP].push(
      this.legend
    );

    this.map.controls[this.google.maps.ControlPosition.LEFT_CENTER].push(
      this.infoTab
    );

    this.mapStyleToggle = document.querySelector("#mapStyle");

    this.map.controls[this.google.maps.ControlPosition.LEFT_TOP].push(
      this.mapStyleToggle
    );

    this.zoomAddress = document.querySelector("#zoomAddress");
    this.map.controls[this.google.maps.ControlPosition.TOP_CENTER].push(
      this.zoomAddress
    );

    await this.fetchSvgData();

    // initialize points of interest clusterer
    this.poiClusterer = new MarkerClusterer({
      map: this.map,
      markers: [],
    });

    // Billboard cluster
    this.bbClusterer = new MarkerClusterer({
      map: this.map,
      markers: [],
      averageCenter: true,
    });

    this.trafficLayer = new this.google.maps.TrafficLayer();
    await this.fetchBBs();
  },

  mixins: [filterScores],
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 80vh;
}
#infoWindow {
  max-width: 350px;
  margin: 10px;
}

.images {
  display: flex;
  justify-content: baseline;
  align-items: center;
  margin-bottom: 10px;
  transition: all 0.2s;
}
.image {
  cursor: pointer;
  margin: 5px;
}
.image:hover {
  transform: scale(1.07);
  transition: all 0.2s;
}
#infoTab {
  box-shadow: 0 0 15px rgb(0 0 0 20%);
}

#legend {
  margin-top: 10px;
}
.v-card__text {
  font-family: Manrope, Arial, sans-serif;
  font-size: 1.5em;
  text-align: center;
}

#poiCard > .v-card__text {
  font-family: Manrope, Arial, sans-serif;
  padding: 10px;
  font-size: 1rem;
  color: black !important;
}
#mapStyle {
  background: #fff176;
  padding: 0 10px;
  margin-top: 10px;
}

#address {
  width: 200px;
  padding: 0 5px 0 10px;
  height: 40px !important;
  background: white;
}
</style>
