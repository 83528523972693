<template>
  <v-card
    width="100%"
    min-height="650"
    elevation="1"
    :color="$vuetify.theme.themes.dark.background"
  >
    <v-toolbar
      height="34"
      flat
      class="py-3"
      :color="$vuetify.theme.themes.dark.background"
    >
      <v-spacer />
      <v-toolbar-title class="white--text">Brand Competitors</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card-text>
      <v-row class="px-6">
        <v-col cols="12" md="4">
          <v-select
            v-model="currentBrandId"
            label="Select Your Brand:"
            :items="getUserBrands"
            :rules="rules.selectRules"
            item-text="name"
            item-value="id"
            dark
            @change="fetchCompetitor"
          ></v-select>
        </v-col>
        <v-col cols="12" md="8" v-if="getBrandCompetitors?.length">
          <v-select
            v-model="currentCompetitorId"
            label="Select Competitor:"
            :items="getBrandCompetitors"
            :rules="rules.selectRules"
            dark
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
      </v-row>
      <div v-if="currentCompetitor.id">
        <v-row class="px-6" align="center" justify="center">
          <v-col cols="12" md="4" class="white--text">
            <p>
              <span class="white--text">Briefs:</span>
              {{ getBriefsCount }}
            </p>
            <p>
              <span class="white--text">Media Plans:</span>
              {{ getMediaPlansCount }}
            </p>
          </v-col>
          <v-col cols="12" md="8">
            <v-select
              class="mediaPlanSelect"
              v-model="currentPlanId"
              label="Select Media Plan:"
              :items="getBrandPlans"
              :rules="rules.selectRules"
              item-text="name"
              dark
              item-value="id"
            ></v-select>
          </v-col>
        </v-row>
        <div v-if="currentPlan.Candidates">
          <view-campaign-map :currentCampaign="currentPlan" />
        </div>
      </div>
      <div v-else class="mx-auto">
        <p class="text-center white--text">No Competitor Found</p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
/**error
 * list of brands
 *  select brand
 *      -  show brand presence  ie map with heat maps
 *      -  show target demographics
 */
import { mapGetters, mapActions } from "vuex";
import ViewCampaignMap from "../campaign/ViewCampaignMap.vue";

export default {
  data() {
    return {
      currentBrandId: null,
      currentPlan: {},
      currentPlanId: null,
      currentCompetitor: {
        name: null,
        BrandOwners: [
          {
            id: null,
            Briefs: [
              {
                id: null,
                Plans: [
                  {
                    id: null,
                    name: null,
                    Candidates: [
                      {
                        id: null,
                        Billboard: {
                          id: null,
                          latitude: null,
                          longitude: null,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      currentCompetitorId: null,

      rules: {
        selectRules: [(v) => !!v || "Please Select an option."],
      },
    };
  },
  components: { ViewCampaignMap },
  computed: {
    ...mapGetters({
      getUserBrands: "brands/getUserBrands",
      getBrandCompetitors: "brands/getBrandCompetitors",
      getCurrentUser: "getCurrentUser",
    }),
    getBriefsCount() {
      return (
        this.currentCompetitor?.BrandOwners.flatMap((owner) => {
          return owner.Briefs;
        }).length || 0
      );
    },
    getMediaPlansCount() {
      return this.currentCompetitor.BrandOwners.flatMap((owner) => {
        return owner.Briefs.flatMap((brief) => {
          return brief.Plans;
        });
      }).length;
    },
    getBrandPlans() {
      return this.currentCompetitor.BrandOwners.flatMap((owner) => {
        return owner.Briefs.flatMap((brief) => {
          return brief.Plans;
        });
      });
    },
  },
  methods: {
    ...mapActions({
      fetchBrandCompetitor: "brands/fetchBrandCompetitor",
      fetchUserBrands: "brands/fetchUserBrands",
    }),

    async fetchCompetitor() {
      await this.fetchBrandCompetitor(this.currentBrandId);
    },
  },
  watch: {
    currentCompetitorId: {
      handler() {
        this.currentCompetitor = this.getBrandCompetitors.find(
          (item) => item.id == this.currentCompetitorId
        );
      },
    },
    currentPlanId: {
      handler() {
        this.currentPlan = this.getBrandPlans.find(
          (item) => item.id == this.currentPlanId
        );
      },
    },
  },
  async created() {
    await this.fetchUserBrands(this.getCurrentUser.userId);
  },
};
</script>

<style>
.v-card {
  font-family: Manrope, Arial, sans-serif !important;
}
</style>
