<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col id="driver" cols="12" sm="2" lg="2" xl="2" md="2">
        <v-card
          width="100%"
          elevation="0"
          :color="$vuetify.theme.themes.dark.background"
          class="white--text"
        >
          <v-btn
            color="expansionPanels"
            elevation="0"
            v-for="btn in buttons"
            :key="btn.name"
            @click="setComponent(btn.cmp)"
            :class="{ active: btn.cmp === currentComponent }"
            :id="btn.id"
          >
            <v-icon color="black">{{ btn.icon }}</v-icon>
            {{ btn.name }}
          </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" sm="10" lg="10" xl="10" md="10">
        <keep-alive>
          <component :is="currentComponent"></component>
        </keep-alive>
      </v-col>
    </v-row>
    <alert></alert>
  </v-container>
</template>

<script>
import BrandsList from "../../components/brands/BrandsList.vue";
import CreateBrand from "../../components/brands/CreateBrand.vue";

export default {
  data() {
    return {
      currentComponent: "BrandsList",
      buttons: [
        {
          name: "Create Brands",
          icon: "mdi-compass-outline",
          cmp: "CreateBrand",
        },
        {
          name: "View Brands",
          icon: "mdi-office-building-outline",
          cmp: "BrandsList",
        },
      ],
    };
  },

  components: { BrandsList, CreateBrand },

  computed: {},

  methods: {
    setComponent(cmp) {
      this.currentComponent = cmp;
    },
  },
};
</script>

<style scoped>
#driver {
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.v-btn {
  margin: 10px;
  height: 48px !important;
  width: 100%;
  text-transform: none;
}
.active {
  background: #f68625 !important;
  border: none;
}
</style>
