import { handleHttpError, baseUrl, getToken } from "./utils";
import {
  getFromLF,
  savetoLF,
  removeFromLF,
} from "../store/storage/localForage";

const axios = require("axios").default;

async function fetchAllPois() {}

async function fetchPoiByBbox({ xmin, ymin, xmax, ymax, poiType }) {
  const token = await getToken();

  try {
    const response = await axios.get(`${baseUrl}/poi/${poiType}`, {
      params: { xmin, ymin, xmax, ymax },
      headers: { Authorization: `Bearer ${token}` },
    });

    let data = await getFromLF(`${poiType}`);

    if (!data && !response.data.data) {
      const error = `Error, No ${poiType} found.`;
      return handleHttpError(error);
    }

    if (!data) {
      const pois = response.data.data;

      await savetoLF(`${poiType}`, pois);

      return { poiType: poiType, data: pois };
    } else {
      //
      if (data.length && response.data.data.length) {
        const pois = response.data.data;

        await removeFromLF(`${poiType}`);

        await savetoLF(`${poiType}`, pois);

        return { poiType: poiType, data: pois };
      }
    }
  } catch (err) {
    const error =
      `Error fetching ${poiType}: ${err}` ||
      "An error occured fetching POIS. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchPoiFromGoogle(query) {
  const { poiType } = query;
  const token = await getToken();

  try {
    const response = await axios.post(`${baseUrl}/poi/search`, [query], {
      headers: { Authorization: `Bearer ${token}` },
    });

    return { poiType: poiType, data: response.data.data[0] };
  } catch (err) {
    const message = err.response.data
      ? `: ${err.response.data.error.message}`
      : "An error was encountered trying to fetch pois. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

export { fetchAllPois, fetchPoiByBbox, fetchPoiFromGoogle };
